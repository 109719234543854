import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNetworkMode } from '../lib/contexts/networkStatusContext'
import { getFirstProject, Project } from '../lib/entities/project'
import { ProjectStorage } from '../lib/utils/storePresentations'

import { useAsync } from './useAsync'
import { useDeviceInfo } from './useDeviceInfo'

export const useProjectWithIframeData = (projectId: void | number | string) => {
  const { isOnline } = useNetworkMode()
  const { run, data: project, isLoading, error } = useAsync<null | QueryDocumentSnapshot<Project>>(null)
  const projectData = project?.data()
  const [iframeSrc, setIframeSrc] = useState<null | string>(null)
  const deviceInfo = useDeviceInfo()

  useEffect(() => {
    if (!project) {
      setIframeSrc(null)
    }
  }, [project])

  useEffect(() => {
    if (!projectId) {
      return
    }
    run(getFirstProject({ where: { id: projectId } }))
  }, [projectId, run])

  // Get Data from network
  useEffect(() => {
    if (!isOnline || !projectData?.presentation_url) {
      return
    }
    setIframeSrc(projectData?.presentation_url)
  }, [isOnline, projectData?.presentation_url])

  // Get Data locally
  useEffect(() => {
    if (isOnline || !projectData?.name) {
      return
    }

    const handleOfflineSrc = async () => {
      if (!projectData?.name) {
        return
      }
      const intendedLocalSrc = await ProjectStorage.getIndexHtmlSrc(String(projectData.id))
      setIframeSrc(`${intendedLocalSrc}${deviceInfo?.operatingSystem === 'android' ? '' : 'index.html'}`)
    }

    handleOfflineSrc()
  }, [deviceInfo?.operatingSystem, isOnline, projectData])

  return { project, isLoading, error, iframeSrc }
}
