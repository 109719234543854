/* eslint-disable no-console */
import { getPlatforms } from '@ionic/react'
import { initializeApp } from 'firebase/app'
import {
  CACHE_SIZE_UNLIMITED,
  collection,
  CollectionReference,
  enableIndexedDbPersistence,
  FirestoreDataConverter,
  initializeFirestore,
} from 'firebase/firestore'
import { MULTI_TAB_FEEDBACK_ROUTE } from './routes'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  automaticDataCollectionEnabled: true,
}

const app = initializeApp(firebaseConfig)

const db = initializeFirestore(app, { cacheSizeBytes: CACHE_SIZE_UNLIMITED })
enableIndexedDbPersistence(db)
  .then(() => {
    console.log('Offline mode is supported')
  })
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      console.log(err.code)
      console.log(err)

      const platforms = getPlatforms()
      const isDesktop = platforms.includes('desktop')
      const isOnMultiTabFeedbackPage = window.location.pathname.includes(MULTI_TAB_FEEDBACK_ROUTE)
      const isInIframe = Boolean(window.frameElement)

      const blockMultiTabUsage = () => (window.location.href = `${window.location.origin}${MULTI_TAB_FEEDBACK_ROUTE}`)

      if (isDesktop && !isOnMultiTabFeedbackPage && !isInIframe) {
        blockMultiTabUsage()
      }
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.log(err.code)
      console.log(err)
    }
  })

function createCollection<T>(collectionName: string, converter?: FirestoreDataConverter<T>) {
  return converter
    ? (collection(db, collectionName).withConverter(converter) as CollectionReference<T>)
    : (collection(db, collectionName) as CollectionReference<T>)
}

export { app, db, createCollection }
