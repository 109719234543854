import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { CLIENT_ROOT_ROUTE, CUSTOMERS_ROUTE, END_SCREEN_ROUTE, ENTRY_SCREEN_ROUTE, MASTER_ROOT_PAGE_ROUTE, MULTI_TAB_FEEDBACK_ROUTE } from '../routes'
import { ClientRoutes } from './client'
import EntryScreen from './EntryScreen'
import { MasterRoutes } from './master'
import NotFound from './NotFound'
import MultiTabFeedback from './MultiTabFeedback'
import EndScreen from './EndScreen'
import { useAuth } from '../lib/contexts/authContext'
import { useNetworkMode } from '../lib/contexts/networkStatusContext'
import ConfirmModal from '../components/ConfirmModal'

export const AppRoutes = () => {
  const { user } = useAuth()

  const { confirmOfflineMode, offlineModeConfirmation } = useNetworkMode()

  const getRootRedirects = (): string => {
    if (user?.type === 'Trainer') {
      return CUSTOMERS_ROUTE
    }
    return ENTRY_SCREEN_ROUTE
  }

  return (
    <>
      {offlineModeConfirmation === 'needed' ? (
        <ConfirmModal confirm={confirmOfflineMode} confirmBtnText="Offline-Modus Einschalten" title="Offline-Modus Aktivieren?">
          <div className="text-gray-900">
            <p>{'Es scheint, als hätten Sie die Verbindung verloren. '}</p>
            <p className="mt-5">{'Möchten Sie in den Offline-Modus wechseln?'}</p>
            <p className="mt-5">{'Sie können den Online-Modus erst wieder nutzen, wenn Sie sich erneut anmelden.'}</p>
            <p className="mt-10 text-base text-gray-600 ">
              {'Wenn Sie stattdessen hier bleiben, verschwindet dieses Popup-Fenster, sobald das Netz wieder verfügbar ist.'}
            </p>
          </div>
        </ConfirmModal>
      ) : null}
      <Switch>
        <Redirect exact from="/" to={getRootRedirects()} />
        <Route path={MASTER_ROOT_PAGE_ROUTE}>
          <MasterRoutes />
        </Route>
        <Route path={CLIENT_ROOT_ROUTE}>
          <ClientRoutes />
        </Route>
        <Route exact path={ENTRY_SCREEN_ROUTE}>
          <EntryScreen />
        </Route>
        <Route exact path={MULTI_TAB_FEEDBACK_ROUTE}>
          <MultiTabFeedback />
        </Route>
        <Route path={END_SCREEN_ROUTE}>
          <EndScreen />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  )
}
