import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import PrevNextButtons from '../../../components/PrevNextButtons'
import ClientBackground from '../../../components/ClientBackground'
import { AppContext, IAppContext } from '../../../lib/contexts/appContext'
import { ADD_USER_ROUTE, USERS_NUMBER_ROUTE } from '../../../routes'
import { useCreateEmployee } from '../../../hooks/entities/useEmployees'
import { useDeviceId } from '../../../hooks/useDeviceId'
import { ANONYMOUS_USER } from '../../../lib/entities/user'
import { usePharmacy } from '../../../lib/contexts/pharmacyContext'

const SetupUsers: React.FC = () => {
  const { pharmacy } = usePharmacy()
  const { intendedUserCount: usersNumber, addedUsers } = useContext(AppContext) as IAppContext
  const uuid = useDeviceId()
  const [createAnonymUser] = useCreateEmployee()
  const history = useHistory()

  const goBack = () => {
    history.push(USERS_NUMBER_ROUTE)
  }

  const addAnonymUser = () => {
    createAnonymUser(
      {
        first_name: ANONYMOUS_USER.NAME,
        last_name: '',
        pharmacy_id: pharmacy?.id || 0,
        email: ANONYMOUS_USER.EMAIL,
        gender: '',
        employee_position_id: '',
        privacy_policy_accepted: false,
        is_anonymous_user: true,
      },
      uuid,
      false,
    )
  }

  const addUser = () => {
    history.push(ADD_USER_ROUTE)
  }

  return (
    <ClientBackground>
      <div className="w-6/12 max-w-2xl">
        <h1 className="text-blue-600 text-2xl font-medium text-center w-full mb-10">{`Nutzer ${
          addedUsers !== undefined ? addedUsers + 1 : ''
        } von ${usersNumber} einrichten`}</h1>
        <p className="text-blue-600 text-lg font-medium text-center w-full">
          {
            'Die Eingabe Ihrer persönlichen Daten ist freiwillig und dient vor allem der persönlichen Ansprache durch den Referenten sowie dem Zertifikat-Versand im Anschluss.'
          }
        </p>
      </div>
      <div className="w-3/5 max-w-screen-md">
        <div className="flex w-full justify-center align-center pt-10">
          <div className="mr-8">
            <button
              onClick={() => addUser()}
              className="bg-white text-blue-600 border-blue-600 border border-solid hover:bg-blue-200 rounded-lg py-3.5 px-5"
            >
              <span className="text-lg font-medium">{'Teilnehmer anmelden'}</span>
            </button>
          </div>
          <div>
            <button onClick={addAnonymUser} className="bg-white text-gray-400 hover:bg-gray-200 hover:text-gray-500 rounded-lg py-3.5 px-5">
              <span className="text-lg font-medium">{'Anonym teilnehmen'}</span>
            </button>
          </div>
        </div>
      </div>
      <PrevNextButtons prevButton={{ onClick: goBack, text: 'Zurück', disabled: addedUsers !== undefined && addedUsers > 1 }} />
    </ClientBackground>
  )
}

export default SetupUsers
