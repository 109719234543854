import { onSnapshot, Query, QueryDocumentSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { SubscriptionState, SubscriptionStatus } from '../lib/models/subscriptionState'

export function useFirstQuerySubscription<T>(query: Query<T> | null) {
  const [state, setState] = useState<SubscriptionState<QueryDocumentSnapshot<T>>>({ status: SubscriptionStatus.Pending })

  const status = state.status
  const data = 'data' in state ? state.data : null
  const error = 'error' in state ? state.error : null

  const canFetch = Boolean(query)

  useEffect(() => {
    if (canFetch && status === SubscriptionStatus.Idle) {
      setState({
        status: SubscriptionStatus.Pending,
      })
    }

    if (!canFetch) {
      setState({
        status: SubscriptionStatus.Idle,
      })
    }
  }, [canFetch, status])

  // Set the connection
  useEffect(() => {
    if (!query) {
      return
    }

    const unsubscribe = onSnapshot(query, {
      next: (snapshot) => {
        setState({ data: snapshot?.docs[0] || null, status: SubscriptionStatus.Resolved })
      },
      error: (err) => setState({ status: SubscriptionStatus.Error, error: err }),
    })

    return unsubscribe
  }, [query])

  return { status, data, isLoading: status === SubscriptionStatus.Pending, error }
}
