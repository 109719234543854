import React from 'react'

interface QRCodeProps {
  qrCodeImageSrc: string
  qrCodeImageAlt: string
  logoImageSrc: string
  logoImageAlt: string
  link: string
}

const QRCode = ({ qrCodeImageSrc, qrCodeImageAlt, logoImageSrc, logoImageAlt, link }: QRCodeProps) => {
  return (
    <div className="w-1/2">
      <div className="rounded-2xl bg-pink-500 p-4 mx-2">
        <img className="w-full" src={qrCodeImageSrc} alt={qrCodeImageAlt} />
        <a href={link} target="_blank" rel="noreferrer">
          <img className="w-full px-6 pt-3 pb-0" src={logoImageSrc} alt={logoImageAlt} />
        </a>
      </div>
    </div>
  )
}

export default QRCode
