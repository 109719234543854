import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import PrivateRoute from '../../components/PrivateRoute'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import {
  CLIENT_LOGIN_ROUTE,
  CLIENT_PRESENTATION_ROUTE,
  CLIENT_ROOT_ROUTE,
  CLIENT_TRAININGS_ROUTE,
  HOME_PAGE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  QUESTIONNAIRE_ROUTE,
  USERS_NUMBER_ROUTE,
  USER_SETUP_ROOT_ROUTE,
  WAITING_ROUTE,
} from '../../routes'
import NotFound from '../NotFound'
import Home from './Home'
import ClientPresentation from './Presentation'
import PrivacyPolicy from './PrivacyPolicy'
import QuestionnairePage from './Questionnaire'
import ClientTrainings from './Trainings'
import { ClientUserSetupRoutes } from './user-setup'
import Waiting from './Waiting'
import ClientLogin from './Login'
import { useAuth } from '../../lib/contexts/authContext'

export const ClientRoutes = () => {
  const { activeAppointment, isLoading: activeAppointmentLoading } = useActiveAppointment()
  const { user } = useAuth()
  const isClientSetupStartable = Boolean(user?.type === 'Client' && user?.entry && (activeAppointment || activeAppointmentLoading))

  return (
    <>
      <Switch>
        <Redirect exact from={CLIENT_ROOT_ROUTE} to={isClientSetupStartable ? USERS_NUMBER_ROUTE : HOME_PAGE_ROUTE} />
        <PrivateRoute path={USER_SETUP_ROOT_ROUTE} rules={{ device: 'Client' }}>
          <ClientUserSetupRoutes />
        </PrivateRoute>
        <Route path={CLIENT_LOGIN_ROUTE}>
          <ClientLogin />
        </Route>
        <PrivateRoute path={HOME_PAGE_ROUTE} rules={{ device: 'Client' }}>
          <Home />
        </PrivateRoute>
        <PrivateRoute path={QUESTIONNAIRE_ROUTE} rules={{ device: 'Client' }}>
          <QuestionnairePage />
        </PrivateRoute>
        <PrivateRoute path={WAITING_ROUTE} rules={{ device: 'Client' }}>
          <Waiting />
        </PrivateRoute>
        <Route path={PRIVACY_POLICY_ROUTE}>
          <PrivacyPolicy />
        </Route>
        <PrivateRoute path={CLIENT_PRESENTATION_ROUTE} rules={{ device: 'Client' }}>
          <ClientPresentation />
        </PrivateRoute>
        <PrivateRoute path={CLIENT_TRAININGS_ROUTE} rules={{ device: 'Client', online: false }}>
          <ClientTrainings />
        </PrivateRoute>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  )
}
