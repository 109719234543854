import { User } from 'firebase/auth'
import React, { createContext } from 'react'
import { useAuthProvider } from '../../hooks'
import { FirebaseUser } from '../entities/user'
import { createContextConsumerHook } from '../utils/createContextConsumerHook'
// Heavily inspired by https://usehooks.com/useAuth/

export type DeviceUser = {
  /**
   * The logged in user on firestore
   * (https://console.firebase.google.com/project/[PROJECT_NAME]/authentication/users)
   */
  account: User
  /**
   * The logged in user's user entry in the usersCollection
   */
  entry: FirebaseUser
  /**
   * How the user logged in
   */
  type: 'Client' | 'Trainer'
  /**
   * The custom token from the crm
   */
  customToken: string
}

export interface AuthLoading {
  initialSignin: boolean
  manualSignin: boolean
}

export interface IAuthContext {
  /**
   * The user itself. If the person is not authenticated the object is null
   */
  user: DeviceUser | null

  loading: AuthLoading

  /**
   * The device name set in the BE
   */
  deviceName: string

  /**
   * Signs in a User with the given credentials
   */
  signInMain: (email: string, password: string, deviceId: string) => Promise<boolean>

  /**
   * Signs in a Client iPad with the given credentials
   */
  signInClient: (email: string, password: string, deviceId: string) => Promise<boolean>

  /**
   * Signs out a user
   */
  logout: () => void
}

const AuthContext = createContext<IAuthContext | null>(null)

/**
 * Populates an implementation of AuthContext to its children.
 *
 */
export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const auth = useAuthProvider()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export interface UserAuth extends User {
  firstName?: string
  lastName?: string
}

export const useAuth = createContextConsumerHook(AuthContext, 'useAuth')
