import React, { createContext, useMemo } from 'react'
import { query, QuerySnapshot, where } from 'firebase/firestore'
import { ReactNode } from 'react'

import { createContextConsumerHook } from '../utils/createContextConsumerHook'
import { Ipad } from '@aposphaere/core-kit/build/models'
import { ipadsCollection } from '../entities/iPad'
import { useQuerySubscription } from '../../hooks/useQuerySubscription'
import { useAuth } from '../../lib/contexts/authContext'
import { SubscriptionStatus } from '../models/subscriptionState'

/*
 * CONTEXT
 */
interface IpadsContext {
  ipadsSnapshot: QuerySnapshot<Ipad> | null
  ipads: Ipad[]
  isLoading: boolean
  status: SubscriptionStatus
}
const Context = createContext<IpadsContext | null>(null)
Context.displayName = 'IpadsContext'

/*
 * PROVIDER
 */
export const IpadsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()

  const ipadsQuery = useMemo(
    () => (user?.entry?.trainer_code ? query(ipadsCollection, where('trainer_code', '==', user?.entry?.trainer_code)) : null),
    [user?.entry?.trainer_code],
  )

  const { data: ipadsSnapshot, isLoading, status } = useQuerySubscription(ipadsQuery)

  return (
    <Context.Provider
      value={{
        ipads: ipadsSnapshot?.docs?.map?.((doc) => doc.data()) || [],
        ipadsSnapshot,
        isLoading: isLoading,
        status,
      }}
    >
      {children}
    </Context.Provider>
  )
}

/*
 * HOOK
 */
export const useCurrentIpads = createContextConsumerHook(Context, 'useIpads')
