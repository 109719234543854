import React, { useEffect } from 'react'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, TextInput, toast } from '@aposphaere/ui-components'
import * as Yup from 'yup'
import { useAuth } from '../../lib/contexts/authContext'
import { useAsync } from '../../hooks/useAsync'
import { useFormik } from 'formik'
import { createRegisterFunction } from '../../lib/utils/formHelpers'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { SignInErrorType } from '../../hooks/useAuthProvider'
import { useHistory } from 'react-router'
import { useDeviceId } from '../../hooks/useDeviceId'

type Props = {
  redirectRoute: string
}

type LoginFormValues = {
  username: string
  password: string
  deviceId: string
}

const LoginValidationScheme = Yup.object().shape({
  username: Yup.string().trim().email('Ungültige E-Mail').required('Pflichtfeld'),
  password: Yup.string().trim().min(8, 'Zu kurz').required('Pflichtfeld'),
})

const initialFormValues: LoginFormValues = {
  username: '',
  password: '',
  deviceId: '',
}

const LoginForm = ({ redirectRoute }: Props) => {
  const { isOnline } = useNetworkMode()
  const auth = useAuth()
  const history = useHistory()
  const { run, data: signInSuccess, error: signInError } = useAsync<boolean | undefined>(undefined)

  const isLoading = auth.loading.manualSignin
  const isDeviceNotRegistered = signInError?.message === SignInErrorType.DeviceIdDoesNotMatch
  const showFormError = signInError && isOnline && !isDeviceNotRegistered
  const deviceId = useDeviceId()

  // Handle sing in
  useEffect(() => {
    if (signInSuccess) {
      history.push(redirectRoute)
    }

    if (isDeviceNotRegistered) {
      toast.show({
        type: 'error',
        headline: signInError?.message,
      })
    }
  }, [signInSuccess, history, isDeviceNotRegistered, redirectRoute, signInError?.message])

  const onFormSubmit = async (values: LoginFormValues) => {
    if (auth) {
      run(auth?.signInMain(values.username, values.password, values.deviceId ? values.deviceId : deviceId))
    }
  }

  const { errors, touched, handleSubmit, handleBlur, handleChange, values } = useFormik({
    initialValues: initialFormValues,
    onSubmit: onFormSubmit,
    validationSchema: LoginValidationScheme,
  })

  const register = createRegisterFunction({ values, handleChange, handleBlur })

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
      <div className="w-full">
        <InputLabel htmlFor="username">
          <span className="text-gray-700 mb-2.5">{'Email:'}</span>
        </InputLabel>
        <TextInput {...register('username')} type="email" />
        <div className="h-8 overflow-auto">
          {errors.username && touched.username ? <InputMessage kind={InputMessageKind.error}>{errors.username}</InputMessage> : null}
        </div>
      </div>
      <div className="w-full">
        <InputLabel htmlFor="password">
          <span className="text-gray-700  mb-2.5">{'Passwort:'}</span>
        </InputLabel>
        <TextInput {...register('password')} type="password" />
        <div className="h-8 overflow-auto">
          {errors.password && touched.password ? <InputMessage kind={InputMessageKind.error}>{errors.password}</InputMessage> : null}
        </div>
      </div>
      <div className="invisible h-1">
        <TextInput {...register('deviceId')} type="text" />
      </div>
      <Button aria-label="Einloggen" type="submit" kind={ButtonKind.secondaryGreen} disabled={isLoading || !isOnline ? true : false}>
        {isLoading ? 'Laden...' : 'Einloggen'}
      </Button>
      <div className="flex flex-col h-12 text-center justify-end">
        {!isOnline && <InputMessage kind={InputMessageKind.error}>Sie können sich nicht anmelden wenn Sie Offline sind.</InputMessage>}
        {showFormError && <InputMessage kind={InputMessageKind.error}>{signInError.message}</InputMessage>}
      </div>
    </form>
  )
}

export default LoginForm
