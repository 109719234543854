import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import Header from '../../components/Header'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { usePresentation } from '../../lib/contexts/presentationContext'
import { useGetPresentationStatistics } from '../../hooks'
import { CLIENT_PRESENTATION_ROUTE, CLIENT_ROOT_ROUTE, CLIENT_TRAININGS_ROUTE } from '../../routes'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'

const Waiting: React.FC = () => {
  const { presentation } = usePresentation()
  const { activeAppointment, isLoading: activeAppointmentLoading } = useActiveAppointment()
  const history = useHistory()
  const location = useLocation<{ presentation_name: string }>()

  const { isOffline } = useNetworkMode()
  const [statistics] = useGetPresentationStatistics(location?.state?.presentation_name ? location?.state?.presentation_name : '')

  useEffect(() => {
    if (isOffline) {
      history.push(CLIENT_TRAININGS_ROUTE)
    }
  }, [isOffline, history])

  useEffect(() => {
    if (!activeAppointment && !activeAppointmentLoading) {
      history.push(CLIENT_ROOT_ROUTE)
    }
  }, [activeAppointment, activeAppointmentLoading, history])

  useEffect(() => {
    if (presentation?.name) {
      history.push({ pathname: CLIENT_PRESENTATION_ROUTE, state: presentation?.name })
    }
  }, [history, presentation?.name, statistics])

  return (
    <div className="w-full h-screen flex flex-wrap overflow-hidden bg-orange-100 font-body">
      <Header />
      <div className="flex absolute w-full h-full bg-no-repeat bg-center flex-col justify-center items-center">
        <img className="absolute right-0 mt-44 -mr-44 animate-spin-slow top-0" src="/login-rotate-1.svg" alt="" />
        <img className="absolute bottom-28 animate-wiggle -ml-24 left-0" src="/login-rotate-2.svg" alt="" />
        <img className="absolute right-0 mt-32 -mr-96 top-0" src="/bg-login.svg" alt="" />
        <img src="/assets/icons/icon-wait.svg" alt="" className="mb-12 mx-auto" />
        <h1 className="text-blue-600 text-xl2 font-medium text-center w-full">{'Gleich geht’s weiter…'}</h1>
      </div>
      <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-400 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
    </div>
  )
}

export default Waiting
