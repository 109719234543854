import { gql } from 'graphql-request'

export const signInMainIpad = gql`
  mutation($email: String!, $password: String!, $deviceId: String!) {
    firebaseMainSignin(email: $email, password: $password, device_id: $deviceId) {
      uid
      token
      device_name
    }
  }
`
