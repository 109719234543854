import { initFirebaseEntity } from '../utils/initFirebaseEntity'

const COLLECTION_NAME = 'surveys' // TODO: It will change in the future to 'questions' Update it here

interface QuestionCore {
  id: number
  project_id: number
  text: string
  is_general_question?: boolean
  is_required?: boolean
  sequence_number: number
}

export type Question =
  | (QuestionCore & {
      type: 'checkboxes' | 'multi-choice'
      options: (string | number)[]
    })
  | (QuestionCore & {
      type: 'single-choice'
      options: (string | number)[]
    })
  | (QuestionCore & {
      type: 'text'
    })
  | (QuestionCore & {
      type: 'slider'
      options: {
        from?: number
        from_label?: string
        to: number
        to_label?: string
        step: number
      }
    })

const { collection, ...question } = initFirebaseEntity<Question>(COLLECTION_NAME)

export const getQuestion = question.get
export const getQuestionsForProject = (projectId: number) => getQuestions({ where: { project_id: projectId } })
export const getGeneralQuestions = () => getQuestions({ where: { is_general_question: true } })
export const getAllQuestions = question.getAll
export const getQuestions = question.getMany
