import { Directory, Filesystem, ReaddirResult } from '@capacitor/filesystem'
import { getAllProjects, getProjectWhereProjectId } from '../../entities/project'
import { Capacitor } from '@capacitor/core'
import { APP_DIRECTORY, PROJECTS_PATH, StoredStatus } from './config'
import { PreviewImage } from './previewImages'
import { Data64URIWriter, Entry } from '@zip.js/zip.js'
import { ProjectChecksum } from './checksum'
import { ProjectZip } from './zip'

export interface StoredProjectDetails {
  name: string
  id: string | number
  checksum: string
  zipUrl: string
  previewUrl: string
  storedState: StoredStatus
  isPreviewStored: boolean | undefined
}

interface StoreProjectData {
  projectId: string | number
  zipChecksum: string
  directories: Entry[]
  files: Entry[]
}

export const ProjectsDirectory = {
  // BASIC
  async createFolder(path: string, directory: Directory) {
    try {
      await Filesystem.mkdir({
        path: path,
        directory: directory,
        recursive: true,
      })
    } catch (e) {
      console.error(e)
    }
  },
  async deleteFolder(projectId: string | number) {
    try {
      const projectRoot = this.getProjectRootPath(projectId)
      await Filesystem.rmdir({ directory: APP_DIRECTORY, path: projectRoot, recursive: true })
    } catch (e) {
      console.error('Error during deleting project (', projectId, ') from filesystem', e)
    }
  },

  getProjectRootPath(projectId: string | number) {
    return `${PROJECTS_PATH}/${String(projectId).replaceAll(' ', '-').replaceAll('/', '_')}`
  },

  async storeProject({ directories, files, projectId, zipChecksum }: StoreProjectData) {
    try {
      // Creating folders
      const projectRootDir = this.getProjectRootPath(projectId)
      await this.storeEntries(directories, projectRootDir)
      // Writing the files
      await this.storeEntries(files, projectRootDir)
      if (zipChecksum) {
        await ProjectChecksum.write(String(projectId), zipChecksum)
      }
    } catch (e) {
      console.error('Error during storing project', projectId, '... Deleting it', e)
      await ProjectsDirectory.deleteFolder(projectId)
    }
  },

  // ENTRY HANDLING
  async storeEntry(entry: Entry, rootPath: string) {
    let data = null
    try {
      if (!entry?.getData) {
        return
      }
      data = (await entry?.getData(new Data64URIWriter())) || undefined
      const path = `${rootPath}/${entry.filename.split('/').slice(1).join('/')}`
      const isDirectory = entry.directory
      if (isDirectory) {
        await this.createFolder(path, APP_DIRECTORY)
      }

      if (!isDirectory && data) {
        await Filesystem.writeFile({
          data: data,
          path: path,
          directory: APP_DIRECTORY,
          recursive: true,
        })
      }
    } catch (e) {
      console.error('Error during storing an entry:', entry.filename, entry, e)
    }
  },
  async storeEntries(entries: Entry[], rootPath: string) {
    return Promise.all(entries.map((entry) => this.storeEntry(entry, rootPath)))
  },
}

interface StoreProjectCallbacks {
  onDownloadStart?: () => void
  onUnzipStart?: () => void
  onWriteStart?: () => void
}

export const ProjectStorage = {
  getPreviewImage(id: string | number) {
    return PreviewImage.getAsBase64FromFilesystem(id)
  },

  async storePreviewImage(id: string | number, previewUrl: string) {
    const response = await PreviewImage.fetch(previewUrl)
    if (response?.data) {
      await PreviewImage.store(id, { base64Data: response.data, fileExtension: response.extension })
    }
  },

  async checkAll(): Promise<StoredProjectDetails[]> {
    const projectsQueryResponse = await getAllProjects()
    const projects = projectsQueryResponse.docs.map((doc) => doc.data())
    const downloadableProjectIds = projects.filter((project) => project.zipped_presentation_url).map((project) => project.id)
    const states = await Promise.all(downloadableProjectIds.map((projectId) => this.check(projectId)))
    return states.filter((state) => state) as StoredProjectDetails[]
  },
  async check(id: string | number | number): Promise<StoredProjectDetails | null> {
    const details: StoredProjectDetails = {
      name: '',
      id: id,
      checksum: '',
      zipUrl: '',
      previewUrl: '',
      storedState: StoredStatus.Missing,
      isPreviewStored: undefined,
    }
    let directory: ReaddirResult | undefined = undefined

    const rootDirPath = ProjectsDirectory.getProjectRootPath(id)
    try {
      const project = await getProjectWhereProjectId(id)

      details.zipUrl = project?.zipped_presentation_url || ''
      details.previewUrl = project?.presentation_preview_url || ''
      details.name = project.name
      const checksum = project?.zip_checksum || ''
      details.checksum = checksum

      directory = await Filesystem.readdir({ directory: APP_DIRECTORY, path: rootDirPath })

      if (!directory || !project) {
        details.storedState = StoredStatus.Missing
      }
      if (checksum) {
        const foundChecksum = await ProjectChecksum.read(id)
        if (foundChecksum === checksum) {
          details.storedState = StoredStatus.UpToDate
        } else {
          details.storedState = StoredStatus.Outdated
        }
      }
      return details
    } catch (e) {
      console.error('Error: ', details)
      console.error(e)
      return details
    }
  },

  async download(storedDetails: StoredProjectDetails, callbacks: StoreProjectCallbacks = {}) {
    try {
      if (!storedDetails.zipUrl) {
        console.warn('Project can not be downloaded: Missing the zip url')
        return
      }

      if (storedDetails.storedState === StoredStatus.Outdated) {
        await ProjectsDirectory.deleteFolder(storedDetails.id)
      }

      if (storedDetails.storedState === StoredStatus.UpToDate) {
        return
      }

      callbacks?.onDownloadStart?.()

      const zipResponse = await ProjectZip.download(storedDetails.zipUrl)
      if (!zipResponse) {
        console.warn('No zip response. Aborting.')
        return
      }
      const zipBlob = await zipResponse?.blob()

      callbacks?.onUnzipStart?.()

      const { directories, files } = await ProjectZip.unzip(zipBlob)

      callbacks?.onWriteStart?.()
      await ProjectsDirectory.storeProject({ directories, files, projectId: storedDetails.id, zipChecksum: storedDetails.checksum || '' })
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message)
      }
    }
  },

  async getIndexHtmlSrc(id: string) {
    const rootDir = await Filesystem.readdir({ directory: APP_DIRECTORY, path: ProjectsDirectory.getProjectRootPath(id) })
    const indexHtmlFile = rootDir.files.find((file) => file.name.endsWith('index.html'))

    if (indexHtmlFile) {
      return Capacitor.convertFileSrc(indexHtmlFile.uri)
    }
    return null
  },
}
