import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import PrevNextButtons from '../../../components/PrevNextButtons'
import CircleButton from '../../../components/CircleButton'
import ClientBackground from '../../../components/ClientBackground'
import { useAppContext } from '../../../lib/contexts/appContext'
import { SETUP_USERS_ROUTE, WAITING_ROUTE } from '../../../routes'

const UserCount: React.FC = () => {
  const { intendedUserCount: userCount, setIntendedUSerCount: setUsersNumber, addedUsers } = useAppContext()
  const history = useHistory()
  const possibleUserCounts = [1, 2, 3]

  useEffect(() => {
    const usersAlreadyAdded = userCount && userCount === addedUsers
    if (usersAlreadyAdded) {
      history.push(WAITING_ROUTE)
    }
  }, [addedUsers, history, userCount])

  const next = () => {
    history.push(SETUP_USERS_ROUTE)
  }

  return (
    <ClientBackground>
      <div className="w-6/12 max-w-2xl">
        <h1 className="text-blue-600 text-2xl font-medium text-center w-full mb-14">{'Willkommen'}</h1>
        <h2 className="text-blue-600 text-3xl font-medium text-center w-full">{'Wie viele Nutzer sollen an diesem iPad angemeldet werden?'}</h2>
      </div>
      <div className="flex justify-between mt-24 w-6/12 max-w-2xl px-6">
        {possibleUserCounts.map((item) => {
          return <CircleButton key={item} label={item + ''} handleClick={() => setUsersNumber(item)} />
        })}
      </div>
      <PrevNextButtons
        customStyle="flex absolute w-full justify-end align-end bottom-7 right-7"
        nextButton={{ onClick: next, text: 'Weiter', disabled: userCount === 0 }}
      />
    </ClientBackground>
  )
}

export default UserCount
