import { DeleteIcon, EditIcon, IconButton, IconButtonKind } from '@aposphaere/ui-components'
import React from 'react'

export type AttendeesCardItemProps = {
  isHighlighted?: boolean
  text: string
  textColor: 'highlighted' | 'dark' | 'light'
  bottomSeparator?: boolean
  hideDeleteBtn?: boolean
  deviceId?: string
  questionnaireParticipants?: boolean
  onDelete?: () => void
  onEdit?: () => void
}

const AttendeesCardItem: React.FunctionComponent<AttendeesCardItemProps> = ({ text, textColor, bottomSeparator = true, onEdit, onDelete }) => {
  let textColorClass = 'text-gray-600'
  if (textColor === 'dark') {
    textColorClass = 'text-black-100'
  }
  if (textColor === 'highlighted') {
    textColorClass = 'text-green-550'
  }

  return (
    <div className={`${bottomSeparator && 'border-b border-gray-400'} flex items-center justify-between mx-4 pt-3 -m-px pb-3`}>
      <h3 className={`${textColorClass} text-lg font-medium m-0`}>{text}</h3>

      <div className="flex">
        {/* This hidden button makes the height the same, if no other buttons are present */}
        <IconButton kind={IconButtonKind.custom} icon={<EditIcon />} additionalCss="invisible w-1 aria-hidden" />
        {onEdit && (
          <IconButton
            kind={IconButtonKind.custom}
            icon={<EditIcon />}
            additionalCss="-mr-1 text-gray-600 hover:text-blue-600 focus:outline-none rounded transition ease-in-out duration-150"
            onClick={onEdit}
          />
        )}
        {onDelete && (
          <IconButton
            kind={IconButtonKind.custom}
            icon={<DeleteIcon />}
            additionalCss="ml-1 -mr-1 text-gray-600 hover:text-blue-600 focus:outline-none rounded transition ease-in-out duration-150"
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  )
}

export default AttendeesCardItem
