import { useEffect, useState } from "react";

export function useLocalStorage<T>(key:string, initialValue: T) { 
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(storedValue));
      }
  }, [storedValue, key])
  
  return [storedValue, setStoredValue] as const
}

