import { Pharmacy } from '@aposphaere/core-kit'
import { getDocs, query, where } from 'firebase/firestore'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'

const { collection, ...pharmacy } = initFirebaseEntity<Pharmacy>('pharmacies')

export const pharmacyCollection = collection
export const pharmacyQuery = pharmacy.query

export const getPharmacy = pharmacy.get
export const getAllPharmacies = pharmacy.getAll
export const getPharmacies = pharmacy.getMany
export const getFirstPharmacy = pharmacy.getFirst
export const getPharmacyById = async (id: number) => {
  const pharmacies = await getDocs(query(collection, where('id', '==', id)))
  return pharmacies?.docs?.[0]
}

export const updatePharmacy = pharmacy.update
export const createPharmacy = pharmacy.create

export const deletePharmacy = pharmacy.delete
export const deletePharmacies = pharmacy.deleteMany

export async function fetchPharmaciesOfUser(userUid: number) {
  const q = query(pharmacyCollection, where('user_ids', 'array-contains', userUid))
  const snapshot = await getDocs(q)
  const pharmacies = snapshot.docs.map((doc) => doc.data())
  return pharmacies
}
