import { Button, ButtonKind } from '@aposphaere/ui-components'
import { ButtonProps } from '@aposphaere/ui-components/build/Button'
import React, { useState } from 'react'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { StoreProjectsModal } from './Modal'

interface StoreProjectsButtonProps extends ButtonProps {
  kind: ButtonKind
}

const StoreProjectsButton = ({ children, ...props }: StoreProjectsButtonProps) => {
  const { isOffline } = useNetworkMode()
  const [modalOpen, setModalOpen] = useState(false)

  const onClick = () => {
    props?.onClick?.()
    setModalOpen(true)
  }

  if (isOffline) {
    return null
  }

  return (
    <>
      <Button {...props} onClick={onClick}>
        {children ? children : 'Präsentationen Updaten'}
      </Button>
      {modalOpen ? <StoreProjectsModal onClose={() => setModalOpen(false)} /> : null}
    </>
  )
}

export default StoreProjectsButton
