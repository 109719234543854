import React, { useState } from 'react'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { useAppContext } from '../../lib/contexts/appContext'
import { useAuth } from '../../lib/contexts/authContext'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { usePresentation } from '../../lib/contexts/presentationContext'

type DataType = 'appContext' | 'activeAppointment' | 'pharmacy' | 'presentation' | 'auth'

const DevTools =
  process.env.NODE_ENV === 'development'
    ? () => {
        // Data
        const auth = useAuth()
        const { activeAppointment, status: activeAppointmentStatus, activeAppointmentId } = useActiveAppointment()
        const { pharmacy, status: pharmacyStatus } = usePharmacy()
        const { presentation, status: presentationStatus } = usePresentation()
        const { addedUsers, intendedUserCount: usersNumber, cleanLocalStorage } = useAppContext()
        const { networkMode } = useNetworkMode()

        // UI state
        const [height, setHeight] = useState<'small' | 'big'>('small')
        const [isOpen, setOpen] = useState(false)
        const [activeData, setActiveData] = useState<DataType>('appContext')

        const getButtonClass = (type: DataType) => {
          const isSelected = activeData === type
          const baseClass = 'p-2 my-1 rounded-md'
          if (isSelected) {
            return baseClass + ' border-white text-black-100 bg-white'
          }

          return baseClass
        }

        const renderListItem = (name: string, type: DataType) => {
          return (
            <li>
              <button className={getButtonClass(type)} onClick={() => setActiveData(type)}>
                {name}
              </button>
            </li>
          )
        }

        return (
          <>
            <button onClick={() => setOpen(!isOpen)} className={`bottom-20 fixed z-999 pr-4 -mr-2 rounded-lg right-0 bg-black-100 p-3 text-white`}>
              Dev tools
            </button>
            {isOpen ? (
              <aside
                style={{ height: height === 'small' ? '24rem' : '40rem' }}
                className={`fixed z-9999 overflow-hidden p-5 bottom-0 left-0 right-0 bg-gray-900 text-white`}
              >
                <div className="absolute top-0 right-0">
                  <button className="p-2 bg-gray-400 text-black-100" onClick={() => setHeight(height === 'big' ? 'small' : 'big')}>
                    {height === 'big' ? 'Smaller' : 'Bigger'}
                  </button>
                  <button className=" p-2 text-white bg-red-800 left-auto" onClick={() => setOpen(false)}>
                    Close
                  </button>
                </div>
                <div className="flex h-full">
                  <div className=" mr-6">
                    <ul>
                      {renderListItem('Auth', 'auth')}
                      {renderListItem(`App Context`, 'appContext')}
                      {renderListItem(`Active Appointment (${activeAppointmentStatus})`, 'activeAppointment')}
                      {renderListItem(`Pharmacy (${pharmacyStatus})`, 'pharmacy')}
                      {renderListItem(`Presentation (${presentationStatus})`, 'presentation')}
                    </ul>
                  </div>
                  <div className="border-l flex-1 border-white pl-6 overflow-auto h-full text-gray-400">
                    <div className="inline-block  rounded-md px-3 py-1 mb-2 border-white border"> {networkMode}</div>
                    {activeData === 'activeAppointment' && (
                      <>
                        <div className="mb-3">ID: {activeAppointmentId}</div>
                        <pre>{JSON.stringify(activeAppointment, null, 2)}</pre>
                      </>
                    )}
                    {activeData === 'appContext' && (
                      <div className="">
                        <button className="p-2 mt-1 bg-yellow-900 rounded-md mb-3 text-black-100 font-bold" onClick={cleanLocalStorage}>
                          Clear data
                        </button>
                        <pre>
                          {JSON.stringify(
                            {
                              addedUsers,
                              usersNumber,
                            },
                            null,
                            2,
                          )}
                        </pre>
                      </div>
                    )}
                    {activeData === 'auth' && <pre>{JSON.stringify(auth, null, 2)}</pre>}
                    {activeData === 'pharmacy' && <pre>{JSON.stringify(pharmacy, null, 2)}</pre>}
                    {activeData === 'presentation' && <pre>{JSON.stringify(presentation, null, 2)}</pre>}
                  </div>
                </div>
              </aside>
            ) : null}
          </>
        )
      }
    : () => null

export default DevTools
