import React from 'react'
import { Button, ButtonKind } from '@aposphaere/ui-components'

interface Props {
  src: string
  onEnd?: () => void
}
export const PresentationVideo = (props: Props) => {
  return (
    <>
      <div className="absolute flex w-full h-full bg-white">
        {props.onEnd ? (
          <div className="absolute z-50 top-5 right-5">
            <Button kind={ButtonKind.secondary} onClick={props.onEnd}>
              Zurück
            </Button>
          </div>
        ) : null}
        <video className="justify-self-center" preload="auto" autoPlay>
          <source src={props.src} type="video/mp4"></source>
        </video>
      </div>
    </>
  )
}
