import { Employee } from '@aposphaere/core-kit/build/models'
import { getDocs, query } from 'firebase/firestore'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'
import { deleteIpadEmployee, getAllIpads, ipadsCollection, ipadWhere, updateIpad } from './iPad'

const { collection, ...employee } = initFirebaseEntity<Employee>('ptas')

export const employeeCollection = collection
export const employeeQuery = employee.query

export const getEmployee = employee.get
export const getAllEmployees = employee.getAll
export const getEmployees = employee.getMany

/**
 * Update an employee, and all it's occurences among the ipad users
 */
export const updateEmployee = async (ptaId: string, data: Partial<Employee>) => {
  const allIpads = await getAllIpads()
  const ipadsWhereEmployeeIsPresent = allIpads.docs.filter((ipadDoc) => ipadDoc.data().users.some((employee) => employee.id === ptaId))
  const updateIpadPromises = ipadsWhereEmployeeIsPresent.map((ipadDoc) =>
    updateIpad(ipadDoc.id, {
      ...ipadDoc.data(),
      users: ipadDoc.data().users.map((employee) => {
        if (employee.id === ptaId) {
          return { ...employee, ...data }
        }
        return employee
      }),
    }),
  )
  await Promise.all([...updateIpadPromises, employee.update(ptaId, data)])
  return
}

export const createEmployee = employee.create
export const deleteEmployee = employee.delete
export const deleteEmployees = employee.deleteMany

const getIpadDeviceAndDocumentIds = async (ptaId: string, deviceId?: string) => {
  const foundIpadsSnapshot = await getDocs(query(ipadsCollection, ipadWhere('pta_ids', 'array-contains-any', [ptaId])))
  const ipads = foundIpadsSnapshot.docs.map((doc) => {
    return { deviceId: doc.data()?.device_id, docId: doc.id }
  })
  return ipads
}

export const deleteIpadUser = async (ptaId: string, deviceId?: string) => {
  const ipads = await getIpadDeviceAndDocumentIds(ptaId, deviceId)
  ipads?.forEach((item) => {
    deleteIpadEmployee(ptaId, item?.deviceId)
  })
}
