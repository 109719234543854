import React from 'react'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, TextInput } from '@aposphaere/ui-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { HOME_PAGE_ROUTE } from '../../routes'
import { useAsync } from '../../hooks/useAsync'
import { createRegisterFunction } from '../../lib/utils/formHelpers'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import Header from '../../components/Header'
import { useAuth } from '../../lib/contexts/authContext'
import { useDeviceId } from '../../hooks/useDeviceId'
import DeviceIdModal from '../../components/DeviceIdModal'

type LoginFormValues = {
  email: string
  password: string
  deviceId: string
}

const LoginValidationScheme = Yup.object().shape({
  email: Yup.string().trim().email('Ungültige E-Mail').required('Pflichtfeld'),
  password: Yup.string().trim().min(8, 'Zu kurz').required('Pflichtfeld'),
})

const initialFormValues: LoginFormValues = {
  email: '',
  password: '',
  deviceId: '',
}

const ClientLogin: React.FC = () => {
  const deviceId = useDeviceId()
  const { isOnline, isOffline } = useNetworkMode()
  const auth = useAuth()
  const history = useHistory()
  const { run, data: signInSuccess, error: signInError } = useAsync<boolean | undefined>(undefined)

  const isLoading = auth.loading.manualSignin

  // Handle sing in
  useEffect(() => {
    if (signInSuccess) {
      history.push(HOME_PAGE_ROUTE)
    }
  }, [signInSuccess, history, auth?.user?.entry?.trainer_code])

  const onFormSubmit = async (values: LoginFormValues) => {
    if (auth) {
      run(auth.signInClient(values.email, values.password, values.deviceId ? values.deviceId : deviceId))
    }
  }

  const { errors, touched, handleSubmit, handleBlur, handleChange, values } = useFormik({
    initialValues: initialFormValues,
    onSubmit: onFormSubmit,
    validationSchema: LoginValidationScheme,
  })
  const register = createRegisterFunction({ values, handleChange, handleBlur })

  return (
    <div className="w-full h-screen flex flex-wrap overflow-hidden bg-orange-100 font-body">
      <DeviceIdModal>{({ openModal }) => <Header onSettingsClick={openModal} />}</DeviceIdModal>
      <div className="flex absolute w-full h-full bg-no-repeat bg-center justify-center items-center">
        <img className="absolute -ml-96 mt-0 animate-spin-slow" src="/login-rotate-1.svg" alt="" />
        <img className="absolute -mr-450 -mt-350 animate-wiggle" src="/login-rotate-2.svg" alt="" />
        <img className="absolute" src="/bg-login.svg" alt="" />
        <div className="flex w-3/4 md:w-96 bg-white shadow-2xl z-50 justify-center rounded-xl">
          <form onSubmit={handleSubmit} className="flex w-full px-15 flex-col align-middle rounded-lg h-fit justify-center pt-5 pb-5">
            <h1 className="text-blue-600 text-xl font-medium text-center w-full mb-8 mt-0 pt-5">{'Client Login:'}</h1>
            <div>
              <InputLabel htmlFor="email">
                <span className="text-gray-700 mb-2.5">{'Email:'}</span>
              </InputLabel>
              <TextInput {...register('email')} type="email" />
              {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
            </div>
            <div className="mt-8">
              <InputLabel htmlFor="password">
                <span className="text-gray-700  mb-2.5">{'Passwort:'}</span>
              </InputLabel>
              <TextInput {...register('password')} type="password" />
              {errors.password && touched.password ? <InputMessage kind={InputMessageKind.error}>{errors.password}</InputMessage> : null}
            </div>
            <div className="invisible h-1">
              <TextInput {...register('deviceId')} type="text" />
            </div>
            <div className="flex w-full mt-8 justify-center pb-5">
              <Button aria-label="Einloggen" type="submit" kind={ButtonKind.secondaryGreen} disabled={isLoading || isOffline ? true : false}>
                {isLoading ? 'Laden...' : 'Einloggen'}
              </Button>
            </div>
            <div className="text-center">
              {isOffline && <InputMessage kind={InputMessageKind.error}>Sie können sich nicht anmelden wenn Sie Offline sind.</InputMessage>}
              {signInError && isOnline && <InputMessage kind={InputMessageKind.error}>{signInError.message}</InputMessage>}
            </div>
          </form>
        </div>
      </div>
      <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-700 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
    </div>
  )
}

export default ClientLogin
