import React from 'react'
import { Button, ButtonKind } from '@aposphaere/ui-components'
import { useHistory } from 'react-router'

const MultiTabFeedback = () => {
  const history = useHistory()

  return (
    <div className="bg-white text-black h-screen w-screen flex items-center justify-center">
      <div className="max-w-5xl flex flex-col items-center">
        <h1 className="text-3xl text-center text-blue-700">
          Die Anwendung ist bereits in einem anderen Tab geöffnet, um die App voll nutzen zu können darf nur ein tab pro Browser Fenster geöffnet
          sein.
        </h1>
        <p className="mt-5 mb-10 text-lg text-gray-700 font-bold">Schließen sie den anderen Tab und laden diesen Tab neu um fortzufahren</p>
        <Button kind={ButtonKind.primary} onClick={history.goBack}>
          Neu Laden
        </Button>
      </div>
    </div>
  )
}

export default MultiTabFeedback
