import React, { useState } from 'react'
import { Button, ButtonKind, Modal, ModalKind } from '@aposphaere/ui-components'
import { useGetAllProjects } from '../../hooks'
import Spinner from '../Spinner'

interface ProjectsModalProps {
  closeModal: () => void
  creatingAppointment?: boolean
  addApointment: (id: string) => void
}

const ProjectsModal = ({ closeModal, addApointment, creatingAppointment }: ProjectsModalProps) => {
  const { allProjects, loadingProjects } = useGetAllProjects()
  const [selectedProjectId, setSelecetdProjectId] = useState<string>()
  return (
    <div className="max-w-4x">
      <Modal kind={ModalKind.sm} noPadding={true} title={'Choose appointment project'} onClose={closeModal} onBack={() => null}>
        <div className="w-full py-8 pl-8 pr-4 xl:pl-10 xl:pr-6 relative">
          {loadingProjects && (
            <div className="text-center bg-white absolute w-full left-0 z-9999">
              <Spinner fixed={false} />
            </div>
          )}
          <select
            className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
            onChange={(event: React.FormEvent<HTMLSelectElement>) => {
              setSelecetdProjectId(event.currentTarget.value)
            }}
          >
            <option value=""></option>
            {allProjects !== undefined &&
              allProjects?.length > 0 &&
              allProjects?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
          </select>
          <div className="flex w-full align-end justify-end mt-8">
            <Button
              disabled={creatingAppointment || selectedProjectId === undefined}
              kind={ButtonKind.outlinedPrimary}
              onClick={() => {
                addApointment(selectedProjectId ? selectedProjectId : '')
              }}
            >
              {creatingAppointment ? 'Laden...' : 'Add Appointment'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default ProjectsModal
