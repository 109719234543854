import React, { useContext, useState } from 'react'
import { ArrowRightIcon } from '@aposphaere/ui-components'
import { useHistory, useLocation } from 'react-router'
import ClientBackground from '../../../components/ClientBackground'
import { AppContext, IAppContext } from '../../../lib/contexts/appContext'
import PrevNextButtons from '../../../components/PrevNextButtons'
import { useCreateEmployee } from '../../../hooks/entities/useEmployees'
import { useDeviceId } from '../../../hooks/useDeviceId'
import { ADD_USER_ROUTE, PRIVACY_POLICY_ROUTE, SETUP_USERS_ROUTE } from '../../../routes'
import { Employee } from '@aposphaere/core-kit/build/models'

const AcceptPrivacyPolicy: React.FC = () => {
  const history = useHistory()
  const location = useLocation<{ userData: Employee }>()
  const { intendedUserCount: usersNumber, addedUsers } = useContext(AppContext) as IAppContext
  const [userData] = useState(location?.state?.userData)
  const uuid = useDeviceId()
  const [createEmployee] = useCreateEmployee()

  const goBack = () => {
    history.push(ADD_USER_ROUTE)
  }

  const goToPrivacyPolicyPage = () => {
    history.push(PRIVACY_POLICY_ROUTE)
  }

  const acceptPrivacyPolicy = (accepted: boolean) => {
    if (accepted) {
      createEmployee(userData, uuid, accepted)
    } else {
      history.push(SETUP_USERS_ROUTE)
    }
  }

  return (
    <ClientBackground>
      <div className="w-6/12 max-w-2xl mb-9">
        <h1 className="text-blue-600 text-2xl font-medium text-center w-full mb-10">{`Nutzer ${
          addedUsers !== undefined ? addedUsers + 1 : ''
        } von ${usersNumber} einrichten`}</h1>
        <p className="text-blue-600 text-lg font-medium text-center w-full">{'Datenschutz:'}</p>
      </div>
      <div className="flex flex-wrap flex-row w-6/12">
        <div className="flex flex-row items-center mb-14">
          <img src="/assets/icons/icon-privacy-policy.svg" alt="" />
          <p className="text-blue-600 text-lg font-medium w-full ml-11">
            {
              'Zur Übermittlung der persönlichen Daten an die Hersteller zum Zweck der Bearbeitung Ihrer Anfragen und Speicherung der Namen der Schulungsteilnehmer benötigen wir Ihr Einverständnis.'
            }
          </p>
        </div>
        <div className="flex flex-row items-center mb-14">
          <img src="/assets/icons/icon-privacy-certification.svg" alt="" />
          <p className="text-orange-600 text-lg font-medium w-full ml-11">
            {
              'Um Ihnen ein Schulungszertifikat digital zusenden zu können, müssen Sie die Datenschutzbedingungen akzeptieren und Ihren Namen sowie ihre persönliche E-Mail-Adresse eingegeben haben.'
            }
          </p>
        </div>
        <button
          onClick={() => goToPrivacyPolicyPage()}
          className="flex font-medium ml-28 items-center text-lg text-blue-600 hover:text-blue-700 focus:outline-none transition ease-in-out duration-150"
        >
          <ArrowRightIcon />
          <span className="ml-2">{'Unsere Datenschutzbedingungen'}</span>
        </button>
      </div>
      <PrevNextButtons
        prevButton={{ onClick: goBack, text: 'Zurück' }}
        nextButton={{ onClick: () => acceptPrivacyPolicy(true), text: 'Akzeptieren' }}
        cancelButton={{ onClick: () => acceptPrivacyPolicy(false), text: 'Ablehnen' }}
      />
    </ClientBackground>
  )
}

export default AcceptPrivacyPolicy
