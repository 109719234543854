import { Employee, Ipad } from '@aposphaere/core-kit/build/models'
import { useCallback, useEffect, useState } from 'react'
import { getIpadWithDeviceId, ipadsCollection, updateIpad } from '../../lib/entities/iPad'
import { setDoc, arrayUnion, collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../firebaseConfig'
import { useDeviceId } from '../useDeviceId'
import { useAuth } from '../../lib/contexts/authContext'

export const useCurrentIpad = () => {
  const deviceId = useDeviceId()
  const { user } = useAuth()
  const trainerCode = user?.entry?.trainer_code
  const [isLoading, setLoading] = useState(true)
  const [ipad, setIpad] = useState<Ipad>()

  useEffect(() => {
    setLoading(true)
    const ipadsQuery = query(ipadsCollection, where('device_id', '==', deviceId), where('trainer_code', '==', trainerCode))

    const unsubscribe = onSnapshot(ipadsQuery, (snapshot) => {
      const ipadList = snapshot.docs.map((doc) => doc.data())
      const foundIpad = ipadList.find((ipad) => ipad.device_id === deviceId)
      setIpad(foundIpad)
      setLoading(false)
      snapshot.docChanges().forEach((change) => {
        let ipadsData = change.doc.data() as Ipad
        setIpad(ipadsData)
      })
    })

    return unsubscribe
  }, [trainerCode, deviceId])
  return { ipad, isLoading }
}

export const useAddIpad = () => {
  const callAPI = useCallback(async (ipadData: Ipad) => {
    try {
      const document = doc(ipadsCollection, ipadData.device_id)
      setDoc(document, ipadData)
      return document
    } catch (error) {
      console.error('Ipad Document error: ', error)
    }
  }, [])
  return [callAPI] as const
}

export const setIpadStatusConnected = async (uuid: string) => {
  const q = query(ipadsCollection, where('device_id', '==', uuid))
  const ipadSnapshots = await getDocs(q)
  const ipadDoc = ipadSnapshots?.docs?.[0]
  return updateDoc(ipadDoc.ref, { connected: true })
}

export const removeQuestionnaireCompletedStatesFromDevice = async (deviceUuid: string) => {
  const ipad = await getIpadWithDeviceId(deviceUuid)
  if (!ipad) {
    return
  }

  const newUsers = ipad?.data().users?.map((employee) => ({ ...employee, questionnaire_status: { all_done: false, required_done: false } }))
  await updateIpad(ipad.id, { users: newUsers })
}

export const addIpadUser = async (user: Employee, uuid: string) => {
  const ipadQuery = query(ipadsCollection, where('device_id', '==', uuid))
  const ipadSnapshot = await getDocs(ipadQuery)
  ipadSnapshot.forEach(async (ipadDocument) => {
    await updateIpad(ipadDocument.id, {
      users: arrayUnion(user),
      pta_ids: arrayUnion(user.id),
    })
  })
}

export const useChangeUserIpad = () => {
  const callAPI = async (userData: Employee & { device_name?: string }) => {
    //delete user from current device
    const queryIpads = query(collection(db, 'ipads'), where('pta_ids', 'array-contains', userData.id))
    const querySnapshotIpads = await getDocs(queryIpads)
    querySnapshotIpads.forEach(async (ipadDoc) => {
      const users = ipadDoc.data().users
      const indexOfUser = users.findIndex((user: Employee & { device_name?: string }) => {
        return user.id === userData.id
      })
      users.splice(indexOfUser, 1)
      const ipadDocRef = doc(db, 'ipads', ipadDoc.id)
      await updateDoc(ipadDocRef, {
        users: users,
      })
    })

    //add user to choosed device
    const queryNewIpads = query(collection(db, 'ipads'), where('device_name', '==', userData.device_name))
    const querySnapshotNewIpads = await getDocs(queryNewIpads)
    querySnapshotNewIpads.forEach(async (newIpadDoc) => {
      const users = newIpadDoc.data().users
      users.push(userData)
      const newIpadDocRef = doc(db, 'ipads', newIpadDoc.id)
      await updateDoc(newIpadDocRef, {
        users: users,
      })
    })
  }
  return [callAPI] as const
}
