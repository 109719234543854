import React from 'react'
import { Button, ButtonKind } from '@aposphaere/ui-components'
import { useHistory } from 'react-router'
import ClientBackground from '../../components/ClientBackground'

const PrivacyPolicy: React.FC = () => {
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <ClientBackground>
      <div className="w-6/12 max-w-2xl mb-9 mt-24">
        <h1 className="text-blue-600 text-2xl font-medium text-center w-full mb-10">Datenschutzerklärung</h1>
        <p className="text-blue-600 text-lg font-medium text-center w-full">Presenter App</p>
      </div>
      <div className="flex flex-wrap flex-row w-6/12 pb-24">
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Wir, die Jäger Health GmbH sowie die aposhäre GmbH (nachfolgend „wir“ oder „aposphäre“), freuen uns über Ihr Interesse an der Presenter App
          (nachfolgend das „App- Angebot).
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Die Presenter App bietet die Möglichkeit die Vorteile von Präsenzschulungen mit denen von Online-Schulungen zu verknüpfen.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Bei Ihrer Nutzung der Presenter App nehmen wir den Schutz Ihrer personenbezogenen Daten sehr ernst. Die Verarbeitung Ihrer personenbezogenen
          Daten findet ausschließlich im Rahmen der gesetzlichen Bestimmungen der EU-Datenschutz-Grundverordnung (DSGVO) und des
          Bundesdatenschutzgesetzes (BDSG) statt. Nachfolgend möchten wir Sie über die Verarbeitung Ihrer personenbezogenen Daten und über Ihre Rechte
          als betroffene Person bei Nutzung der Nutzung der Presenter App informieren.
        </p>
        <p className="text-gray-900 text-lg font-bold w-full mb-4">I. Verantwortliche Stelle im Sinne des Gesetzes ist</p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Für die Bereitstellung des App-Angebots liegt eine gemeinsame Verantwortlichkeit nach Art. 26 DSGVO zwischen den nachfolgend bezeichneten
          Gesellschaften vor:
        </p>
        <ul className="text-gray-900 text-lg font-normal w-full mb-4">
          <li>Jäger Health GmbH – an IQVIA business</li>
          <li>Niehler Straße 104</li>
          <li>50733 Köln</li>
        </ul>
        <ul className="text-gray-900 text-lg font-normal w-full mb-4">
          <li>Tel.: +49 221 650 729 00</li>
          <li>E-Mail: info@jaeger.health</li>
        </ul>
        <ul className="text-gray-900 text-lg font-normal w-full mb-4">
          <li>aposphäre GmbH – an IQVIA business</li>
          <li>Niehler Straße 104</li>
          <li>50733 Köln</li>
        </ul>
        <ul className="text-gray-900 text-lg font-normal w-full mb-4">
          <li>Tel.: +49 221 650 729 50</li>
          <li>E-Mail: info@aposphaere.de</li>
        </ul>
        <p className="text-gray-900 text-lg font-bold w-full mb-4">II. Gegenstand, Zwecke und Rechtsgrundlagen der Datenverarbeitung</p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Gegenstand des Datenschutzes ist der Schutz personenbezogener Daten. Dies sind alle Informationen, die sich auf eine identifizierte oder
          identifizierbare natürliche Person (sog. betroffene Person) beziehen. Hierunter fallen Angaben wie z.B. Name, E-Mail-Adresse oder
          Telefonnummer, aber auch sonstige Informationen, die bei Nutzung der Presenter App verarbeitet werden, insbesondere Angaben über die
          erfolgte Schulung und deren Dauer.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Nachfolgend erhalten Sie einen Überblick über Zwecke und Rechtsgrundlagen der Datenverarbeitung im Rahmen der App-Nutzung.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          <ul className="font-bold list-disc ml-8">
            <li>Angabe persönlicher Daten im Vorfeld der Schulung</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Sie haben die Möglichkeit die folgenden personenbezogenen Daten im Vorfeld der Schulung in der Presenter-App anzugeben:
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          <ul className="ml-10">
            <li>- Anrede</li>
            <li>- Vor- und Nachname</li>
            <li>- Funktion</li>
            <li>- Apotheke (bereits vorausgefüllt)</li>
            <li>- E-Mail-Adresse (wir empfehlen die Angabe der geschäftlichen Firmenadresse)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Die Datenerhebung erfolgt auf Grundlage Ihrer <b>Einwilligung, Art. 6 Abs. 1 lit. a DSGVO.</b> Hierbei handelt es sich um freiwillige Daten,
          welche vom Referenten dazu genutzt werden sie <b>während der Schulung persönlich ansprechen</b> zu können, indem die Angaben auf seinem
          Tablet gespiegelt werden. Nach Beendigung der Schulung werden die Daten dazu genutzt Ihr <b>Schulungszertifikat</b> zu erstellen und Ihnen
          dieses über die angegebene E-Mail- Adresse zukommen zu lassen.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Zudem werden Ihre Daten in unserem <b>CRM-System gespeichert.</b> Dadurch ist es uns möglich Sie bei erneuter Schulung wieder zu erkennen.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Sie haben die <b>Möglichkeit</b> auch ohne Angabe Ihrer Daten <b>anonym</b> an den Schulungen <b>teilzunehmen</b>. Es ist uns dann jedoch
          nicht möglich Ihnen die Teilnahme über das Schulungszertifikat zu bestätigen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8">
            <li>Verknüpfung der Schulungsdaten</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full my-4 mb-4">
          Sofern Sie bereits das Angebot der <b>aposphäre campus App</b> in Anspruch nehmen, werden wir Ihre{' '}
          <b>Schulungserfolge auch in dieser App spiegeln</b>. Zu diesem Zweck greifen wir auf die dort hinterlegten Angaben zu E-Mail-Adresse und
          Ihrem Namen zu und führen einen Abgleich mit den angegebenen Daten der Presenter App durch.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full">
          Die Datenverarbeitung erfolgt auf Grundlage Ihrer Ihrer <b>Einwilligung, Art. 6 Abs. 1 lit. a DSGVO</b>.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-16">
            <li>Empfänger personenbezogener Daten</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full my-4 mb-4">
          Durch uns findet eine Verarbeitung Ihrer personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken und auf Basis
          der hier jeweils aufgeführten Rechtsgrundlagen statt. An externe Empfänger geben wir Ihre personenbezogenen Daten nur weiter, wenn hierfür
          eine gesetzliche Erlaubnis besteht (Abschluss Vertrag zur Auftragsverarbeitung gemäß Art. 28 DSGVO) oder Sie hierzu zugestimmt haben.
          Bezüglich der Auftragsverarbeiter handelt es sich um verbundene Unternehmen oder externe Dienstleister, beispielsweise in den Bereichen der
          technischen Infrastruktur und Wartung. Die Auftragsverarbeiter dürfen die Daten ausschließlich nach unseren Weisungen verwenden und sind
          hierzu vertraglich verpflichtet.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-16">
            <li>Datenübermittlung in Drittstaaten</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mt-4 mb-4">
          Zum Zweck der Verarbeitung und Speicherung der erhobenen personenbezogenen Daten machen wir Gebrauch vom Google Firebase Dienst, Cloud
          Firestore.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Weiter Angaben finden Sie unter:{' '}
          <a href="https://firebase.google.com/terms/data-processing-terms">https://firebase.google.com/terms/data-processing-terms</a>. Die
          Muttergesellschaft von Google Ireland, Google LLC, hat Ihren Sitz in Kalifornien, USA. Eine Übermittlung von Daten in die USA und ein
          Zugriff US-amerikanischer Behörden auf die bei Google gespeicherten Daten kann nicht ausgeschlossen werden.
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Zur Absicherung des Datenschutzniveaus haben wir mit dem Dienstleister EU- Standardvertragsklauseln (SCCs) geschlossen. Soweit Ihre Daten in
          diesem Rahmen außerhalb der EU bzw. des EWR verarbeitet werden, beachten Sie bitte, dass trotz Abschluss von SCCs das Risiko besteht, dass
          Behörden auf die Daten zu Sicherheits- und Überwachungszwecken zugreifen, ohne dass Sie hierüber informiert werden oder Rechtsmittel
          einlegen können.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-16">
            <li>Löschung der Daten</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mt-4 mb-4">
          Sofern wir nicht im Einzelnen bereits über die Speicherdauer informiert haben, löschen wir personenbezogene Daten, wenn sie für die
          vorgenannten Verarbeitungszwecke nicht mehr erforderlich sind und keine gesetzlichen Aufbewahrungspflichten einer Löschung entgegenstehen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-16">
            <li>Profiling</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mt-4 mb-4">
          Wir führen keine automatisierte Entscheidungsfindung bzw. kein Profiling gemäß Art. 22 DSGVO durch. Eine Profilbildung erfolgt nur zur
          Wahrung unserer berechtigten Interessen wie oben beschrieben.
        </p>
        <p className="text-gray-900 text-lg font-bold w-full mb-4">III. Ihre Rechte als Betroffene</p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Auskunftsrecht (Art. 15 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall,
          so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Recht auf Berichtigung (Art. 16 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung
          unvollständiger Daten zu verlangen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Recht auf Löschung (Art. 17 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Sie haben das Recht, zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17
          DSGVO im Einzelnen aufgeführten Gründe zutrifft. Von einer unverzüglichen Löschung muss abgesehen werden, soweit die rechtlichen Vorgaben
          hierzu verpflichten. Insoweit erfolgt die Datenverarbeitung auf Basis des Artikels 6 Absatz 1 Buchstabe c) DSGVO. Die Verpflichtungen können
          sich z.B. aus dem Handels-, Steuer-, Geldwäsche-, Finanz- oder Strafrecht ergeben. Die Zwecke der Verarbeitung ergeben sich aus der
          jeweiligen rechtlichen Verpflichtung; die Verarbeitung dient in der Regel dem Zweck, staatlichen Kontroll- und Auskunftspflichten
          nachzukommen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben
          ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer der Prüfung durch den Verantwortlichen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt sind, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten
          in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Widerrufsrecht (Art. 7 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full">
          Sofern die Verarbeitung von Daten auf Grundlage Ihrer Einwilligung erfolgt, sind Sie nach Art. 7 Abs. 3 DSGVO berechtigt, die Einwilligung
          in die Verwendung Ihrer personenbezogenen Daten jederzeit zu widerrufen. Bitte kontaktieren Sie uns diesbezüglich mit E-Mail an
          info@aposphaere.de oder per Telefon unter der Nummer +49 9179 9440-400. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt.
          Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Widerspruchsrecht (Art. 21 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO (Datenverarbeitung zur Wahrung berechtigter Interessen) oder auf Grundlage
          von Art. 6 Abs. 1 S. 1 lit. e DSGVO (Datenverarbeitung zur Wahrung öffentlichen Interesse oder in Ausübung öffentlicher Gewalt) erhoben,
          steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch
          einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe
          für die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO)</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full">
          Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
          betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in
          dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.
        </p>
        <p>
          <ul className="font-bold list-disc ml-8 mt-4">
            <li>Geltendmachung Ihrer Rechte</li>
          </ul>
        </p>
        <p className="text-gray-900 text-lg font-normal w-full">
          Sofern vorstehend nichts anderes beschrieben wurde, wenden Sie sich zur Geltendmachung Ihrer Betroffenenrechte bitte an die im Impressum
          genannte Stelle.
        </p>
        <p className="text-gray-900 text-lg font-bold w-full mb-4 mt-4">IV. Kontaktdaten des Datenschutzbeauftragten</p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4">
          Unser externer Datenschutzbeauftragter steht Ihnen gerne für Auskünfte zum Thema Datenschutz unter folgenden Kontaktdaten zur Verfügung:
        </p>
        <p className="text-gray-900 text-lg font-normal w-full ">Aposphäre GmbH – an IQVIA business</p>
        <p className="text-gray-900 text-lg font-normal w-full ">Niehler Str.104</p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4 mt-4">50733 Köln</p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4 mt-8">E-Mail: info@aposphaere.de</p>
        <p className="text-gray-900 text-lg font-bold w-full mb-4 mt-4">V. Stand</p>
        <p className="text-gray-900 text-lg font-normal w-full mb-4 mt-2">
          Es gilt die aktuellste Version dieser Datenschutzerklärung. Stand: Oktober 2022.
        </p>
      </div>
      <div className="absolute left-0 bottom-7 px-7">
        <Button kind={ButtonKind.primary} onClick={() => goBack()}>
          {'Zurück'}
        </Button>
      </div>
    </ClientBackground>
  )
}

export default PrivacyPolicy
