import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import ClientBackground from '../components/ClientBackground'
import { useActiveAppointment } from '../lib/contexts/activeAppointmentContext'
import { useAppContext } from '../lib/contexts/appContext'
import { useAuth } from '../lib/contexts/authContext'
import { LOGIN_ROUTE, CLIENT_LOGIN_ROUTE } from '../routes'
import { ButtonKind } from '@aposphaere/ui-components'
import { Modal, ModalKind } from '@aposphaere/ui-components'
import StoreProjectsButton from '../components/StoreProjectsButton'
import { useDeviceId } from '../hooks/useDeviceId'
import { useIsMobile } from '../hooks/useIsMobile'
import CopyButton from '../components/CopyButton'

const EntryScreen: React.FC = () => {
  const { removeActiveAppointment } = useActiveAppointment()
  const { user, logout } = useAuth()
  const uuid = useDeviceId()
  const [showModal, setShowModal] = useState(false)
  const isMobile = useIsMobile()

  const { cleanLocalStorage } = useAppContext()
  const history = useHistory()

  useEffect(() => {
    //When user reach the Entry Screen is needed to logout and clean the local storage
    if (user) {
      removeActiveAppointment()
      logout()
    }
  })

  const goMaster = () => {
    cleanLocalStorage()
    history.push(LOGIN_ROUTE)
  }

  const goClient = () => {
    cleanLocalStorage()
    history.push(CLIENT_LOGIN_ROUTE)
  }

  return (
    <ClientBackground onSettingsClick={() => setShowModal(true)}>
      <div className="w-6/12 max-w-2xl">
        <h1 className="text-blue-600 text-2xl font-medium text-center w-full mb-10">iPad einrichten</h1>
      </div>
      <div className="w-3/5 max-w-screen-md z-50">
        <div className="flex w-full justify-center align-center pt-10">
          <div className="mr-8">
            <button
              onClick={goMaster}
              className="bg-white text-gray-900 border-black-100 border border-solid hover:bg-gray-300 rounded-lg py-3.5 px-5"
            >
              <span className="text-lg font-medium">{'Main'}</span>
            </button>
          </div>
          <div>
            <button
              onClick={goClient}
              className="bg-white text-blue-600 border-blue-600 border border-solid hover:bg-blue-200 rounded-lg py-3.5 px-5"
            >
              <span className="text-lg font-medium">{'Client'}</span>
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal kind={ModalKind.sm} noPadding title={'Unique Device ID:'} onClose={() => setShowModal(false)} onBack={() => null}>
          <div className="flex">
            <span className={'text-xl font-medium text-center ml-6 mt-6 mb-6 pl-2'}>{uuid}</span>
            <CopyButton textToCopy={uuid} />
          </div>
          {isMobile && (
            <div className="mx-5 my-5 w-full">
              <div className="float-right">
                <StoreProjectsButton kind={ButtonKind.outlinedSecondary} />
              </div>
            </div>
          )}
        </Modal>
      )}
    </ClientBackground>
  )
}

export default EntryScreen
