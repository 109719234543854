import React, { FunctionComponent } from 'react'

export type NavBarProps = {
  logoUrl: string
  logoUrlSmall?: string
  logoAlt: string
  children?: React.ReactNode
}

const NavBar: FunctionComponent<NavBarProps> = ({ logoUrl, logoUrlSmall, logoAlt, children }) => (
  <div className="bg-transparent toolbar-ios">
    <div className="w-full shadow-md bg-blue-600 sticky top-0 z-50 justify-between">
      <div className="relative z-10 shadow">
        <div className="transition ease-in-out duration-150 h-12 xl:h-14 w-full flex justify-between items-center px-2 py-4 xl:px-6 sm:py-2 xl:px-6">
          <div className="text-white hidden xl:block">
            <img className="h-9 w-auto" src={`${logoUrl}`} alt={`${logoAlt}`} />
          </div>
          <div className="mr-2 text-white block xl:hidden">
            <img className="h-8 w-auto" src={`${logoUrlSmall}`} alt={`${logoAlt}`} />
          </div>
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default NavBar
