import { Employee } from '@aposphaere/core-kit/build/models'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'

export interface Answer {
  answer: string | number | (string | number)[]
  question_id: number
  pharmacy_id: number | undefined
  statistic_id: string
  pta: Employee
  pta_id: string
}

const { collection, ...answer } = initFirebaseEntity<Answer>('answers')

export const answersCollection = collection

export const getAnswer = answer.get
export const updateAnswer = answer.update
export const deleteAnswer = answer.delete
export const createAnswer = answer.create
export const getAnswers = answer.getMany

export const saveAnswer = async (answerData: Answer) => {
  try {
    //If the answer for this user, this question, project and pharamcy exist update it, otherwise add the answer
    const foundAnswers = await getAnswers({
      where: {
        question_id: answerData.question_id,
        pta_id: answerData.pta_id,
        pharmacy_id: answerData.pharmacy_id,
      },
    })

    if (foundAnswers.empty) {
      answer.create(answerData)
    } else {
      foundAnswers.forEach(async (answerDoc) => {
        answer.update(answerDoc.id, {
          answer: answerData.answer,
        })
      })
    }
  } catch (error) {
    console.error('Ipad Document error: ', error)
  }
}
