import React, { useCallback, useEffect, useState } from 'react'
import { Pharmacy } from '@aposphaere/core-kit'
import { InputLabel, TabBar, TabBarItem, TabBarItemKind, TextInput } from '@aposphaere/ui-components'
import AppointmentCard from '../../components/AppointmentCard'
import CustomerCard from '../../components/CustomerCard'
import PageTitleSection from '../../components/PageTitleSection'
import Spinner from '../../components/Spinner'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { deleteActiveAppointments } from '../../lib/entities/activeAppointment'
import { deletePresentationsForTrainerId } from '../../lib/entities/presentation'
import { ExtendedAppointment, fetchExtendedAppointments } from '../../lib/entities/appointment'
import { useAsync } from '../../hooks/useAsync'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { fetchPharmaciesOfUser } from '../../lib/entities/pharmacy'
import { useAuth } from '../../lib/contexts/authContext'

enum Tabs {
  AppointmentsToday = 'appointments-today',
  AllCustomers = 'all-customers',
}

const Customers: React.FC = () => {
  const [activeTab, setActiveTab] = useState(Tabs.AppointmentsToday)
  const [filteredCustomers, setFilteredCustomers] = useState<Pharmacy[] | undefined>(undefined)
  const [filterValue, setFilterValue] = useState<string>('')
  const { isOnline } = useNetworkMode()
  const { removeActiveAppointment } = useActiveAppointment()

  const { run: startGettingAppointments, setData: setAppointments, data: appointments, isLoading: appointmentsLoading } = useAsync<
    null | ExtendedAppointment[]
  >(null)
  const { run: startGettingCustomers, setData: setCustomers, data: customers, isLoading: customersLoading } = useAsync<null | Pharmacy[]>(null)

  const isLoading = customersLoading || appointmentsLoading
  const auth = useAuth()

  const reloadPageData = useCallback(() => {
    setCustomers([])
    setAppointments([])
    if (!auth?.user?.entry?.id) {
      return
    }
    if (activeTab === Tabs.AllCustomers) {
      startGettingCustomers(fetchPharmaciesOfUser(Number(auth?.user?.entry?.id)))
    }

    if (activeTab === Tabs.AppointmentsToday) {
      startGettingAppointments(fetchExtendedAppointments(new Date(), auth.user?.entry?.id))
    }
  }, [activeTab, auth.user?.entry?.id, setAppointments, setCustomers, startGettingAppointments, startGettingCustomers])

  useEffect(() => {
    reloadPageData()
  }, [reloadPageData, isOnline, activeTab])

  // Filter handling
  useEffect(() => {
    const applyFilters = (value: string) => {
      if (customers && value?.length) {
        const newCustomers = customers?.filter((customer) => {
          if (
            (value.length ? customer?.name?.toLocaleLowerCase()?.includes(value.toLocaleLowerCase()) : true) ||
            (value.length ? customer?.address?.address_name?.toLocaleLowerCase()?.includes(value.toLocaleLowerCase()) : true)
          ) {
            return customer
          } else {
            return null
          }
        })
        setFilteredCustomers(newCustomers)
      }
    }

    if (filterValue.length >= 3) {
      applyFilters(filterValue)
    } else if (customers) {
      setFilteredCustomers(customers)
    }
  }, [filterValue, customers])

  // Initialize a clean state
  useEffect(() => {
    removeActiveAppointment()
    const trainerCode = auth.user?.entry?.trainer_code
    if (trainerCode) {
      deleteActiveAppointments({ where: { trainerCode } })
      deletePresentationsForTrainerId(trainerCode)
    }
  }, [auth.user?.entry?.trainer_code, removeActiveAppointment])

  return (
    <>
      <div className="w-full min-h-screen flex flex-col">
        <PageTitleSection pageTitle="Kunde & Termine" handleClick={reloadPageData} iconType="refresh" />
        <div className="py-8">
          <div className="flex flex-col max-w-3xl mx-auto justify-center">
            <TabBar justifyContent="justify-center">
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={activeTab === Tabs.AppointmentsToday}
                onClick={() => setActiveTab(Tabs.AppointmentsToday)}
              >
                <span className="text-lg px-4">{'Termine heute'}</span>
              </TabBarItem>
              <TabBarItem kind={TabBarItemKind.secondary} selected={activeTab === Tabs.AllCustomers} onClick={() => setActiveTab(Tabs.AllCustomers)}>
                <span className="text-lg px-4">{'Alle Kunden'}</span>
              </TabBarItem>
            </TabBar>
            {isLoading && (
              <div className="mt-8 text-center p-5 border border-gray-400 rounded-sm bg-white">
                <Spinner fixed={false} />
              </div>
            )}
            {activeTab === Tabs.AppointmentsToday && !isLoading && (
              <div className="mt-8">
                {appointments?.length ? (
                  appointments.map((appointment, index) => {
                    return <AppointmentCard key={index} index={index} appointment={appointment} />
                  })
                ) : (
                  <div className="w-full py-2 text-lg text-gray-600 text-center">{'Für heute wurden keine Termine gefunden.'}</div>
                )}
              </div>
            )}
            {activeTab === Tabs.AllCustomers && !isLoading && (
              <div className="mt-8">
                <div className="mb-8" data-testid="search">
                  <InputLabel>
                    <span className="text-gray-900 mb-2.5">{'APOTHEKE SUCHEN:'}</span>
                  </InputLabel>
                  <TextInput
                    value={filterValue}
                    disabled={false}
                    bgColor="white"
                    onChange={(e) => setFilterValue(e.currentTarget.value)}
                    type="text"
                  />
                </div>
                {!!filteredCustomers?.length &&
                  filteredCustomers.map((customer, index) => {
                    return <CustomerCard key={index} index={index} customer={customer} />
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Customers
