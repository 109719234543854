import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { PresentationStatus } from '../../lib/constants'
import { Ipad } from '@aposphaere/core-kit/build/models'
import { usePresentation } from '../../lib/contexts/presentationContext'
import { presentationCollection } from '../../lib/entities/presentation'

export const useSetPresentationTimes = () => {
  const { presentationSnapshot } = usePresentation()

  const setPresentationTimes = async (presentationTimeInSeconds: number, bookedTimeInSeconds?: number, minTimeInSeconds?: number) => {
    if (!presentationSnapshot) {
      console.error('There is no active presentation')
      return
    }
    await updateDoc(presentationSnapshot.ref, {
      presentation_time_in_seconds: presentationTimeInSeconds,
      booked_time_in_seconds: bookedTimeInSeconds,
      min_time_in_seconds: minTimeInSeconds,
    })
  }
  return setPresentationTimes
}

export const useEndPresentation = () => {
  const { presentationSnapshot } = usePresentation()

  const endCurrentPresentation = async (presentationTimeInSeconds: number) => {
    if (!presentationSnapshot) {
      console.error('There is no active presentation')
      return
    }
    updateDoc(presentationSnapshot.ref, {
      end_date: new Date(),
      status: PresentationStatus.Inactive,
      presentation_time_in_seconds: presentationTimeInSeconds,
    })
  }
  return endCurrentPresentation
}

export const updatePresentationConnectedIpads = async (presentationDocId: string, ipads: Ipad[]) => {
  let connectedIpads: Ipad[] = ipads.filter((ipad) => ipad?.connected)

  try {
    const presentationsSnapshot = await getDoc(doc(presentationCollection, presentationDocId))
    await updateDoc(presentationsSnapshot.ref, {
      connectedIpads: connectedIpads,
    })
  } catch (error) {
    console.error('error: ', error)
  }
}
