import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { query } from 'firebase/firestore'
import { ButtonKind } from '@aposphaere/ui-components'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { usePresentation } from '../../lib/contexts/presentationContext'
import { useAuth } from '../../lib/contexts/authContext'
import { addParticipantToStatistic, statisticCollection, statisticWhere } from '../../lib/entities/statistic'
import { useQuerySubscription } from '../../hooks/useQuerySubscription'
import PageTitleSection from '../../components/PageTitleSection'
import { getTimeFromTimestamps } from '../../lib/utils/convertTime'
import PrevNextButtons from '../../components/PrevNextButtons'
import { END_SCREEN_ROUTE } from '../../routes'

type StatisticsBarType = {
  title: string
  time?: string
  testId?: number | string
  participants: number
  onAddUserClick: () => void
}

const StatisticsBar: React.FC<StatisticsBarType> = ({ title, testId, time = 0, onAddUserClick, participants }) => {
  return (
    <div className="w-full p-4 font-body font-medium">
      <div className="border flex flex-col bg-white">
        <div className="text-lg text-blue-400 py-4 px-6 border-b-2">{title}</div>
        <div className="flex flex-row">
          <div className="flex-1 flex flex-col py-8 px-10">
            <div className="text-base">DAUER (STD:MIN)</div>
            <div className="text-4xl text-blue-600">{time}</div>
          </div>
          <div className="w-1 h-28 bg-gray-100 my-3"></div>
          {participants !== undefined ? (
            <>
              <div className="w-1 h-28 bg-gray-100 my-3"></div>

              <div className="flex-1 flex flex-col py-8 px-10">
                <div className="text-base">TEILNEHMER</div>
                <div className="flex flex-row justify-between text-4xl text-blue-700">
                  <div className="px-8" data-testid={`user-amount-${testId}`}>
                    {participants}
                  </div>
                  <button aria-label="Add participant" onClick={onAddUserClick} data-testid={`add-participant-${testId}`}>
                    {' '}
                    <img src="/assets/icons/add-participant-icon.svg" alt="" className="mr-2 px-8" />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="flex-1 flex flex-col py-8 px-10" />
          )}
        </div>
      </div>
    </div>
  )
}

const Statistics: React.FC = () => {
  const { pharmacy } = usePharmacy()
  const currentPresentation = usePresentation()
  const history = useHistory()
  const { user } = useAuth()

  const trainerCode = user?.entry?.trainer_code
  const appointmentDocID = currentPresentation.presentation?.appointment_doc_id

  const statisticQuery = useMemo(() => {
    if (!appointmentDocID || !trainerCode) {
      console.warn("Missing data. Can't fetch statistics")
      return null
    }
    return query(statisticCollection, statisticWhere('appointment_doc_id', '==', appointmentDocID))
  }, [appointmentDocID, trainerCode])

  const { data: statisticsSnapshot } = useQuerySubscription(statisticQuery)

  return (
    <div className="w-full">
      <PageTitleSection pageTitle="Statistik" label={pharmacy?.name ? `${pharmacy.name} | Slot 1` : ''} iconType="home" />
      {statisticsSnapshot?.docs?.map((doc, index) => {
        const statistic = doc.data()

        if (!statistic?.project_name || !statistic?.project_id || !statistic?.start_time || !statistic?.end_time) {
          return null
        }
        return (
          <StatisticsBar
            onAddUserClick={() => addParticipantToStatistic(doc.id)}
            key={doc.id}
            testId={index}
            title={statistic.project_name}
            time={getTimeFromTimestamps(statistic.start_time, statistic.end_time)}
            participants={statistic.participants || 0}
          />
        )
      })}
      <PrevNextButtons
        nextButton={{
          onClick: () => history.push(END_SCREEN_ROUTE),
          kind: ButtonKind.primary,
          text: 'Beenden',
        }}
      />
    </div>
  )
}

export default Statistics
