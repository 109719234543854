import { initFirebaseEntity } from '../utils/initFirebaseEntity'

interface OrderItem {
  amount: number
  appointment_doc_id: string
  project_id: number
}

export const orderItem = initFirebaseEntity<OrderItem>('order_items')
export const orderItemCollection = orderItem.collection
export const getOrderItems = orderItem.get
export const updateOrderItem = orderItem.update
export const createOrderItem = orderItem.create
export const orderItemWhere = orderItem.where
