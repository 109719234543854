import { BlobReader, ZipReader } from '@zip.js/zip.js'

export const ProjectZip = {
  async download(zipUrl?: string) {
    if (!zipUrl) {
      console.warn('Project can not be downloaded: Missing the zip url')
      return null
    }
    return fetch(zipUrl, {
      mode: 'cors',
    })
  },
  async unzip(zipBlob: Blob) {
    const blobReader = new BlobReader(zipBlob)
    const zipReader = new ZipReader(blobReader)
    const entries = await zipReader.getEntries()
    const directories = entries.filter((entry) => entry.directory)
    const files = entries.filter((entry) => !entry.directory)
    return { entries, directories, files }
  },
}
