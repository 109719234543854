import React from 'react'
import { getTrackBackground, Range } from 'react-range'

export interface QuestionnaireSliderProps {
  step: number
  min: { value: number; label?: string }
  max: { value: number; label?: string }
  value: number
  setValue: (val: number) => void
}

export function QuestionnaireSlider({ step, min, max, setValue, value }: QuestionnaireSliderProps) {
  return (
    <div className="grid justify-center align-center" style={{ gridTemplateColumns: '1fr auto 1fr' }}>
      <span className="text-blue-600 text-right my-2 mx-8 text-lg">{min?.label || min.value}</span>
      <Range
        values={[Number(value)]}
        step={step}
        min={min.value}
        max={max.value}
        onChange={(values) => {
          setValue(values[0])
        }}
        renderTrack={({ props, children }) => (
          <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} className="flex mt-1 h-9" style={{ ...props.style }}>
            <div
              ref={props.ref}
              className="self-center h-5 w-64 rounded-full"
              style={{
                background: getTrackBackground({
                  values: [value],
                  colors: ['#228BB8', '#D6DBE0'],
                  min: min.value,
                  max: max.value,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            className="flex focus:outline-none justify-center w-10 h-10 items-center rounded-full bg-white"
            style={{ ...props.style, boxShadow: '0px 2px 6px #AAA' }}
          >
            <div className={`h-4 w-1 ${isDragged ? 'bg-blue-600' : 'bg-gray-400'}`} />
            <output className="absolute mt-20 text-blue-600" id="output">
              {[value]}
            </output>
          </div>
        )}
      />
      <span className="text-left text-blue-600 my-2 mx-8 text-lg">{max?.label || max.value}</span>
    </div>
  )
}
