import { useCallback, useState } from 'react'
import { ProjectStorage, StoredProjectDetails } from '../lib/utils/storePresentations'
import { StoredStatus } from '../lib/utils/storePresentations/config'

interface StoreProjectsReturnValue {
  projects: ProjectState[]
  checkProjects: () => Promise<StoredProjectDetails[]>
  checkProject: (projectId: string | number) => Promise<StoredProjectDetails | null>
  storeProjects: (details: StoredProjectDetails[]) => void
  storeProject: (projectDetails: StoredProjectDetails) => void
  status: 'idle' | 'downloading-images' | 'downloading-projects'
  isInitialized: boolean
}

interface ProjectState extends StoredProjectDetails {
  status: 'idle' | 'checking' | 'error' | 'downloading-zip' | 'unzipping' | 'deleting-old-version' | 'writing-the-files'
}

export const useStoreProjects = (): StoreProjectsReturnValue => {
  const [status, setStatus] = useState<'idle' | 'downloading-images' | 'downloading-projects'>('idle')
  const [projectStates, setProjectStates] = useState<ProjectState[]>([])
  const [isInitialized, setInitialized] = useState(false)

  const isBusy = status === 'downloading-images' || status === 'downloading-projects'

  const updateProjectState = useCallback((data: ProjectState) => {
    setProjectStates((states) =>
      states.map((projectState) => {
        const isProjectToUpdate = projectState.id === data.id
        if (isProjectToUpdate) {
          return { ...projectState, ...data }
        }
        return projectState
      }),
    )
  }, [])

  /*
   * STORE
   */
  const storeProject = useCallback(
    async (projectDetails: StoredProjectDetails) => {
      if (isBusy) {
        return
      }
      try {
        await ProjectStorage.download(projectDetails, {
          onDownloadStart: () => updateProjectState({ ...projectDetails, status: 'downloading-zip' }),
          onUnzipStart: () => updateProjectState({ ...projectDetails, status: 'unzipping' }),
          onWriteStart: () => updateProjectState({ ...projectDetails, status: 'writing-the-files' }),
        })
        updateProjectState({ ...projectDetails, storedState: StoredStatus.UpToDate, status: 'idle' })
      } catch {
        updateProjectState({ ...projectDetails, status: 'idle' })
      }
    },
    [isBusy, updateProjectState],
  )

  const storeProjects = useCallback(
    async (projectDetails: StoredProjectDetails[]) => {
      try {
        if (isBusy) {
          console.warn('Already doing something')
          return
        }
        setStatus('downloading-images')
        projectDetails.forEach(async (projectState) => {
          if (projectState.previewUrl) {
            await ProjectStorage.storePreviewImage(projectState.id, projectState.previewUrl)
          }
        })
        setStatus('downloading-projects')
        projectDetails.forEach(async (project) => await storeProject(project))
        setStatus('idle')
      } catch (e) {
        console.error(e)
        setStatus('idle')
      }
    },
    [isBusy, storeProject],
  )

  /*
   * CHECK
   */
  const checkProjects = useCallback(async () => {
    const states = await ProjectStorage.checkAll()
    const newProjectStates = states.map((state) => ({ ...state, status: 'idle' } as const))
    setProjectStates(newProjectStates)
    setInitialized(true)
    return states
  }, [])

  const check = useCallback(
    async (projectId: string | number) => {
      const state = await ProjectStorage.check(projectId)
      if (state) {
        updateProjectState({ ...state, status: 'idle' })
        return state
      }
      return null
    },
    [updateProjectState],
  )

  return { storeProjects, isInitialized, storeProject, checkProjects, checkProject: check, projects: projectStates, status }
}
