import React from 'react'
import { HomeIcon, RefreshIcon } from '@aposphaere/ui-components'

interface PageTitleSectionProps {
  pageTitle: string
  handleClick?: () => void
  label?: string
  iconType: string
}

const PageTitleSection = ({ pageTitle, handleClick, iconType, label }: PageTitleSectionProps) => (
  <div className="flex w-full justify-between sticky z-40 px-2 py-4 xl:px-6 bg-white shadow-md">
    <h1 className="text-blue-700 text-xl font-medium my-0 pt-1.5">{pageTitle}</h1>
    <div className="text-gray-500 flex justify-end">
      <button
        onClick={handleClick || undefined}
        className="flex items-center px-2 py-2 hover:text-blue-600 focus:outline-none transition ease-in-out duration-150"
      >
        {iconType === 'refresh' && <RefreshIcon />}
        {iconType === 'home' && <HomeIcon />}
        {label && <span className="ml-2 text-lg font-medium">{label}</span>}
      </button>
    </div>
  </div>
)

export default PageTitleSection
