import { Employee, EmployeePosition, Pharmacy } from '@aposphaere/core-kit/build/models'
import { getDocs, query, collection, getDoc, doc, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from '../../firebaseConfig'

const getPharamcyEmployees = async (pharmacyId: number | null) => {
  const q = query(collection(db, 'ptas'), where('pharmacy_id', '==', pharmacyId), where('is_anonymous_user', '==', false))
  const snapshot = await getDocs(q)
  const data = snapshot.docs.map((doc) => doc.data() as Employee)
  return data
}

export const usePharmacyEmployeePositions = () => {
  const [employeePositions, setEmployeePositions] = useState<EmployeePosition[] | null>(null)
  const [loadingEmployeePositions, setLoadingEmployeePositions] = useState<boolean>(false)

  useEffect(() => {
    const fetchPharmacyEmployees = async () => {
      setLoadingEmployeePositions(true)
      const data: EmployeePosition[] = []
      const q = query(collection(db, 'positions'))
      const snapshot = await getDocs(q)
      snapshot.docs.map(async (doc) => {
        const position = doc.data() as EmployeePosition
        data.push(position)
      })
      setEmployeePositions(data)
      setLoadingEmployeePositions(false)
    }
    fetchPharmacyEmployees().catch(console.error)
  }, [])

  return { loadingEmployeePositions, employeePositions }
}

const getRelatedPharmacies = async (pharmacyId: number | null) => {
  const snapshot = await getDoc(doc(db, 'pharmacies', pharmacyId + ''))
  const pharmacy = snapshot.data() as Pharmacy
  const relatedPharmacies: number[] = pharmacy?.related_pharmacy_ids || []
  pharmacyId && relatedPharmacies.push(pharmacyId)

  return relatedPharmacies
}

export const useCurrentAndRelatedPharmacyEmployees = (pharmacyId: number | null) => {
  const [allEmployees, setAllEmployees] = useState<Employee[]>([])

  useEffect(() => {
    const fetchEmployees = async () => {
      const relatedPharmacies = await getRelatedPharmacies(pharmacyId)
      const employeesData: Employee[] = []
      relatedPharmacies?.map(async (id) => {
        const data = await getPharamcyEmployees(id)
        data?.map((e) => {
          return employeesData.push(e)
        })
      })
      setAllEmployees(employeesData)
    }
    fetchEmployees().catch(console.error)
  }, [pharmacyId])

  return { allEmployees }
}
