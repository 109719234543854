import React, { useCallback } from 'react'
import { IconButton, IconButtonKind, toast, CopyIcon } from '@aposphaere/ui-components'

export const CopyButton = ({ textToCopy }: { textToCopy: string }) => {
  const copyId = useCallback(() => {
    navigator.clipboard.writeText(textToCopy)
    toast.show({
      type: 'success',
      headline: 'Text wurde kopiert.',
    })
  }, [textToCopy])

  return <IconButton kind={IconButtonKind.custom} icon={<CopyIcon />} additionalCss="focus:outline-none text-blue-700 pl-3" onClick={copyId} />
}

export default CopyButton
