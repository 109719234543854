import React, { ReactNode } from 'react'
import Header from '../Header'

interface Props {
  content?: ReactNode
}

const WaitingOverlay = (props: Props) => {
  return (
    <div className="absolute w-full h-screen flex flex-wrap overflow-hidden bg-orange-100 font-body">
      <Header />
      <div className="flex absolute w-full h-full bg-no-repeat bg-center flex-col justify-center items-center">
        <img className="absolute right-0 mt-44 -mr-44 animate-spin-slow top-0" src="/login-rotate-1.svg" alt="" />
        <img className="absolute bottom-28 animate-wiggle -ml-24 left-0" src="/login-rotate-2.svg" alt="" />
        <img className="absolute right-0 mt-32 -mr-96 top-0" src="/assets/icons/bg-login.svg" alt="" />
        <img src="/assets/icons/icon-wait.svg" alt="" className="mb-12 mx-auto" />
        {props?.content ? props.content : (
            <h1 className="text-blue-600 text-xl2 font-medium text-center w-full">{'Gleich geht’s weiter…'}</h1>
        )}
      </div>
      <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-400 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
    </div>
  )
}

export default WaitingOverlay
