import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { useActiveAppointment } from '../../../lib/contexts/activeAppointmentContext'
import { useNetworkMode } from '../../../lib/contexts/networkStatusContext'
import {
  ACCEPT_PRIVACY_POLICY_ROUTE,
  ADD_USER_ROUTE,
  HOME_PAGE_ROUTE,
  SETUP_USERS_ROUTE,
  USERS_IPAD_CHANGE_ROUTE,
  USERS_NUMBER_ROUTE,
  USER_SETUP_ROOT_ROUTE,
} from '../../../routes'
import NotFound from '../../NotFound'
import AcceptPrivacyPolicy from './AcceptPrivacyPolicy'
import AddUser from './AddUser'
import SetupUsers from './SetupUsers'
import UsersIpadChange from './UsersIpadChange'
import UserCount from './UsersNumber'

export const ClientUserSetupRoutes = () => {
  const history = useHistory()
  const { activeAppointment, isLoading: activeAppointmentLoading } = useActiveAppointment()
  const { isOffline } = useNetworkMode()

  useEffect(() => {
    if (isOffline) {
      return
    }
    if (!activeAppointment && !activeAppointmentLoading) {
      history.push(HOME_PAGE_ROUTE)
    }
  }, [activeAppointment, activeAppointmentLoading, history, isOffline])

  return (
    <Switch>
      <Route exact path={USER_SETUP_ROOT_ROUTE}>
        <Redirect to={USERS_NUMBER_ROUTE} />
      </Route>
      <Route path={USERS_NUMBER_ROUTE}>
        <UserCount />
      </Route>
      <Route path={SETUP_USERS_ROUTE}>
        <SetupUsers />
      </Route>
      <Route path={ADD_USER_ROUTE}>
        <AddUser />
      </Route>
      <Route path={ACCEPT_PRIVACY_POLICY_ROUTE}>
        <AcceptPrivacyPolicy />
      </Route>
      <Route path={USERS_IPAD_CHANGE_ROUTE}>
        <UsersIpadChange />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}
