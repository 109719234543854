import React from "react";
import { Modal, ModalKind } from "@aposphaere/ui-components";

interface AppointmentModalProps {
  closeModal: () => void,
  message: string
}

const AppointmentModal = ({ closeModal, message }: AppointmentModalProps) => {
  return(
    <div className="max-w-4x">
      <Modal kind={ModalKind.sm} noPadding={true} title={''} onClose={closeModal} onBack={() => null}>
        <div className="w-full p-8">
          <p className="text-lg font-medium text-gray-700">{message}</p>
        </div>
      </Modal>
    </div>
  )
}
export default AppointmentModal