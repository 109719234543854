import { Directory } from '@capacitor/filesystem'

export enum StoredStatus {
  Outdated = 'outdated',
  UpToDate = 'up-to-date',
  Missing = 'missing',
}

export const APP_DIRECTORY = Directory.Documents
export const APP_ROOT_PATH = 'presenter-app'
export const PROJECTS_PATH = APP_ROOT_PATH + '/' + 'projects'
export const PROJECT_CHECKSUM_FILENAME = 'checksum.txt'
export const PREVIEW_IMAGE_FILENAME_WITHOUT_EXTENSION = 'preview___image___'
