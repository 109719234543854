import { Encoding, Filesystem } from '@capacitor/filesystem'
import { ProjectsDirectory } from '.'
import { APP_DIRECTORY, PROJECT_CHECKSUM_FILENAME } from './config'

export const ProjectChecksum = {
  async read(projectId: string | number) {
    try {
      const rootDir = ProjectsDirectory.getProjectRootPath(projectId)
      const checksumFile = await Filesystem.readFile({
        directory: APP_DIRECTORY,
        path: `${rootDir}/${PROJECT_CHECKSUM_FILENAME}`,
        encoding: Encoding.UTF8,
      })
      return checksumFile.data
    } catch {
      return ''
    }
  },
  async write(projectId: string, checksum: string) {
    const rootDir = ProjectsDirectory.getProjectRootPath(projectId)
    await Filesystem.writeFile({
      data: checksum,
      encoding: Encoding.UTF8,
      directory: APP_DIRECTORY,
      path: `${rootDir}/${PROJECT_CHECKSUM_FILENAME}`,
      recursive: true,
    })
  },
}
