import { ConnectionStatus, Network } from '@capacitor/network'
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { disableNetwork as disableFirebaseNetwork, enableNetwork as enableFirebaseNetwork } from 'firebase/firestore'
import { db } from '../../firebaseConfig'
import { getPlatforms } from '@ionic/react'
import { useAuth } from './authContext'

/*
 * CONTEXT
 */
type NetworkStatusContextValue = {
  isConnected: boolean | undefined
  isOnline: boolean | undefined
  isOffline: boolean | undefined
  networkMode: 'online' | 'offline' | undefined
  offlineModeConfirmation: 'needed' | 'granted' | undefined
  confirmOfflineMode: () => void
}

const NetworkStatusContext = createContext<void | NetworkStatusContextValue>(undefined)
/*
 * PROVIDER
 */
export const NetworkStatusProvider = ({ children }: { children: ReactNode }) => {
  const hasNetworkConnection = useNetworkConnection()
  const { user } = useAuth()
  const isUserLoggedIn = !!user
  const [networkMode, setNetworkMode] = useState<'offline' | 'online' | undefined>()
  const [offlineModeConfirmationState, setOfflineModeConfirmationState] = useState<'needed' | 'granted' | undefined>()

  const initialized = typeof networkMode !== 'undefined' && typeof hasNetworkConnection !== 'undefined'

  useEffect(() => {
    if (!initialized && typeof hasNetworkConnection !== 'undefined') {
      setNetworkMode(hasNetworkConnection ? 'online' : 'offline')
    }
  }, [hasNetworkConnection, initialized])

  useEffect(() => {
    if (isUserLoggedIn || typeof hasNetworkConnection === 'undefined') {
      return
    }
    // Sync online mode with the network status
    setNetworkMode(hasNetworkConnection ? 'online' : 'offline')
  }, [hasNetworkConnection, isUserLoggedIn])

  // Handle the offline mode confirmation state
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (!isUserLoggedIn) {
      setOfflineModeConfirmationState(undefined)
      return
    }
    if (!hasNetworkConnection) {
      timeout = setTimeout(() => {
        setOfflineModeConfirmationState('needed')
      }, 1000)
    }
    return () => clearTimeout(timeout)
  }, [hasNetworkConnection, isUserLoggedIn])

  const confirmOfflineMode = () => setOfflineModeConfirmationState('granted')

  // Remove confirmation prompt, if we gain connection
  useEffect(() => {
    if (hasNetworkConnection) {
      setOfflineModeConfirmationState(undefined)
    }
  }, [hasNetworkConnection])

  // Handle offline mode, if the user allowed it
  useEffect(() => {
    if (offlineModeConfirmationState === 'granted') {
      setNetworkMode('offline')
    }
  }, [offlineModeConfirmationState])

  // Sync the firebase network state with our connected status
  useEffect(() => {
    const handleFirebaseNetworkState = async () => {
      if (networkMode === 'online') {
        await enableFirebaseNetwork(db)
      }
      if (networkMode === 'offline') {
        await disableFirebaseNetwork(db)
      }
    }
    handleFirebaseNetworkState()
  }, [networkMode])

  if (!initialized) {
    return <div></div>
  }

  return (
    <NetworkStatusContext.Provider
      value={{
        confirmOfflineMode,
        networkMode,
        offlineModeConfirmation: offlineModeConfirmationState,
        isOnline: networkMode === 'online',
        isOffline: networkMode === 'offline',
        isConnected: hasNetworkConnection,
      }}
    >
      {children}
    </NetworkStatusContext.Provider>
  )
}

/*
 * CONSUMER HOOK
 */
export const useNetworkMode = () => {
  const context = useContext(NetworkStatusContext)
  if (!context) {
    throw new Error('Missing provider for the useNetworkMode hook')
  }
  return context
}

function useNetworkConnection() {
  const [networkStatus, setNetworkStatus] = useState<ConnectionStatus>()

  const isConnected = useMemo(() => {
    let connectedStatus = networkStatus?.connected
    const platforms = getPlatforms()
    const nonWebPlatforms: typeof platforms[0][] = ['android' || 'ios']
    const isMobile = platforms.some((platform) => nonWebPlatforms.includes(platform))

    if (isMobile && networkStatus?.connectionType === 'unknown') {
      connectedStatus = false
    }

    return connectedStatus
  }, [networkStatus?.connected, networkStatus?.connectionType])

  useEffect(() => {
    const initialize = async () => {
      try {
        Network.addListener('networkStatusChange', (currentStatus) => {
          setNetworkStatus(currentStatus)
        })

        const initialStatus = await Network.getStatus()
        setNetworkStatus(initialStatus)
      } catch (err) {
        console.error('error during initializing the network status', err)
      }
    }
    initialize()
  }, [])

  return isConnected
}
