import { Ipad } from '@aposphaere/core-kit/build/models'
import { deleteField, getDocs, query } from 'firebase/firestore'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'

/*
 * TYPES
 */
enum PresentationStatus {
  Inactive = 'inactive',
  Active = 'active',
}

type TimestampString = string
export interface Presentation {
  current_slide: string
  name: string
  pharmacy_id: number
  status: PresentationStatus
  clicked_pop_up?: string
  trainer_code: string
  video_src?: string | null | undefined
  trainer_id: number
  end_date?: Date | TimestampString
  connectedIpads: Ipad[]
  appointment_doc_id: string
  presentation_time_in_seconds?: number
  slot_id?: number
  booked_time_in_seconds?: number
  min_time_in_seconds?: number
  project_id: number
}

const { collection, ...presentation } = initFirebaseEntity<Presentation>('presentations')

export const presentationCollection = collection
export const presentationWhere = presentation.where

/*
 * GET
 */
export const getPresentation = presentation.get
export const getPresentations = presentation.getMany

export const getPresentationsOfAppointment = (appointmentDocId: string, trainerCode: string) =>
  getDocs(query(collection, presentation.where('appointment_doc_id', '==', appointmentDocId), presentation.where('trainer_code', '==', trainerCode)))

/*
 * UPDATE
 */
export const updatePresentation = presentation.update
export const updatePresentationCurrentSlide = (id: string, slide: string) => {
  presentation.update(id, { current_slide: slide })
}

/**
 * DELETE
 */
export const deletePresentationsForTrainerId = async (trainerId: string) => {
  const presentationsToDelete = await getDocs(query(collection, presentation.where('trainer_code', '==', trainerId)))

  return Promise.all(presentationsToDelete.docs?.length ? presentationsToDelete.docs.map((query) => presentation.delete(query.id)) : [])
}

/**
 * UPDATE
 */
export const endPresentationsForTrainerId = async (trainerId: string) => {
  const presentationSnapshotsToEnd = await getDocs(query(collection, presentation.where('trainer_code', '==', trainerId)))
  const presentationsToEnd = presentationSnapshotsToEnd.docs || []
  const updatePromises = presentationsToEnd.map((doc) => presentation.update(doc.id, { status: PresentationStatus.Inactive }))
  return Promise.all(updatePromises)
}

export const updateClickedPopUp = (id: string, clickId: string | null) => {
  if (clickId) {
    presentation.update(id, { clicked_pop_up: clickId })
  }
}

/*
 * UPDATE
 */
export const updateVideoSrc = async (src: string | null | undefined, id: string | undefined) => {
  if (id && src) {
    presentation.update(id, { video_src: src })
  } else if (id) {
    presentation.update(id, { video_src: deleteField() })
  }
}
