import React, { ReactNode } from 'react'
import { Button, ButtonKind, Modal, ModalKind } from '@aposphaere/ui-components'

interface ConfirmModalProps {
  closeModal?: () => void
  closeBtnText?: string
  confirm: () => void
  confirmBtnText?: string
  title: string
  children?: ReactNode
}

const ConfirmModal = ({ closeModal, closeBtnText, children, confirmBtnText, confirm, title }: ConfirmModalProps) => {
  return (
    <div className="absolute max-w-sm z-9999">
      <Modal kind={ModalKind.sm} title={title} onClose={closeModal || undefined}>
        {children ? <div className="mx-3 my-5">{children}</div> : null}
        <div className={`${children ? 'mt-5' : 'mt-15'} my-5 flex justify-between w-full`}>
          {closeModal ? (
            <Button kind={ButtonKind.danger} onClick={closeModal}>
              {closeBtnText || 'Abbrechen'}
            </Button>
          ) : null}
          <div className="ml-auto">
            <Button kind={ButtonKind.secondaryGreen} onClick={confirm}>
              {confirmBtnText || 'Bestätigen'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmModal
