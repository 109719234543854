import { useEffect, useState, useCallback } from 'react'
import { addDoc, collection, onSnapshot, query } from 'firebase/firestore'
import { useHistory } from 'react-router'
import { usePresentation } from '../../lib/contexts/presentationContext'
import { getPresentationsOfAppointment, Presentation } from '../../lib/entities/presentation'
import { STATISTICS_ROUTE } from '../../routes'
import { db } from '../../firebaseConfig'
import { Statistic, statisticCollection, statisticWhere } from '../../lib/entities/statistic'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { useAuth } from '../../lib/contexts/authContext'

export const useGetPresentations = () => {
  const [presentations, setPresentations] = useState<Presentation[]>()
  const currentPresentation = usePresentation()
  const appointmentDocID = currentPresentation.presentation?.appointment_doc_id || ''
  const trainerCode = currentPresentation.presentation?.trainer_code || ''

  const fetchPresentations = useCallback(async () => {
    const response = await getPresentationsOfAppointment(appointmentDocID, trainerCode)
    return response
  }, [appointmentDocID, trainerCode])

  useEffect(() => {
    fetchPresentations().then((response) => {
      const returnedPresentations = response.docs.map((presentation) => {
        return presentation.data()
      })
      setPresentations(returnedPresentations)
    })
  }, [fetchPresentations])

  return presentations
}

export const useAddPresentationStaistics = () => {
  const history = useHistory()
  const callAPI = async (statisticsData: Statistic) => {
    try {
      const docRef = await addDoc(collection(db, 'statistics'), statisticsData) //todo check again to add other data that are needed
      if (docRef.id) {
        history.push(STATISTICS_ROUTE)
      }
    } catch (error) {
      console.error('Statistic Document error: ', error)
    }
  }
  return [callAPI] as const
}

export const useGetPresentationStatistics = (projectName: string) => {
  const [statistics, setStatistics] = useState<Statistic | null>()
  const { pharmacy } = usePharmacy()
  const pharmacyId = pharmacy?.id
  const { user } = useAuth()
  const trainerCode = user?.entry?.trainer_code

  useEffect(() => {
    if (!pharmacyId || !trainerCode) {
      setStatistics(null)
      return
    }

    const statisticsQuery = query(
      statisticCollection,
      statisticWhere('trainer_code', '==', trainerCode),
      statisticWhere('project_name', '==', projectName),
      statisticWhere('pharmacy_id', '==', pharmacyId),
    )
    const unsubscribe = onSnapshot(statisticsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          let statisticsData = change.doc.data() as Statistic
          setStatistics(statisticsData)
        }
      })
    })

    return unsubscribe
  }, [pharmacyId, projectName, trainerCode])

  return [statistics] as const
}
