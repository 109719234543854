import React, { useEffect } from 'react'
import { DownloadIcon, IconButton, IconButtonKind, Modal, ModalKind, SearchIcon } from '@aposphaere/ui-components'
import { useStoreProjects } from '../../hooks/useStoreProjects'
import Spinner from '../Spinner'
import { StoredStatus } from '../../lib/utils/storePresentations/config'

interface StoreProjectsModalProps {
  onClose?: () => void
}

export const StoreProjectsModal = (props: StoreProjectsModalProps) => {
  const { checkProject, isInitialized, checkProjects, projects, status, storeProject, storeProjects } = useStoreProjects()

  // Initialize
  useEffect(() => {
    checkProjects()
  }, [checkProjects])

  const onDownloadAll = async () => {
    const details = await checkProjects()
    storeProjects(details)
  }
  const onDownload = async (projectId: string | number) => {
    const details = await checkProject(projectId)
    if (details) {
      storeProject(details)
    }
  }

  const isAllUpdated = projects.length && projects.every((project) => project.storedState === StoredStatus.UpToDate)

  return (
    <div className="z-9999">
      <Modal
        onBack={() => null}
        title="Aktualisierung der ProjeSettingsIconkte"
        onClose={status === 'idle' ? props?.onClose : undefined}
        kind={ModalKind.ms}
      >
        {isInitialized ? (
          <>
            <div className="flex w-full">
              {isAllUpdated ? null : <p className="text-gray-700 text-lg p-4 w-full ">Bitte warten Sie, bis sie alle aktualisiert wurden.</p>}
              <div className="ml-auto flex">
                <IconButton kind={IconButtonKind.custom} icon={<SearchIcon />} onClick={checkProjects} />
                {status === 'idle' && !isAllUpdated ? (
                  <IconButton kind={IconButtonKind.custom} icon={<DownloadIcon />} onClick={onDownloadAll} />
                ) : null}
              </div>
            </div>
            <ul className="text-black-100 pl-5 w-full">
              {projects.map((details) => {
                const { name, status, storedState, id } = details
                const getStatusColor = () => {
                  if (storedState === StoredStatus.Missing) {
                    return 'bg-red-700'
                  }
                  if (storedState === StoredStatus.Outdated) {
                    return 'bg-yellow-900'
                  }
                  if (storedState === StoredStatus.UpToDate) {
                    return 'bg-green-600'
                  }
                }
                const busyStates: typeof status[] = ['checking', 'deleting-old-version', 'downloading-zip', 'unzipping', 'writing-the-files']
                const isBusy = busyStates.includes(status)
                return (
                  <li className="first:pt-3 mb-5 pb-2 border-b w-full grid grid-cols-2 items-center" key={id}>
                    <span className="text-lg pt-2 text-blue-700 font-bold">{name}</span>

                    <div className="flex items-center">
                      <code className={`inline-block px-4 mr-4 text-white rounded-xl ${getStatusColor()}`}>{storedState}</code>
                      {status !== 'idle' && (
                        <code className="inline-block rounded-xl border border-black-100 mr-auto px-4">
                          {status}
                          {isBusy && <span className="ml-4 inline-block animate-spin">/</span>}
                        </code>
                      )}
                      {status === 'idle' && details.storedState !== StoredStatus.UpToDate ? (
                        <div className="ml-auto">
                          <IconButton kind={IconButtonKind.custom} icon={<DownloadIcon />} onClick={() => onDownload(id)} />
                        </div>
                      ) : null}
                    </div>
                  </li>
                )
              })}
            </ul>
          </>
        ) : (
          <div className="my-10  p-6 bg-white max-w-sm mx-auto rounded-sm">
            <Spinner fixed={false} />
          </div>
        )}
      </Modal>
    </div>
  )
}
