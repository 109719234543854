import React, { useEffect, useState } from 'react'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { AuthProvider, useAuth } from './lib/contexts/authContext'
import MainLayout from './layouts/MainLayout'
import AppProvider from './lib/contexts/appContext'
import { toast } from '@aposphaere/ui-components'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
/* Theme variables */
import './theme/variables.css'
import { ActiveAppointmentProvider, useActiveAppointment } from './lib/contexts/activeAppointmentContext'
import { PharmacyProvider, usePharmacy } from './lib/contexts/pharmacyContext'
import { PresentationProvider, usePresentation } from './lib/contexts/presentationContext'
import { AppRoutes } from './pages'
import DevTools from './components/DevTools'
import { NetworkStatusProvider } from './lib/contexts/networkStatusContext'
import Spinner from './components/Spinner'
import { useStoreProjects } from './hooks/useStoreProjects'
import { StoredStatus } from './lib/utils/storePresentations/config'
import { StoreProjectsModal } from './components/StoreProjectsButton/Modal'
import { useIsMobile } from './hooks/useIsMobile'

setupIonicReact()

const App = withProviders(() => {
  const { activeAppointment } = useActiveAppointment()
  const { setPharmacyId } = usePharmacy()
  const { setPresentationId } = usePresentation()
  const { loading: authLoading } = useAuth()
  const { projects, checkProjects } = useStoreProjects()
  const [projectsModalOpen, setProjectsModalOpen] = useState(false)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile) {
      checkProjects()
    }
  }, [checkProjects, isMobile])

  const hasOutdatedProjects = Boolean(projects.length && projects.some((project) => project.storedState !== StoredStatus.UpToDate))

  useEffect(() => {
    if (hasOutdatedProjects && isMobile) {
      toast.show({
        headline: 'Sie haben veraltete oder fehlende Projekte.',
        button: { onClick: () => setProjectsModalOpen(true), text: 'Projekte Verwalten' },
        duration: 13000,
      })
    }
  }, [hasOutdatedProjects, isMobile])

  // Keep the Presentation data in sync
  useEffect(() => {
    if (activeAppointment?.presentation_id) {
      setPresentationId(activeAppointment?.presentation_id)
    }
  }, [activeAppointment?.presentation_id, setPresentationId])

  // Keep the Pharmacy data in sync
  useEffect(() => {
    if (activeAppointment?.pharmacy_id) {
      setPharmacyId(activeAppointment?.pharmacy_id || null)
    }
  }, [activeAppointment?.pharmacy_id, setPharmacyId])

  if (authLoading.initialSignin) {
    return <Spinner />
  }

  return (
    <>
      <DevTools />
      {projectsModalOpen ? <StoreProjectsModal onClose={() => setProjectsModalOpen(false)} /> : null}
      <MainLayout>
        <AppRoutes />
      </MainLayout>
    </>
  )
})

function withProviders(Component: React.FC) {
  const AppProviders = () => (
    <AuthProvider>
      <NetworkStatusProvider>
        <AppProvider>
          <ActiveAppointmentProvider>
            <PharmacyProvider>
              <PresentationProvider>
                <IonApp>
                  <IonReactRouter>
                    <IonRouterOutlet>
                      <Component />
                    </IonRouterOutlet>
                  </IonReactRouter>
                </IonApp>
              </PresentationProvider>
            </PharmacyProvider>
          </ActiveAppointmentProvider>
        </AppProvider>
      </NetworkStatusProvider>
    </AuthProvider>
  )
  return AppProviders
}

export default App
