import React from 'react'
import ClientBackground from '../ClientBackground'
import PrevNextButtons from '../PrevNextButtons'

interface ChangeDeviceUserPromtProps {
  title: string
  description?: string
  prevButtonName?: string
  nextButtonName?: string
  handleBack: () => void
  handleNext: () => void
}

const ChangeDeviceUserPrompt = ({
  prevButtonName = 'Zurück',
  nextButtonName = 'Weiter',
  handleBack,
  handleNext,
  title,
  description,
}: ChangeDeviceUserPromtProps) => {
  return (
    <ClientBackground>
      <div className="w-6/12 max-w-2xl mb-9">
        <h1 className="text-blue-600 text-2lg font-medium text-center w-full mb-10">{title}</h1>
        {description ? <p className="text-blue-600 text-lg font-medium text-center w-full mb-20">{description}</p> : null}
        <img src="/change-users-ipad.png" alt="" className="px-20" />
      </div>
      <PrevNextButtons prevButton={{ text: prevButtonName, onClick: handleBack }} nextButton={{ text: nextButtonName, onClick: handleNext }} />
    </ClientBackground>
  )
}

export default ChangeDeviceUserPrompt
