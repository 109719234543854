import React, { createContext, useCallback, useMemo } from 'react'
import { doc, DocumentSnapshot } from 'firebase/firestore'
import { Dispatch, ReactNode, useContext, useState } from 'react'
import { Presentation, presentationCollection } from '../entities/presentation'
import { useDocumentSubscription } from '../../hooks/useDocumentSubscription'
import { SubscriptionStatus } from '../models/subscriptionState'

/*
 * CONTEXT
 */
interface PresentationContext {
  presentationSnapshot: DocumentSnapshot<Presentation> | null
  presentation: Presentation | null
  isLoading: boolean
  reset: () => void
  setPresentationId: Dispatch<React.SetStateAction<string | null>>
  status: SubscriptionStatus
}
const Context = createContext<PresentationContext | null>(null)
Context.displayName = 'PresentationContext'

/*
 * PROVIDER
 */
export const PresentationProvider = ({ children }: { children: ReactNode }) => {
  const [presentationId, setPresentationId] = useState<string | null>(null)
  const presentationDocRef = useMemo(() => (presentationId ? doc(presentationCollection, presentationId) : null), [presentationId])
  const { data: presentationSnapshot, isLoading, status } = useDocumentSubscription(presentationDocRef)

  const reset = useCallback(() => {
    setPresentationId(null)
  }, [])

  return (
    <Context.Provider
      value={{
        presentation: presentationSnapshot?.data() || null,
        presentationSnapshot,
        setPresentationId,
        reset,
        isLoading: isLoading,
        status,
      }}
    >
      {children}
    </Context.Provider>
  )
}

/*
 * HOOKS
 */
export const usePresentation = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("usePresentation can't be used without it's Provider")
  }
  return context
}
