import { getDocs, query } from 'firebase/firestore'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'
import { createOrderItem, orderItemCollection, orderItemWhere } from './orderItems'
export interface Project {
  booked_time_in_seconds: string
  min_time_in_seconds: string
  client_id: number
  contact_goal: number
  end_date: Date
  id: number | string
  legacy_project_id: null | number
  name: string
  price: number
  price_multiple_in_cents: string
  start_date: string
  state: string
  status: null | string
  presentation_preview_url: string | null
  presentation_url: string | null
  zipped_presentation_url: string | null
  zip_checksum: string | null
  active?: boolean
}

const project = initFirebaseEntity<Project>('projects')

export const projectCollection = project.collection
export const projectsWhere = project.where
export const getAllProjects = project.getAll
export const getProject = project.get
export const getFirstProject = project.getFirst
export const getProjects = project.getMany

export const getProjectWhereProjectId = async (projectId: number | string) => {
  const projects = await getDocs(query(projectCollection, projectsWhere('id', '==', projectId)))
  const project = projects?.docs?.[0]?.data?.()
  return project
}

export const linkProjectToAppointmentIfNecessary = async (projectId: number, appointmentDocId: string) => {
  const foundOrderItemsSnapshot = await getDocs(
    query(orderItemCollection, orderItemWhere('project_id', '==', projectId), orderItemWhere('appointment_doc_id', '==', appointmentDocId)),
  )
  const foundOrderItem = foundOrderItemsSnapshot?.docs?.[0]

  if (foundOrderItem) {
    return foundOrderItem
  }
  if (!foundOrderItem) {
    const res = await createOrderItem({ amount: 1, appointment_doc_id: appointmentDocId, project_id: projectId })
    return res
  }
}

export const getProjectsOfAppointment = async (appointmentDocId: string) => {
  const orderItemsSnapshot = await getDocs(query(orderItemCollection, orderItemWhere('appointment_doc_id', '==', appointmentDocId)))

  const uniqueProjectIds = Array.from(new Set(orderItemsSnapshot?.docs?.map((doc) => doc.data().project_id)))
  const projectsSnapshot = await getDocs(query(projectCollection, projectsWhere('id', 'in', uniqueProjectIds)))

  return projectsSnapshot.docs
}
