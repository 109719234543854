import { Pharmacy } from '@aposphaere/core-kit'
import { Button, ButtonKind, IconButton, IconButtonKind, PlusSignIcon } from '@aposphaere/ui-components'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import React, { useState } from 'react'
import { useAuth } from '../../lib/contexts/authContext'
import { Appointment, appointmentCollection, AppointmentStatus } from '../../lib/entities/appointment'
import { getAllQuarters } from '../../lib/entities/quarters'
import AppointmentModal from '../AppointmentModal'
import ProjectsModal from './ProjectsModal'

export type CustomerCardProps = {
  customer: Pharmacy | undefined
  index: number
}

const CustomerCard: React.FunctionComponent<CustomerCardProps> = (props) => {
  const [showAppointmentModal, setShowAppointmentModal] = useState<boolean>(false)
  const [showProjectsModal, setShowProjectsModal] = useState<boolean>(false)
  const [creatingAppointment, setCreatingAppointment] = useState<boolean>(false)
  const [appointmentModalMsg, setAppointmentModalMsg] = useState<string>('')
  const auth = useAuth()

  const chooseAppointmentProject = () => {
    setShowProjectsModal(true)
  }

  const getQuarters = async () => {
    const docsResponse = await getAllQuarters()
    return docsResponse
  }

  const addApointment = async (projectId: string) => {
    setCreatingAppointment(true)
    if (!auth.user || !props.customer?.id || !projectId) {
      return
    }

    const quartersDocResponse = await getQuarters()
    let appointmentCreated = false //used to avoid creation of dublicated appointments

    quartersDocResponse?.docs.map(async (document) => {
      let quarterData = document.data()

      const currentDateIncluded = checkIfCurrentDateIncludedInAnyQuarter(quarterData?.from || '', quarterData?.to || '')

      if (currentDateIncluded && !appointmentCreated) {
        try {
          appointmentCreated = true

          const appointmentDoc = doc(appointmentCollection)
          const appointmentData: Appointment = {
            pharmacy_id: Number(props.customer?.id),
            quarter_id: quarterData.id,
            uuid: appointmentDoc.id,
            status_id: AppointmentStatus.Active,
            appointment_type_id: 1,
            user_ids: [Number(auth.user?.entry?.id)],
            project_id: Number(projectId),
            creator_id: Number(auth.user?.entry?.id),
            date: Timestamp.fromDate(new Date()),
            send_confirmation: false,
          }
          setDoc(appointmentDoc, appointmentData)
          showAppointmentCreationErrorMessage(false)
        } catch (error) {
          showAppointmentCreationErrorMessage(true)
        } finally {
          setCreatingAppointment(false)
        }
      }
    })
  }

  const showAppointmentCreationErrorMessage = (error: boolean) => {
    const today = new Date()
    setAppointmentModalMsg(
      error
        ? 'Leider konnte der Termin nicht angelegt werden, Termine können nur zwischen 8:00 und 20:00 Uhr erstellt werden.'
        : `Der Termin für die Apotheke ${props.customer?.name} um ${today.getHours() + ':' + today.getMinutes()} wurde erfolgreich angelegt .`,
    )
    setShowProjectsModal(false)
    setShowAppointmentModal(true)
  }

  const checkIfCurrentDateIncludedInAnyQuarter = (from: string, to: string) => {
    if (!from || !to) return false
    const fromDate = new Date(from)
    const toDate = new Date(to)
    const currentDate = new Date()
    if (from !== '' && to !== '' && currentDate.getTime() >= fromDate.getTime() && currentDate.getTime() <= toDate.getTime()) {
      return true
    }
    return false
  }

  return (
    <>
      {showAppointmentModal && <AppointmentModal closeModal={() => setShowAppointmentModal(false)} message={appointmentModalMsg} />}
      {showProjectsModal && (
        <ProjectsModal
          closeModal={() => setShowProjectsModal(false)}
          creatingAppointment={creatingAppointment}
          addApointment={(e) => addApointment(e)}
        />
      )}
      <div
        className="flex flex-row justify-between w-full p-5 bg-white overflow-hidden rounded-md border border-solid border-gray-400 mb-4 font-body text-blue-700"
        data-testid="customer-card"
      >
        <div className="flex flex-col justify-between font-medium text-lg w-80">
          <p data-testid="pharmacy-name">{props.customer?.name || ''}</p>
          <p data-testid="pharmacy-address" className="font-normal">
            {props.customer?.address?.address_name + ', ' + props.customer?.address?.city || ''}
          </p>
        </div>
        <div className="flex flex-col justify-between font-medium text-lg mx-5">
          {props.customer?.id && <span data-testid="pharmacy-id">PIN {props.customer?.id}</span>}
          {props.customer?.phone && <span data-testid="pharmacy-phone">Tel.: {props.customer?.phone || ''}</span>}
        </div>
        <div className="bg-white py-2 flex justify-between items-center">
          <Button
            kind={ButtonKind.outlinedSecondary}
            onClick={() => {
              //todo
            }}
          >
            {'APOSurvey'}
          </Button>
          <div data-testid={`add-button-${props.index}`}>
            <IconButton
              kind={IconButtonKind.custom}
              icon={<PlusSignIcon />}
              additionalCss="h-fit items-center px-0.5 py-0.5 ml-3 rounded-md text-gray-700 hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150 border border-solid border-gray-400"
              onClick={() => chooseAppointmentProject()}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerCard
