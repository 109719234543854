import React, { useEffect, useMemo, useState } from 'react'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, Modal, ModalKind, TextInput } from '@aposphaere/ui-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { usePharmacyEmployeePositions } from '../../hooks/entities/usePharmacies'

import { Employee, Pharmacy } from '@aposphaere/core-kit/build/models'
import { createRegisterFunction } from '../../lib/utils/formHelpers'
import { deleteEmployee, deleteIpadUser, updateEmployee } from '../../lib/entities/pta'
import { useAsync } from '../../hooks/useAsync'
import { getPharmacy } from '../../lib/entities/pharmacy'
import { DocumentSnapshot } from 'firebase/firestore'
import ConfirmModal from '../ConfirmModal'

type EditUserFormValues = {
  firstName: string
  lastName: string
  email: string
  gender: string
  employeePositionId: string
}

interface EditEmployeeModalProps {
  employeeId: string
  employee: Employee
  onClose: () => void
  onEdit?: (values: EditUserFormValues) => void
}

export const EditEmployeeModal = ({ employee, employeeId, onClose, onEdit }: EditEmployeeModalProps) => {
  const { employeePositions, loadingEmployeePositions } = usePharmacyEmployeePositions()
  const { run, data: pharmacyOfUser, isLoading: pharmacyLoading } = useAsync<DocumentSnapshot<Pharmacy> | null>(null)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const isDataLoading = loadingEmployeePositions || pharmacyLoading
  const pharmacyEmail = pharmacyOfUser?.data()?.email?.toLowerCase()

  const EditUserValidationScheme: Yup.SchemaOf<EditUserFormValues> = useMemo(() => {
    return Yup.object().shape({
      firstName: Yup.string().trim().min(2, 'Zu kurz').required('Feld ist notwendig!'),
      lastName: Yup.string().trim().min(2, 'Zu kurz').required('Feld ist notwendig!'),
      email: Yup.string()
        .email('Ungültige E-Mail')
        .required('Feld ist notwendig!')
        .test('is-pharmacy-generic-email', 'Bitte verwenden Sie eine personalisierte Emailadresse wie Vorname.Nachname@apotheke.de', (email) => {
          return Boolean(email && email?.toLowerCase() !== pharmacyEmail)
        }),
      gender: Yup.string().required('Feld ist notwendig!'),
      employeePositionId: Yup.string().trim().required('Feld ist notwendig!'),
    })
  }, [pharmacyEmail])

  useEffect(() => {
    run(getPharmacy(String(employee.pharmacy_id)))
  }, [run, employee.pharmacy_id])

  const initialFormValues: EditUserFormValues = {
    firstName: employee.first_name,
    lastName: employee.last_name,
    email: employee.email,
    gender: employee.gender,
    employeePositionId: employee.employee_position_id,
  }

  const onFormSubmit = async (values: EditUserFormValues) => {
    const userData: Partial<Employee> = {
      email: values.email,
      employee_position_id: values.employeePositionId,
      first_name: values.firstName,
      gender: values.gender,
      is_anonymous_user: false,
      id: employeeId,
      last_name: values.lastName,
    }
    await updateEmployee(employeeId, userData)
    onEdit?.(values)
    onClose()
  }

  const { errors, touched, handleSubmit, isValid, handleChange, handleBlur, values } = useFormik({
    initialValues: initialFormValues,
    onSubmit: onFormSubmit,
    validationSchema: EditUserValidationScheme,
  })
  const register = createRegisterFunction({ values, handleChange, handleBlur })

  const isFormTouched = Boolean(Object.keys(touched).length)

  const deleteUser = async () => {
    if (!employeeId) {
      return
    }
    await Promise.all([deleteEmployee(employeeId), deleteIpadUser(employeeId)])
    setShowConfirmModal(false)
    onClose()
  }

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          title={`Sind Sie sicher, dass Sie PTA "${employee?.first_name + ' ' + employee?.last_name}" löschen möchten?`}
          closeModal={() => {
            setShowConfirmModal(false)
          }}
          confirm={deleteUser}
        />
      )}
      <Modal
        kind={ModalKind.ms}
        noPadding
        title={`Benutzer bearbeiten: ${employee.first_name || ''} ${employee.last_name || ''}`}
        onClose={onClose}
        onBack={() => null}
      >
        <div className="flex flex-row w-full h-full">
          <div className="flex flex-col w-1/3 h-auto bg-gray-100 border-r border-gray-400"></div>
          <div className="w-2/3">
            <form onSubmit={handleSubmit} className={`flex flex-wrap w-full rounded-lg h-fit justify-center py-5 px-4`}>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="employeePositionId">
                  <span className="text-gray-900 mb-2.5">{'FUNKTION:'}</span>
                </InputLabel>
                <div className="w-full rounded-md">
                  <select
                    {...register('employeePositionId')}
                    className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                  >
                    {employeePositions?.length &&
                      employeePositions?.map(({ id, name }: { id: number; name: string }) => {
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="gender">
                  <span className="text-gray-900 mb-2.5">{'ANREDE:'}</span>
                </InputLabel>
                <div className="w-full rounded-md">
                  <select
                    {...register('gender')}
                    className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                  >
                    <option value="male">Herr</option>
                    <option value="female">Frau</option>
                    <option value="other">Divers</option>
                  </select>
                </div>
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="firstName">
                  <span className="text-gray-900 mb-2.5">{'VORNAME:'}</span>
                </InputLabel>
                <TextInput {...register('firstName')} bgColor="bg-white" type="text" />
                {errors.firstName && touched.firstName ? <InputMessage kind={InputMessageKind.error}>{errors.firstName}</InputMessage> : null}
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="lastName">
                  <span className="text-gray-900 mb-2.5">{'NACHNAME:'}</span>
                </InputLabel>
                <TextInput {...register('lastName')} bgColor="bg-white" type="text" />
                {errors.lastName && touched.lastName ? <InputMessage kind={InputMessageKind.error}>{errors.lastName}</InputMessage> : null}
              </div>
              <div className="w-full py-2 px-4">
                <InputLabel htmlFor="pharmacyId">
                  <span className="text-gray-900 mb-2.5 opacity-50">{'APOTHEKE:'}</span>
                </InputLabel>
                <TextInput id="pharmacyName" name="pharmacyName" bgColor="bg-white" disabled type="text" value={pharmacyOfUser?.data()?.name || ''} />
              </div>
              <div className="w-full py-2 px-4">
                <InputLabel htmlFor="email">
                  <span className="text-gray-900 mb-2.5">{'E-MAIL:'}</span>
                </InputLabel>
                <TextInput {...register('email')} bgColor="bg-white" type="email" />
                {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
              </div>
              <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-end gap-6 p-4 pt-6 pb-4 place-items-stretch">
                <Button
                  kind={ButtonKind.outlinedDanger}
                  onClick={() => {
                    setShowConfirmModal(true)
                  }}
                >
                  {'Person löschen'}
                </Button>
                <Button type="submit" kind={ButtonKind.primary} disabled={!isFormTouched || !isValid || isDataLoading} onClick={handleSubmit}>
                  {'Änderungen speichern'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}
