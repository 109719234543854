// Shared
export const ENTRY_SCREEN_ROUTE = '/entry'
export const MULTI_TAB_FEEDBACK_ROUTE = '/multi-tab-feedback'
export const END_SCREEN_ROUTE = `/end-screen`

//Master
const MASTER_ROOT = '/master'
export const MASTER_ROOT_PAGE_ROUTE = MASTER_ROOT

export const MASTER_PRESENTATION_ROUTE = `${MASTER_ROOT}/presentation`
export const LOGIN_ROUTE = `${MASTER_ROOT}/login`
export const CUSTOMERS_ROUTE = `${MASTER_ROOT}/customers`
export const ATTENDEES_ROUTE = `${MASTER_ROOT}/attendees`
export const MASTER_TRAININGS_ROUTE = `${MASTER_ROOT}/trainings`
export const STATISTICS_ROUTE = `${MASTER_ROOT}/statistics`
export const QUESTIONNAIRE_PARTICIPANTS_ROUTE = `${MASTER_ROOT}/questionnaire-participants`

//Client
const CLIENT_ROOT = '/client'
export const CLIENT_ROOT_ROUTE = CLIENT_ROOT

export const CLIENT_LOGIN_ROUTE = `${CLIENT_ROOT}/login`
export const CLIENT_PRESENTATION_ROUTE = `${CLIENT_ROOT}/presentation`
export const CLIENT_TRAININGS_ROUTE = `${CLIENT_ROOT}/trainings`
export const HOME_PAGE_ROUTE = `${CLIENT_ROOT}/home`
export const PRIVACY_POLICY_ROUTE = `${CLIENT_ROOT}/privacy-policy`
export const WAITING_ROUTE = `${CLIENT_ROOT}/waiting`
export const QUESTIONNAIRE_ROUTE = `${CLIENT_ROOT}/questionnaire`

// Client/User Setup
const USER_SETUP_ROOT = `${CLIENT_ROOT}/user-setup`
export const USER_SETUP_ROOT_ROUTE = USER_SETUP_ROOT

export const USERS_NUMBER_ROUTE = `${USER_SETUP_ROOT}/users-number`
export const SETUP_USERS_ROUTE = `${USER_SETUP_ROOT}/setup-users`
export const ADD_USER_ROUTE = `${USER_SETUP_ROOT}/add-user`
export const ACCEPT_PRIVACY_POLICY_ROUTE = `${USER_SETUP_ROOT}/accept-privacy-policy`
export const USERS_IPAD_CHANGE_ROUTE = `${USER_SETUP_ROOT}/users-ipad-change`
