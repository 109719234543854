import { IpadEmployee } from '@aposphaere/core-kit/build/models'
import { Ipad } from '@aposphaere/core-kit/build/models/iPad'
import React, { useState } from 'react'
import { useMemo } from 'react'
import { deleteIpadUser } from '../../lib/entities/pta'
import { ANONYMOUS_USER } from '../../lib/entities/user'
import ConfirmModal from '../ConfirmModal'
import AttendeesCardItem from './CardItem'

export type AttendeesCardProps = {
  ipad: Ipad
  isHighlighted?: boolean
  titleColor?: 'gray' | 'blue'
  attendeeTextColor?: 'light' | 'dark'
  isAttendeeHighlighted?: (employee: IpadEmployee) => boolean
  onEdit: (user: IpadEmployee) => void
}

const ICONS = {
  IPAD_HIGHLIGHTED: '/assets/icons/ipad-icon-green.png',
  IPAD_ACTIVE: '/assets/icons/ipad-icon-active.png',
  IPAD_INACTIVE: '/assets/icons/ipad-icon-inactive.png',
}

const IpadAttendeesCard: React.FunctionComponent<AttendeesCardProps> = ({
  ipad,
  isAttendeeHighlighted,
  attendeeTextColor = 'light',
  isHighlighted,
  titleColor,
  onEdit,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [userId, setUserId] = useState<string>('')
  const deviceId = ipad?.device_id
  const selectedUser = useMemo(() => ipad?.users?.find((user) => user.id === userId), [userId])

  let textColorClass = titleColor === 'blue' ? 'text-blue-600' : 'text-gray-400'
  if (isHighlighted) {
    textColorClass = 'text-green-550'
  }

  // Icon
  // CardItemProps

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          title={`Sind Sie sicher, dass Sie PTA ${selectedUser?.first_name + ' ' + selectedUser?.last_name} löschen möchten?`}
          closeModal={() => {
            setShowConfirmModal(false)
          }}
          confirm={() => {
            deleteIpadUser(userId, deviceId)
            setShowConfirmModal(false)
          }}
        />
      )}
      <div className="w-1/2 m-0 float-left mt-7 pr-7 flex">
        <div
          style={{ minHeight: '14.5rem' }}
          className={`${isHighlighted ? 'border-green-500' : 'border-gray-400'} w-full border-2 border-gray-400 bg-white rounded-md`}
        >
          <div className={`${isHighlighted ? 'border-green-500 bg-green-60' : 'border-gray-400'} flex justify-between border-b-2 px-4 py-2.5`}>
            <h2 className={`${textColorClass} text-lg font-medium m-0 pt-1.5`}>{ipad.device_name || ipad.device_id}</h2>
            <img
              src={`${ipad?.users?.length ? (isHighlighted ? ICONS.IPAD_HIGHLIGHTED : ICONS.IPAD_ACTIVE) : ICONS.IPAD_INACTIVE}`}
              alt=""
              className="h-7 w-auto"
            />
          </div>
          {ipad?.users?.length > 0 ? (
            ipad?.users?.map((item, index) => {
              return (
                <AttendeesCardItem
                  key={index}
                  text={item.is_anonymous_user ? ANONYMOUS_USER.NAME : item.first_name + ' ' + item.last_name}
                  textColor={isAttendeeHighlighted?.(item) ? 'highlighted' : attendeeTextColor}
                  onEdit={!item.is_anonymous_user && item.id ? () => onEdit(item) : undefined}
                  onDelete={
                    !item.is_anonymous_user && item.id
                      ? () => {
                          setShowConfirmModal(true)
                          setUserId(item.id)
                        }
                      : undefined
                  }
                  bottomSeparator={index !== 2}
                />
              )
            })
          ) : (
            <div className="h-44 flex justify-center items-center">
              <p className="text-gray-400 focus:text-lg font-medium m-0 p-0 -mt-2.5 text-center">Keine Nutzer</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default IpadAttendeesCard
