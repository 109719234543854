import { ButtonKind } from '@aposphaere/ui-components'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import PrevNextButtons from '../../components/PrevNextButtons'
import PageTitleSection from '../../components/PageTitleSection'
import Spinner from '../../components/Spinner'
import TrainingCard from '../../components/TrainingCard'
import { ATTENDEES_ROUTE, MASTER_PRESENTATION_ROUTE } from '../../routes'
import { useGetAllProjects, usePharmacyProjects } from '../../hooks'
import { PresentationStatus } from '../../lib/constants'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { endPresentationsForTrainerId, Presentation, presentationCollection } from '../../lib/entities/presentation'
import { useCurrentIpads } from '../../lib/contexts/ipadsContext'
import { removeQuestionnaireCompletedStatesFromDevice } from '../../hooks/entities/useIpads'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { linkProjectToAppointmentIfNecessary, Project } from '../../lib/entities/project'
import { hasOwnProperty } from '../../lib/utils/typeUtils'
import { Statistic, statisticCollection } from '../../lib/entities/statistic'
import { useAuth } from '../../lib/contexts/authContext'
import { doc, setDoc } from 'firebase/firestore'
import { updateActiveAppointment } from '../../lib/entities/activeAppointment'

const Trainings: React.FC = () => {
  const { pharmacy } = usePharmacy()

  const auth = useAuth()
  const history = useHistory()
  const { state: locationState } = useLocation()
  const { activeAppointment, activeAppointmentSnapshot } = useActiveAppointment()
  const [selectedCard, setSelectedCard] = useState<Project | null>(null)
  const { ipads } = useCurrentIpads()
  const { pharmacyProjects, loadingPharamcyProjects } = usePharmacyProjects()
  const { allProjects, loadingProjects } = useGetAllProjects()
  const [trainings, setTrainings] = useState<Project[]>()

  const loading = loadingPharamcyProjects || loadingProjects

  const ipadDeviceIds = ipads.map((ipad) => ipad.device_id)

  // Initialize
  useEffect(() => {
    const initialize = async () => {
      if (auth.user?.entry?.trainer_code) {
        await endPresentationsForTrainerId(auth.user.entry.trainer_code)
      }
    }
    initialize()
  }, [auth?.user?.entry?.trainer_code])

  useEffect(() => {
    const pharmacyActiveProjectsId = pharmacyProjects?.map((project) => project?.id) || []
    const trainingsData: Project[] = []
    allProjects?.forEach((project) => {
      if (pharmacyActiveProjectsId.includes(project.id)) {
        trainingsData.push({ ...project, active: true })
      } else {
        trainingsData.push({ ...project, active: false })
      }
    })
    setTrainings(trainingsData)
  }, [allProjects, pharmacyProjects])

  const goBack = () => {
    let backDestination = ATTENDEES_ROUTE
    if (locationState && typeof locationState === 'object' && hasOwnProperty(locationState, 'from')) {
      if (typeof locationState.from === 'string') {
        backDestination = locationState.from
      }
    }
    history.push(backDestination)
  }

  const addStatisticsData = (presentationId: string) => {
    if (!selectedCard || !pharmacy || !auth?.user?.entry?.trainer_code || !auth?.user?.entry?.id || !activeAppointment?.appointment_doc_id) {
      console.error('Missing data for adding statistics')
      return
    }

    const participants = ipads?.reduce((internalUserCount, ipad) => internalUserCount + ipad?.users?.length, 0)
    const deviceIds = ipads?.reduce((clientDeviceIds: string[], ipad) => [...clientDeviceIds, ipad.device_id], [])
    const currentDate = new Date()
    let startTime = currentDate.getTime()
    const statisticDoc = doc(statisticCollection)
    const statisticData: Statistic = {
      start_time: startTime,
      end_time: 0,
      uuid: statisticDoc.id,
      date: currentDate + '',
      pharmacy_id: pharmacy.id,
      project_id: Number(selectedCard.id),
      project_name: selectedCard.name,
      presentation_id: presentationId,
      appointment_doc_id: activeAppointment?.appointment_doc_id,
      trainer_code: auth?.user?.entry?.trainer_code,
      participants: participants,
      device_ids: deviceIds,
    }
    setDoc(statisticDoc, statisticData)

    return statisticDoc
  }

  const addPresentationData = () => {
    if (!selectedCard || !pharmacy || !auth?.user?.entry || !activeAppointment?.appointment_doc_id) {
      console.error('Missing data for adding a presentation')
      return
    }
    const presentationDoc = doc(presentationCollection)

    const presentationData: Presentation = {
      current_slide: 'slide_0',
      name: selectedCard.name,
      pharmacy_id: pharmacy.id,
      status: PresentationStatus.Active,
      trainer_id: auth?.user?.entry?.id,
      trainer_code: auth?.user?.entry?.trainer_code,
      appointment_doc_id: activeAppointment?.appointment_doc_id,
      slot_id: 1, // todo get this value from context,
      connectedIpads: ipads || [],
      project_id: Number(selectedCard.id),
    }
    setDoc(presentationDoc, presentationData)

    return presentationDoc
  }

  const next = async () => {
    if (!selectedCard || !activeAppointment?.appointment_doc_id) {
      console.error('Missing data')
      return
    }

    // Clear the questionnaire states
    const clearQuestionnaireStatePromises = ipadDeviceIds.map((deviceId) => removeQuestionnaireCompletedStatesFromDevice(deviceId))
    Promise.all(clearQuestionnaireStatePromises)
    // Add order_items if needed
    linkProjectToAppointmentIfNecessary(Number(selectedCard.id), activeAppointment.appointment_doc_id)
    const presentationDoc = addPresentationData()
    if (!presentationDoc || !activeAppointmentSnapshot?.id) {
      return
    }

    // Create a statistic entry
    const statisticDoc = addStatisticsData(presentationDoc.id)
    if (!statisticDoc) {
      return
    }

    // link the presentation and statistics to the active appointment
    updateActiveAppointment(activeAppointmentSnapshot?.id, {
      ...activeAppointmentSnapshot.data(),
      presentation_id: presentationDoc.id,
      statistics_id: statisticDoc.id,
    })
    history.push({ pathname: MASTER_PRESENTATION_ROUTE, state: { statisticDocId: statisticDoc?.id } })
  }

  const chooseTraining = (item: Project) => {
    setSelectedCard(item)
  }

  return (
    <>
      {loading && <Spinner />}
      <div className="w-full min-h-screen">
        <PageTitleSection pageTitle="Schulung wählen" label={pharmacy?.name ? `${pharmacy.name} | Slot 1` : ''} iconType="home" />
        <div className="p-4 float-left pb-28 w-full">
          {trainings?.length
            ? trainings.map((training, index) => {
                return (
                  <TrainingCard
                    key={training.id}
                    index={index}
                    project={training}
                    selectedCard={selectedCard?.id ? Number(selectedCard?.id) : null}
                    onClick={() => chooseTraining(training)}
                  />
                )
              })
            : null}
        </div>
        <PrevNextButtons
          customStyle="absolute bg-white flex w-full justify-between align-end px-7 py-4 bottom-0 shadow-top"
          prevButton={{ onClick: goBack, kind: ButtonKind.secondary, text: 'Zurück' }}
          nextButton={{ onClick: next, kind: ButtonKind.secondaryGreen, text: 'Starten', disabled: !selectedCard }}
        />
      </div>
    </>
  )
}

export default Trainings
