import { initFirebaseEntity } from '../utils/initFirebaseEntity'

export interface FirebaseUser {
  email: string
  id: number
  name: string
  trainer_code: string
}

const { collection, ...user } = initFirebaseEntity<FirebaseUser>('users')

export const ANONYMOUS_USER = {
  NAME: 'Anonym',
  EMAIL: 'anonym@example.com',
} as const

export const userCollection = collection
export const getUser = user.get
export const getFirstUser = user.getFirst
export const getAllUsers = user.getAll
