import React from 'react'
import { IconButton, IconButtonKind, SettingsIcon } from '@aposphaere/ui-components'

interface SettingsButtonProps {
  onClick?: () => void
}

const SettingsButton = ({onClick}: SettingsButtonProps) => {
  
  return (
    <IconButton
      kind={IconButtonKind.custom}
      icon={<SettingsIcon />}
      additionalCss="mr-2 px-2 py-2 active:text-white hover:text-white focus:outline-none hover:bg-blue-700 rounded active:bg-blue-800 transition ease-in-out duration-150"
      onClick={onClick}
    />
  )
}

export default SettingsButton
