import { FirestoreError } from 'firebase/firestore'

export enum SubscriptionStatus {
  Idle = 'idle',
  Pending = 'pending',
  Resolved = 'resolved',
  Error = 'error',
}

export type SubscriptionState<T> =
  | {
      status: SubscriptionStatus.Idle
    }
  | {
      status: SubscriptionStatus.Pending
    }
  | {
      status: SubscriptionStatus.Resolved
      data: T
    }
  | {
      status: SubscriptionStatus.Error
      error: FirestoreError
    }
