import { Employee, Ipad } from '@aposphaere/core-kit/build/models'
import { ButtonKind } from '@aposphaere/ui-components'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import AttendeesCardItem from '../../components/AttendesCard/CardItem'
import IpadAttendeesCard from '../../components/AttendesCard'
import PageTitleSection from '../../components/PageTitleSection'
import { CUSTOMERS_ROUTE, MASTER_TRAININGS_ROUTE } from '../../routes'
import { EditIpadUsersModal } from '../../components/EditIpadUsersModal'
import PrevNextButtons from '../../components/PrevNextButtons'
import Spinner from '../../components/Spinner'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { useCurrentIpads } from '../../lib/contexts/ipadsContext'
import { ANONYMOUS_USER } from '../../lib/entities/user'
import { EditEmployeeModal } from '../../components/EditEmployeeModal'
import { deleteEmployee, deleteIpadUser } from '../../lib/entities/pta'
import ConfirmModal from '../../components/ConfirmModal'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { useCurrentAndRelatedPharmacyEmployees } from '../../hooks/entities/usePharmacies'

type UserEditModalState = { isOpen: false } | { isOpen: true; employeeId: string; employee: Employee; ipadDeviceId?: string }

const Attendees: React.FC = () => {
  const { pharmacy } = usePharmacy()
  const history = useHistory()
  const { ipads, isLoading: loading } = useCurrentIpads()
  const { allEmployees } = useCurrentAndRelatedPharmacyEmployees(pharmacy?.id || null)
  const [showParticipants, setShowParticipants] = useState<boolean>(false)
  const { removeActiveAppointment } = useActiveAppointment()
  const { isOnline } = useNetworkMode()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>()
  const [employeeId, setEmployeeId] = useState('')

  const [userEditModal, setUserEditModal] = useState<UserEditModalState>({
    isOpen: false,
  })

  const handleBackClick = () => {
    removeActiveAppointment()
    history.push(CUSTOMERS_ROUTE)
  }

  const next = () => {
    history.push({
      pathname: MASTER_TRAININGS_ROUTE,
      state: { showProjects: true },
    })
  }

  let message = ''
  if (!ipads?.length) {
    message = 'Es ist noch kein iPad verknüpft.'
  }

  if (!isOnline) {
    message = 'Sie sind offline'
  }

  return (
    <>
      <div className="w-full">
        <PageTitleSection pageTitle="Einrichtung der Teilnehmer" label={pharmacy?.name ? `${pharmacy.name} | Slot 1` : ''} iconType="home" />
        <div className="sticky float-right z-30">
          <button
            className={`${
              showParticipants ? 'border-0 mt-2 mr-4' : 'border-2 mt-4 mr-6'
            } border-blue-600 border-solid rounded-md py-1.5 px-2 z-10 focus:outline-none`}
            onClick={() => setShowParticipants(!showParticipants)}
          >
            <img src="/assets/icons/user-icon.png" alt="" className="h-6 w-auto" />
          </button>
        </div>
        {loading && (
          <div className="mt-8 border p-6 bg-white max-w-sm mx-auto border-gray-400 rounded-sm">
            <Spinner fixed={false} />
          </div>
        )}
        <div className={`${showParticipants ? 'max-w-full' : 'max-w-screen-md mx-auto'} ease-in-out duration-300`}>
          <div className={`${showParticipants ? 'w-2/3 pl-7' : 'w-full'} flex flex-wrap float-left h-auto pb-24`}>
            {ipads?.length > 0
              ? ipads?.map((ipad) => {
                  return (
                    <IpadAttendeesCard
                      key={ipad?.device_id}
                      isHighlighted={ipad?.connected}
                      titleColor={ipad.users?.length ? 'blue' : 'gray'}
                      isAttendeeHighlighted={() => true}
                      ipad={ipad}
                      onEdit={(ipadEmployee) => {
                        setUserEditModal({ isOpen: true, employee: ipadEmployee, employeeId: ipadEmployee.id, ipadDeviceId: ipad?.device_id })
                      }}
                    />
                  )
                })
              : null}
            {message ? <p className="text-gray-500 text-lg font-medium mt-16 p-0 text-center w-full">{message}</p> : null}
          </div>
          {showParticipants && (
            <>
              {showConfirmModal && (
                <ConfirmModal
                  title={`Sind Sie sicher, dass Sie PTA ${selectedEmployee?.first_name + ' ' + selectedEmployee?.last_name} löschen möchten?`}
                  closeModal={() => {
                    setShowConfirmModal(false)
                  }}
                  confirm={() => {
                    deleteEmployee(employeeId)
                    deleteIpadUser(employeeId)
                    setShowConfirmModal(false)
                  }}
                />
              )}
              <div
                className={`${
                  showParticipants ? 'right-0' : '-right-1/3'
                } absolute max-h-screen overflow-y-scroll h-auto w-1/3 transition ease-in-out duration-300 pl-7 pb-32 float-left`}
              >
                <div className="bg-white border-l border-b border-gray-400 pb-32 min-h-screen">
                  <h2 className={` ${showParticipants ? 'border-b-2 border-gray-400' : ''} text-lg text-blue-700 font-medium p-4 m-0`}>
                    Alle Nutzer des Standorts:
                  </h2>
                  {allEmployees?.length ? (
                    allEmployees.map((employee) => {
                      return (
                        <AttendeesCardItem
                          key={employee.id}
                          textColor="highlighted"
                          text={employee.is_anonymous_user ? ANONYMOUS_USER.NAME : employee.first_name + ' ' + employee.last_name}
                          onEdit={() => {
                            setUserEditModal({
                              isOpen: true,
                              employeeId: employee.id || '',
                              employee: employee,
                            })
                          }}
                          onDelete={
                            !employee.is_anonymous_user && employee.id
                              ? () => {
                                  setSelectedEmployee(employee)
                                  setEmployeeId(employee?.id || '')
                                  setShowConfirmModal(true)
                                }
                              : undefined
                          }
                        />
                      )
                    })
                  ) : (
                    <p className="text-black-100 text-lg font-medium m-0 p-4">Keine Nutzer vorhanden!</p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <PrevNextButtons
          customStyle="absolute bg-white flex w-full justify-between align-end px-7 py-4 bottom-0 shadow-top"
          prevButton={{ onClick: handleBackClick, kind: ButtonKind.secondary, text: 'Zurück' }}
          nextButton={{ onClick: next, text: 'Weiter' }}
        />
        {userEditModal.isOpen ? (
          userEditModal.ipadDeviceId ? (
            <EditIpadUsersModal
              closeModal={() => setUserEditModal({ isOpen: false })}
              ipad={ipads.find((ipad) => userEditModal.ipadDeviceId === ipad.device_id) as Ipad}
              ipads={ipads}
              selectedUser={userEditModal.employee}
            />
          ) : (
            <EditEmployeeModal
              employee={userEditModal.employee}
              employeeId={userEditModal.employeeId}
              onClose={() => setUserEditModal({ isOpen: false })}
            />
          )
        ) : null}
      </div>
    </>
  )
}

export default Attendees
