import React, { FunctionComponent, ReactNode } from 'react'
import Header from '../Header'

interface ClientBackgroundProps {
  onSettingsClick?: () => void
  children?: ReactNode
}

const ClientBackground: FunctionComponent<ClientBackgroundProps> = ({ onSettingsClick, children }) => {
  return (
    <div className="w-full  h-screen flex flex-wrap overflow-x-hidden overflow-y-auto bg-gray-100 font-body">
      <Header onSettingsClick={onSettingsClick} />
      <div className="flex w-full bg-no-repeat bg-center flex-col justify-center items-center mt-16 z-40">
        <img className="absolute animate-wiggle -mr-16 right-0 top-0 mt-40" src="/login-rotate-2.svg" alt="" />
        <img className="absolute h-80 left-0 bottom-14 -ml-44" src="/bg-client-left.png" alt="" />
        {children}
      </div>
    </div>
  )
}

export default ClientBackground
