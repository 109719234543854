import { useEffect, useState } from 'react'
import { Device } from '@capacitor/device'

export const useDeviceId = () => {
  const [uuid, setUUID] = useState<string>('')

  useEffect(() => {
    getDeviceUUID()
  }, [])

  const getDeviceUUID = async () => {
    await Device.getId().then((res) => setUUID(res.uuid))
  }

  return uuid
}
