import { Employee } from '@aposphaere/core-kit/build/models'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { useHistory } from 'react-router'
import { db } from '../../firebaseConfig'
import { CLIENT_TRAININGS_ROUTE, USERS_IPAD_CHANGE_ROUTE, WAITING_ROUTE } from '../../routes'
import { addIpadUser } from './useIpads'
import { setIpadStatusConnected } from '../../hooks'
import { employeeCollection } from '../../lib/entities/pta'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { useAppContext } from '../../lib/contexts/appContext'

export const useCreateEmployee = () => {
  const { setAddedUsers, addedUsers, intendedUserCount: userCount } = useAppContext()
  const history = useHistory()
  const { isOnline } = useNetworkMode()
  const callAPI = async (userData: Employee, uuid: string, privacyPolicyAccepted: boolean) => {
    const data = { ...userData, privacy_policy_accepted: privacyPolicyAccepted }
    if ((privacyPolicyAccepted && !userData.is_anonymous_user) || (!privacyPolicyAccepted && userData.is_anonymous_user)) {
      try {
        let docRefId = data.id
        if (!data.id) {
          //Add user to PTA collection if is new user and not choosed from the suggestions
          delete data.id
          const docRef = doc(employeeCollection)
          setDoc(docRef, data)
          docRefId = docRef.id
        }

        const newAddedUsers = addedUsers !== undefined ? addedUsers + 1 : 1
        addIpadUser({ ...data, id: docRefId }, uuid)
        setAddedUsers(newAddedUsers)
        const allDeviceUsersAreSet = newAddedUsers > 0 && userCount !== undefined && userCount > 0 && newAddedUsers === userCount

        if (allDeviceUsersAreSet) {
          setIpadStatusConnected(uuid)
          if (!isOnline) {
            history.push(CLIENT_TRAININGS_ROUTE)
            return
          }

          history.push(WAITING_ROUTE)
        } else {
          history.push(USERS_IPAD_CHANGE_ROUTE)
        }
      } catch (error) {
        console.error('User Document error: ', error)
      }
    }
  }
  return [callAPI] as const
}

export const useUpdateEmployee = () => {
  const callAPI = async (userData: Employee) => {
    const docRef = doc(db, 'ptas', userData?.id ? userData?.id : '')
    await updateDoc(docRef, {
      ...userData,
    })
  }
  return [callAPI] as const
}
