import { Employee } from '@aposphaere/core-kit/build/models'
import { Answer } from '../../../lib/entities/answer'
import { Question } from '../../../lib/entities/question'

interface QuestionnaireState {
  status: 'initializing' | 'answering-questions' | 'waiting-for-next-user' | 'finished' | 'no-questions-for-presentation'
  requiredFinished: boolean
  questions: Question[]
  questionIndex: number
  userIndex: number
  statisticsId: string
  users: Employee[]
  answers: Answer[]
}

const NO_ANSWER_TEXT = 'Keine Angaben'

export const initialQuestionnaireState: QuestionnaireState = {
  users: [],
  questionIndex: 0,
  requiredFinished: false,
  userIndex: 0,
  statisticsId: '',
  questions: [],
  answers: [],
  status: 'initializing',
}

type Action =
  | {
      type: 'initialize'
      users: Employee[]
      questions: Question[]
      statisticsId: string
    }
  | {
      type: 'nextQuestion'
    }
  | {
      type: 'prevQuestion'
    }
  | {
      type: 'setAnswer' | 'toggleAnswer'
      questionId: number
      pharmacyId: number
      answer: string | number
      index: number
    }
  | {
      type: 'endOfUserQuestions'
    }
  | {
      type: 'startNextUserQuestionnaire'
    }
  | {
      type: 'cancelNextUserPrompt'
    }

export function questionnaireReducer(state: QuestionnaireState, action: Action): QuestionnaireState {
  const currentQuestion = getCurrentQuestion(state)
  const currentAnswer = getCurrentAnswer(state)

  switch (action.type) {
    case 'initialize': {
      const newState: QuestionnaireState = {
        status: action.questions?.length ? 'answering-questions' : 'no-questions-for-presentation',
        questions: action.questions,
        requiredFinished: false,
        users: action.users,
        statisticsId: action.statisticsId,
        answers: [],
        questionIndex: 0,
        userIndex: 0,
      }
      return { ...newState, requiredFinished: getRequiredFinishedValue(newState) }
    }
    case 'setAnswer': {
      const newAnswers = [...state.answers]
      const newAnswer = createAnswer(state, action.answer, action.questionId, action.pharmacyId)

      newAnswers[action.index] = newAnswer
      const newState: QuestionnaireState = { ...state, answers: newAnswers }
      newState.requiredFinished = getRequiredFinishedValue(newState)

      return newState
    }
    case 'toggleAnswer': {
      const newAnswers = [...state.answers]

      const prevAnswer = newAnswers[action.index]
      const prevAnswerArray = Array.isArray(prevAnswer?.answer) ? prevAnswer.answer : []
      const newAnswerValue = [...prevAnswerArray]

      const indexOfValue = newAnswerValue.findIndex((answerValue) => answerValue === action.answer)
      const arrayAction: 'remove' | 'add' = indexOfValue === -1 ? 'add' : 'remove'

      if (arrayAction === 'remove') {
        newAnswerValue.splice(indexOfValue, 1)
      }
      if (arrayAction === 'add') {
        newAnswerValue.push(action.answer)
      }

      newAnswers[action.index] = createAnswer(state, newAnswerValue, action.questionId, action.pharmacyId)
      const newState: QuestionnaireState = { ...state, answers: newAnswers }
      newState.requiredFinished = getRequiredFinishedValue(newState)

      return newState
    }

    case 'nextQuestion': {
      const hasMoreQuestions = state.questions.length > state.questionIndex + 1

      const shouldHandleMissingAnswer = !currentQuestion?.is_required && !currentAnswer?.answer

      if (shouldHandleMissingAnswer && currentQuestion) {
        state.answers[state.questionIndex] = createAnswer(state, NO_ANSWER_TEXT, currentQuestion?.id)
      }

      return { ...state, questionIndex: hasMoreQuestions ? state.questionIndex + 1 : state.questionIndex }
    }
    case 'prevQuestion': {
      const hasPreviousQuestion = state.questionIndex > 0
      return { ...state, questionIndex: hasPreviousQuestion ? state.questionIndex - 1 : state.questionIndex }
    }

    case 'startNextUserQuestionnaire': {
      const hasNextUser = state.userIndex + 1 <= state.users.length
      const newState: QuestionnaireState = {
        ...state,

        status: hasNextUser ? 'answering-questions' : state.status,
        userIndex: hasNextUser ? state.userIndex + 1 : state.userIndex,
        questionIndex: 0,
        answers: [],
      }
      newState.requiredFinished = getRequiredFinishedValue(newState)

      return newState
    }
    case 'cancelNextUserPrompt': {
      return { ...state, status: 'answering-questions' }
    }
    case 'endOfUserQuestions': {
      const hasNextUser = state.userIndex + 1 < state.users.length
      return {
        ...state,
        status: hasNextUser ? 'waiting-for-next-user' : 'finished',
      }
    }
  }
}

function getCurrentAnswer(state: QuestionnaireState) {
  return state.answers[state.questionIndex] ? state.answers[state.questionIndex] : null
}

function getCurrentQuestion(state: QuestionnaireState) {
  return state.questions[state.questionIndex] ? state.questions[state.questionIndex] : null
}

function createAnswer(state: QuestionnaireState, answer: string | number | (string | number)[], questionId: number, pharmacyId?: number): Answer {
  return {
    answer,
    statistic_id: state.statisticsId,
    pharmacy_id: pharmacyId,
    question_id: questionId,
    pta: state.users[state.userIndex],
    pta_id: state.users[state.userIndex]?.id || '',
  }
}

function getRequiredFinishedValue(state: QuestionnaireState) {
  const requiredQuestionsWithoutAnswers = state.questions.filter(
    (question, questionIndex) => question.is_required && !state.answers?.[questionIndex]?.answer,
  )

  const areRequiredQuestionsFinished = requiredQuestionsWithoutAnswers.length === 0
  return areRequiredQuestionsFinished
}
