import { InputLabel, InputSearchPanel, InputSearchPanelKind, TextInput } from '@aposphaere/ui-components'
import { useFormik } from 'formik'
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import ClientBackground from '../../../components/ClientBackground'
import { ACCEPT_PRIVACY_POLICY_ROUTE, SETUP_USERS_ROUTE } from '../../../routes'
import * as Yup from 'yup'
import { AppContext, IAppContext } from '../../../lib/contexts/appContext'
import { useCurrentAndRelatedPharmacyEmployees, usePharmacyEmployeePositions } from '../../../hooks/entities/usePharmacies'
import PrevNextButtons from '../../../components/PrevNextButtons'
import { Employee } from '@aposphaere/core-kit/build/models'
import { usePharmacy } from '../../../lib/contexts/pharmacyContext'
import { createRegisterFunction } from '../../../lib/utils/formHelpers'

const initialFormValues: Employee = {
  first_name: '',
  last_name: '',
  pharmacy_id: 0,
  email: '',
  gender: '',
  employee_position_id: '',
  privacy_policy_accepted: false,
  is_anonymous_user: false,
}

const AddUser: React.FC = () => {
  const { pharmacy } = usePharmacy()
  const { intendedUserCount, addedUsers } = useContext(AppContext) as IAppContext
  const { employeePositions } = usePharmacyEmployeePositions()
  const history = useHistory()
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true)
  const { allEmployees } = useCurrentAndRelatedPharmacyEmployees(pharmacy?.id || null)
  const [suggestedEmployees, setSuggestedEmployees] = useState<Employee[]>([])
  const [selectedUserId, setSelectedUserId] = useState<string>()

  const pharmacyEmail = pharmacy?.email?.toLowerCase()
  const goBack = () => {
    history.push(SETUP_USERS_ROUTE)
  }

  const addNewUser = (values: Employee) => {
    if (!values.is_anonymous_user) {
      history.push({
        pathname: ACCEPT_PRIVACY_POLICY_ROUTE,
        state: { is_anonymous_user: false, userData: { ...values, id: selectedUserId } },
      })
    }
  }

  const onFormSubmit = (values: Employee) => {
    addNewUser(values)
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        first_name: Yup.string().trim().min(2, 'Zu kurz').required('Feld ist notwendig!'),
        last_name: Yup.string().trim().min(2, 'Zu kurz').required('Feld ist notwendig!'),
        email: Yup.string()
          .email('Ungültige E-Mail')
          .required('Feld ist notwendig!')
          .test('is-pharmacy-generic-email', 'Bitte verwenden Sie eine personalisierte Emailadresse wie Vorname.Nachname@apotheke.de', (email) => {
            return Boolean(email && email?.toLowerCase() !== pharmacyEmail)
          }),
        gender: Yup.string().required('Feld ist notwendig!'),
        employee_position_id: Yup.string().trim().required('Feld ist notwendig!'),
      }),
    [pharmacyEmail],
  )

  const { values, errors, touched, isValid, handleBlur, submitForm, handleSubmit, setFieldValue, setFieldTouched, handleChange } = useFormik({
    initialValues: initialFormValues,
    onSubmit: onFormSubmit,
    validationSchema: validationSchema,
    validateOnBlur: true,
  })

  useEffect(() => {
    setFieldValue('pharmacy_id', pharmacy?.id)
  }, [setFieldValue, pharmacy?.id])

  const register = createRegisterFunction({ values, handleChange, handleBlur })

  const checkForSuggestions = (value: string, field: string, employeePositionId: string, gender: string) => {
    const objectKey = field as keyof Employee
    if (!allEmployees?.length || value.length < 2 || !objectKey || !employeePositionId || !gender) {
      return
    }
    let employeeSuggestions: Employee[] = []
    allEmployees.forEach((employee) => {
      const employeeData = employee[objectKey] as string
      const fieldMatched = employeeData.toLocaleLowerCase().startsWith(value.toLowerCase())
      const employeePositionMatched = employee.employee_position_id === employeePositionId
      const genderMatched = employee.gender === gender

      if (fieldMatched && employeePositionMatched && genderMatched) {
        employeeSuggestions.push({ ...employee, id: employee.id })
      }
    })

    setSuggestedEmployees(employeeSuggestions)
    setShowSuggestions(employeeSuggestions.length ? true : false)
  }

  return (
    <ClientBackground>
      <div className="w-6/12 max-w-2xl">
        <h1 className="text-blue-600 text-2xl font-medium text-center w-full mb-10">{`Nutzer ${
          addedUsers !== undefined ? addedUsers + 1 : ''
        } von ${intendedUserCount} einrichten`}</h1>
        <p className="text-blue-600 text-lg font-medium text-center w-full">{'Neuen Teilnehmer anlegen:'}</p>
      </div>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex flex-wrap w-6/12 rounded-lg h-fit justify-center pt-5 pb-5 mx-auto relative z-9999">
          <div className="w-1/2 py-2 px-4">
            <InputLabel htmlFor="employee_position_id">
              <span className="text-gray-900 mb-2.5">{'FUNKTION:'}</span>
              {errors.employee_position_id && touched.employee_position_id ? (
                <span role="alert" className="text-red-700 mb-2.5 ml-2.5 normal-case">
                  {errors.employee_position_id}
                </span>
              ) : null}
            </InputLabel>
            <div className="w-full rounded-md">
              <select
                {...register('employee_position_id')}
                className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
              >
                <option value=""></option>
                {employeePositions !== null &&
                  employeePositions?.length > 0 &&
                  employeePositions?.map(({ id, name }: { id: number; name: string }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
          <div className="w-1/2 py-2 px-4">
            <InputLabel htmlFor="gender">
              <span className="text-gray-900 mb-2.5">{'ANREDE:'}</span>
              {errors.gender && touched.gender ? (
                <span role="alert" className="text-red-700 mb-2.5 ml-2.5 normal-case">
                  {errors.gender}
                </span>
              ) : null}
            </InputLabel>
            <div className="w-full rounded-md">
              <select
                {...register('gender')}
                className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
              >
                <option value=""></option>
                <option value="male">Herr</option>
                <option value="female">Frau</option>
                <option value="other">Divers</option>
              </select>
            </div>
          </div>
          <div className="w-1/2 py-2 px-4">
            <InputLabel htmlFor="first_name">
              <span className="text-gray-900 mb-2.5">{'VORNAME:'}</span>
              {errors.first_name && touched.first_name ? <span className="text-red-700 mb-2.5 ml-2.5 normal-case">{errors.first_name}</span> : null}
            </InputLabel>
            <InputSearchPanel
              {...register('first_name')}
              kind={InputSearchPanelKind.flat}
              displayCol={true}
              inputClass="form-input font-body text-gray-900 block w-full h-10 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
              handleOnBlur={() => setFieldTouched('first_name', true)}
              onType={(value: string) => {
                setFieldValue('first_name', value)
                setSelectedUserId('')
                if (value.length >= 2) checkForSuggestions(value, 'first_name', values.employee_position_id, values.gender)
              }}
            >
              {showSuggestions && values.first_name.length >= 2 && (
                <div className="w-full">
                  {suggestedEmployees.map((employee, index) => (
                    <span
                      className="block cursor-pointer"
                      key={index}
                      onClick={() => {
                        setShowSuggestions(false)
                        setFieldValue('first_name', employee.first_name)
                        setFieldValue('last_name', employee.last_name)
                        setFieldValue('email', employee.email)
                        setSelectedUserId(employee.id)
                      }}
                    >{`${employee.first_name}`}</span>
                  ))}
                </div>
              )}
            </InputSearchPanel>
          </div>
          <div className="w-1/2 py-2 px-4">
            <InputLabel htmlFor="last_name">
              <span className="text-gray-900 mb-2.5">{'NACHNAME:'}</span>
              {errors.last_name && touched.last_name ? <span className="text-red-700 mb-2.5 ml-2.5 normal-case">{errors.last_name}</span> : null}
            </InputLabel>
            <InputSearchPanel
              {...register('last_name')}
              kind={InputSearchPanelKind.flat}
              displayCol={true}
              inputClass="form-input font-body text-gray-900 block w-full h-10 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
              handleOnBlur={() => setFieldTouched('last_name', true)}
              onType={(value: string) => {
                setFieldValue('last_name', value)
                setSelectedUserId('')
                if (value.length >= 2) checkForSuggestions(value, 'last_name', values.employee_position_id, values.gender)
              }}
            >
              {showSuggestions && values.last_name.length >= 2 && (
                <div className="w-full">
                  {suggestedEmployees.map((employee, index) => (
                    <span
                      className="block"
                      key={index}
                      onClick={() => {
                        setShowSuggestions(false)
                        setFieldValue('last_name', employee.last_name)
                        setFieldValue('first_name', employee.first_name)
                        setFieldValue('email', employee.email)
                        setSelectedUserId(employee.id)
                      }}
                    >{`${employee.last_name}`}</span>
                  ))}
                </div>
              )}
            </InputSearchPanel>
          </div>
          <div className="w-full py-2 px-4">
            <InputLabel>
              <span className="text-gray-900 mb-2.5 opacity-50">{'APOTHEKE:'}</span>
            </InputLabel>
            <TextInput bgColor="bg-white" disabled type="text" value={pharmacy?.name || ''} onChange={() => {}} />
          </div>
          <div className="w-full py-2 px-4">
            <InputLabel htmlFor="email">
              <span className="text-gray-900 mb-2.5">{'E-MAIL:'}</span>
              {errors.email && touched.email ? (
                <span role="alert" className="text-red-700 mb-2.5 ml-2.5 normal-case">
                  {errors.email}
                </span>
              ) : null}
            </InputLabel>
            <TextInput {...register('email')} bgColor="bg-white" type="email" />
          </div>
        </div>
        <PrevNextButtons prevButton={{ onClick: goBack, text: 'Zurück' }} nextButton={{ onClick: submitForm, text: 'Weiter', disabled: !isValid }} />
      </form>
    </ClientBackground>
  )
}

export default AddUser
