import React from 'react'
import { Button, ButtonKind } from '@aposphaere/ui-components'
import { CUSTOMERS_ROUTE } from '../../routes'
import LoginForm from '../../components/LoginForm'
import LoginLayout from '../../layouts/LoginLayout'

const Login: React.FC = () => {
  const onSetup = () => {
    //todo
  }

  return (
    <LoginLayout>
      <div className="flex w-3/5 md:w-4/5 lg:w-3/5 bg-white shadow-2xl z-50 justify-center rounded-xl max-w-screen-md p-5">
        <div className="w-6/12 py-5 border-r-2 border-gray-300">
          <div className="flex flex-col items-center justify-between h-full pr-10 pl-5 pb-7">
            <div className="flex flex-col h-2/3 justify-between">
              <h1 className="text-blue-600 text-xl font-medium text-center w-full mt-0">{'Geräte einrichten:'}</h1>
              <p className="text-gray-600 text-lg font-medium text-center w-full">{'Zum Verbinden aller Geräte mit dem Master iPad:'}</p>
            </div>
            <Button kind={ButtonKind.primary} onClick={onSetup}>
              {'Setup neu starten'}
            </Button>
          </div>
        </div>
        <div className="flex flex-col justify-between w-6/12 pt-5 pr-9 pl-12">
          <h1 className="text-blue-600 text-xl font-medium text-center w-full mt-0">{'Login:'}</h1>
          <LoginForm redirectRoute={CUSTOMERS_ROUTE} />
        </div>
      </div>
    </LoginLayout>
  )
}

export default Login
