export type IncomingPresentationPostMessage =
  | {
      type: 'time'
      totalTime?: number
      minTime?: number
    }
  | {
      type: 'finished'
      totalTime: number
      startTime: number
      overtime: number
    }
  | {
      type: 'goPage'
      command: {
        command: {
          clickevent: string
        }
        viewid: string
      }
    }
  | {
      type: 'videoSrc'
      command: {
        src: string
      }
    }

export type SendablePresentationPostMessage =
  | {
      type: 'showButton'
    }
  | {
      type: 'goPage'
      command: {
        viewid: string
        command?: {
          clickevent: string
        }
      }
    }

export const sendPresentationPostMessage = (iframe: HTMLIFrameElement, message: SendablePresentationPostMessage) => {
  if (iframe) {
    iframe?.contentWindow?.postMessage(message, '*')
  }
}
