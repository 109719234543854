import React, { PropsWithChildren } from 'react'
import DeviceIdModal from '../components/DeviceIdModal'
import Header from '../components/Header'

const LoginLayout = ({ children }: PropsWithChildren<{}>) => (
  <div className="w-full h-screen flex flex-wrap overflow-hidden bg-orange-100 font-body">
    <DeviceIdModal>{({ openModal }) => <Header onSettingsClick={openModal} />}</DeviceIdModal>
    <div className="flex absolute w-full h-full bg-no-repeat bg-center justify-center items-center">
      <img className="absolute -ml-96 mt-0 animate-spin-slow" src="/login-rotate-1.svg" alt="" />
      <img className="absolute -mr-450 -mt-350 animate-wiggle" src="/login-rotate-2.svg" alt="" />
      <img className="absolute" src="/bg-login.svg" alt="" />
      {children}
    </div>
    <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-700 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
  </div>
)

export default LoginLayout
