import { IconButton, IconButtonKind, SettingsIcon } from '@aposphaere/ui-components'
import React, { FunctionComponent, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../lib/contexts/authContext'
import { ENTRY_SCREEN_ROUTE } from '../../routes'

interface HeaderProps {
  onSettingsClick?: () => void
  children?: ReactNode
}

const Header: FunctionComponent<HeaderProps> = ({ onSettingsClick, children }) => {
  const location = useLocation()
  const { user } = useAuth()
  const isMaster = user?.type === 'Trainer'
  const isEntryScreen = location.pathname === ENTRY_SCREEN_ROUTE

  return (
    <>
      <div className="absolute bg-white h-64 w-110 transform -rotate-3 -mt-32 -ml-3" />
      <div className="absolute flex w-full flex-row justify-between">
        <img
          className="h-11 w-auto ml-16 mt-16"
          src={isMaster || isEntryScreen ? '/logo_master.png' : '/logo_client.png'}
          alt="Aposphäre Presenter App"
        />
        {onSettingsClick && (<div data-testid="logout_client">
          <IconButton
            kind={IconButtonKind.custom}
            icon={<SettingsIcon />}
            additionalCss="mr-10 mt-5 text-blue-600 active:text-white hover:text-white focus:outline-none hover:text-blue-700 rounded active:bg-blue-800 transition ease-in-out duration-150 z-10"
            onClick={onSettingsClick}
          />
        </div>)}
        {children}
      </div>
    </>
  )
}

export default Header
