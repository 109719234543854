import { getDocs, increment, query } from 'firebase/firestore'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'

export interface Statistic {
  pharmacy_id: number
  trainer_code: string
  presentation_id: string
  uuid: string
  project_id: number
  project_name: string
  start_time?: number
  end_time?: number
  date?: string
  appointment_doc_id?: string
  participants?: number
  device_ids?: string[]
  pta_ids?: string[]
}

const { collection, ...statistic } = initFirebaseEntity<Statistic>('statistics')

export const statisticCollection = collection
export const statisticWhere = statistic.where

export const getStatistic = statistic.get
export const updateStatistic = statistic.update
export const createStatistic = statistic.create
export const deleteStatistic = statistic.delete
export const getStatisticsForAppointment = (appointmentDocId: string, trainerCode: string) =>
  getDocs(query(collection, statistic.where('appointment_doc_id', '==', appointmentDocId), statistic.where('trainer_code', '==', trainerCode)))

export const addParticipantToStatistic = (statisticDocId: string) => {
  updateStatistic(statisticDocId, { participants: increment(1) })
}
