import { Employee, Ipad } from '@aposphaere/core-kit/build/models'
import { ButtonKind } from '@aposphaere/ui-components'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import AttendeesCardItem from '../../components/AttendesCard/CardItem'
import IpadAttendeesCard from '../../components/AttendesCard'
import PageTitleSection from '../../components/PageTitleSection'
import { QUESTIONNAIRE_PARTICIPANTS_ROUTE, STATISTICS_ROUTE, MASTER_TRAININGS_ROUTE } from '../../routes'
import { EditIpadUsersModal } from '../../components/EditIpadUsersModal'
import PrevNextButtons from '../../components/PrevNextButtons'
import Spinner from '../../components/Spinner'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { usePresentation } from '../../lib/contexts/presentationContext'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { useCurrentIpads } from '../../lib/contexts/ipadsContext'
import { useAsync } from '../../hooks/useAsync'
import { getGeneralQuestions, getQuestionsForProject, Question } from '../../lib/entities/question'
import { QuerySnapshot } from 'firebase/firestore'
import { ANONYMOUS_USER } from '../../lib/entities/user'
import { EditEmployeeModal } from '../../components/EditEmployeeModal'
import { deleteEmployee } from '../../lib/entities/pta'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import ConfirmModal from '../../components/ConfirmModal'
import { useCurrentAndRelatedPharmacyEmployees } from '../../hooks/entities/usePharmacies'

type ConfirmModalState = { isOpen: false } | { isOpen: true; onConfirm: () => void; text: string; confirmText?: string }
type UserEditModalState = { isOpen: false } | { isOpen: true; employeeId: string; employee: Employee; ipadDeviceId?: string }

const QuestionnaireParticipants: React.FunctionComponent = () => {
  const { pharmacy } = usePharmacy()
  const { presentation, isLoading: presentationLoading, presentationSnapshot } = usePresentation()
  const { removeActiveAppointment } = useActiveAppointment()
  const { ipads } = useCurrentIpads()
  const { isOnline } = useNetworkMode()
  const history = useHistory()

  const { allEmployees } = useCurrentAndRelatedPharmacyEmployees(pharmacy?.id || null)
  const [participantsSidebar, setParticipantsSidebar] = useState({ isOpen: false })
  const [userEditModal, setUserEditModal] = useState<UserEditModalState>({ isOpen: false })
  const [confirmModal, setConfirmModal] = useState<ConfirmModalState>({ isOpen: false })

  const presentationName = presentation?.name
  const connectedIpads = ipads.filter((ipad) => ipad.connected)

  const {
    run: startGettingProjectQuestions,
    data: projectQuestionsSnapshot,
    isLoading: areProjectQuestionsLoading,
  } = useAsync<QuerySnapshot<Question> | null>(null)
  const {
    run: startGettingGeneralQuestions,
    data: generalQuestionsSnapshot,
    isLoading: areGeneralQuestionsLoading,
  } = useAsync<QuerySnapshot<Question> | null>(null)

  useEffect(() => {
    if (presentation?.project_id) {
      startGettingProjectQuestions(getQuestionsForProject(presentation?.project_id))
    }
    startGettingGeneralQuestions(getGeneralQuestions())
  }, [presentation?.project_id])

  const handleClick = () => {
    //todo
  }

  const haveQuestions = Boolean(projectQuestionsSnapshot?.docs?.length || generalQuestionsSnapshot?.docs?.length)
  const showIpads = Boolean(haveQuestions && connectedIpads?.length)
  const usersFinishedRequiredQuestions = ipads.every((ipad) => ipad.users.every((user) => user.questionnaire_status?.required_done))
  const closeModal = () => setUserEditModal({ isOpen: false })

  let feedbackMessage = ''

  if (!connectedIpads.length) {
    feedbackMessage = 'Es gibt keine verbundenen iPads'
  }
  if (!haveQuestions) {
    feedbackMessage = 'Die Präsentation enthält keine Fragen'
  }
  if (!isOnline) {
    feedbackMessage = 'Sie sind offline'
  }

  if (presentationLoading || areProjectQuestionsLoading || areGeneralQuestionsLoading) {
    return <Spinner />
  }

  const handleNextButtonClick = () => {
    const action = () => {
      history.push({ pathname: MASTER_TRAININGS_ROUTE, state: { from: QUESTIONNAIRE_PARTICIPANTS_ROUTE } })
    }
    if (usersFinishedRequiredQuestions) {
      action()
    } else {
      setConfirmModal({
        isOpen: true,
        text: 'Noch nicht alle haben den Fragebogen ausgefüllt. Sind Sie sicher?',
        onConfirm: action,
        confirmText: 'Nächster Slot',
      })
    }
  }

  const handleAppointmentEnding = () => {
    if (!pharmacy?.id || !presentationName || !presentationSnapshot?.id) {
      console.error("Can't end the presentation, Data is missing to create statistics")
      return
    }
    removeActiveAppointment()
    history.push(STATISTICS_ROUTE)
  }

  const handleFinishButtonClick = () => {
    const action = () => {
      handleAppointmentEnding()
    }
    if (usersFinishedRequiredQuestions) {
      action()
    } else {
      setConfirmModal({
        isOpen: true,
        text: 'Noch nicht alle haben den Fragebogen ausgefüllt. Sind Sie sicher?',
        onConfirm: action,
        confirmText: 'Beenden',
      })
    }
  }

  return presentation ? (
    <>
      <div className="w-full">
        <PageTitleSection
          pageTitle="Fragebögen zur Schulung"
          handleClick={handleClick}
          label={pharmacy?.name ? `${pharmacy.name} | Slot 1` : ''}
          iconType="home"
        />
        <div className="sticky float-right z-30">
          <button
            className={`${
              participantsSidebar.isOpen ? 'border-0 mt-2 mr-4' : 'border-2 mt-4 mr-6'
            } border-blue-600 border-solid rounded-md py-1.5 px-2 z-10 focus:outline-none`}
            onClick={() => setParticipantsSidebar({ isOpen: !participantsSidebar.isOpen })}
          >
            <img src="/assets/icons/user-icon.png" alt="" className="h-6 w-auto" />
          </button>
        </div>
        <div className={`${participantsSidebar.isOpen ? 'max-w-full' : 'max-w-screen-md mx-auto'} ease-in-out duration-300`}>
          <div className={`${participantsSidebar.isOpen ? 'w-2/3 pl-7' : 'w-full'} flex flex-wrap float-left h-auto pb-24`}>
            {showIpads
              ? connectedIpads?.map((ipad) => {
                  return (
                    <IpadAttendeesCard
                      key={ipad.device_id}
                      ipad={ipad}
                      isAttendeeHighlighted={(employee) => Boolean(employee.questionnaire_status?.required_done)}
                      isHighlighted={usersFinishedRequiredQuestions}
                      titleColor="blue"
                      onEdit={(ipadEmployee) => {
                        setUserEditModal({ isOpen: true, employee: ipadEmployee, ipadDeviceId: ipad?.device_id, employeeId: ipadEmployee.id })
                      }}
                    />
                  )
                })
              : null}
            {feedbackMessage ? <h2 className="text-gray-500 text-xl font-medium text-center w-full mt-20">{feedbackMessage}</h2> : null}
          </div>
          {/* SIDEBAR */}
          {participantsSidebar.isOpen && (
            <div
              className={`${
                participantsSidebar.isOpen ? 'right-0' : '-right-1/3'
              } absolute max-h-screen overflow-y-scroll h-auto w-1/3 transition ease-in-out duration-300 pl-7 pb-32 float-left`}
            >
              <div className="bg-white border-l border-b border-gray-400 pb-32 min-h-screen">
                <h2 className={` ${participantsSidebar.isOpen ? 'border-b-2 border-gray-400' : ''} text-lg text-blue-700 font-medium p-4 m-0`}>
                  Alle Nutzer des Standorts:
                </h2>
                {allEmployees?.length ? (
                  allEmployees.map((employee) => {
                    return (
                      <AttendeesCardItem
                        key={employee.id}
                        textColor="dark"
                        text={employee.is_anonymous_user ? ANONYMOUS_USER.NAME : employee.first_name + ' ' + employee.last_name}
                        onEdit={() => {
                          setUserEditModal({
                            isOpen: true,
                            employee: employee,
                            employeeId: employee.id || '',
                          })
                        }}
                        onDelete={!employee.is_anonymous_user && employee.id ? () => deleteEmployee(employee.id || '') : undefined}
                      />
                    )
                  })
                ) : (
                  <p className="text-black-100 text-lg font-medium m-0 p-4">Keine Nutzer vorhanden!</p>
                )}
              </div>
            </div>
          )}
        </div>
        <PrevNextButtons
          nextButton={{
            onClick: handleNextButtonClick,
            kind: ButtonKind.secondaryGreen,
            text: 'Nächster Slot',
          }}
          cancelButton={{
            onClick: handleFinishButtonClick,
            kind: ButtonKind.primary,
            text: 'Beenden',
          }}
        />
        {confirmModal.isOpen ? (
          <ConfirmModal
            title={confirmModal.text}
            confirmBtnText={confirmModal?.confirmText}
            closeModal={() => setConfirmModal({ isOpen: false })}
            confirm={confirmModal.onConfirm}
          />
        ) : null}
        {userEditModal.isOpen && (
          <>
            {userEditModal.ipadDeviceId ? (
              <EditIpadUsersModal
                closeModal={closeModal}
                ipad={ipads.find((ipad) => userEditModal.ipadDeviceId === ipad.device_id) as Ipad}
                ipads={connectedIpads}
                selectedUser={userEditModal.employee}
              />
            ) : (
              <EditEmployeeModal onClose={closeModal} employee={userEditModal.employee} employeeId={userEditModal.employeeId} />
            )}
          </>
        )}
      </div>
    </>
  ) : null
}

export default QuestionnaireParticipants
