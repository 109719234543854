import { ArrowRightIcon } from '@aposphaere/ui-components'
import React from 'react'
import { Project } from '../../lib/entities/project'

type SlotItemProps = {
  project: Project
  index: number
  onClick: () => void
}

const SlotItem: React.FC<SlotItemProps> = ({ project, index, onClick }) => {
  return (
    <div className="mt-2.5 bg-gray-600 flex justify-between items-center px-4 py-1.5 text-white cursor-pointer" onClick={onClick}>
      <p className="text-white font-medium text-base3">
        Slot {index + 1}
        <span className="border-l border-gray-400 inline-block pl-4 ml-4 py-1.5 leading-snug">{project.name}</span>
      </p>
      <ArrowRightIcon />
    </div>
  )
}

export default SlotItem
