import { Filesystem } from '@capacitor/filesystem'
import { ProjectsDirectory } from '.'
import { APP_DIRECTORY, PREVIEW_IMAGE_FILENAME_WITHOUT_EXTENSION } from './config'

export const PreviewImage = {
  fetch: async (previewUrl: string) => {
    if (previewUrl) {
      const previewImageRes = await fetch(previewUrl, {
        mode: 'cors',
      })
      const imageExtension = previewUrl.slice(previewUrl.lastIndexOf('.') + 1)
      const imageBlob = await previewImageRes.blob()
      const imageBase64 = await blobToBase64(imageBlob)
      if (typeof imageBase64 === 'string') {
        return { data: imageBase64, extension: imageExtension }
      }
      return null
    }
  },
  store: async (projectId: string | number, { base64Data, fileExtension }: { base64Data: string; fileExtension: string }) => {
    await Filesystem.writeFile({
      directory: APP_DIRECTORY,
      path: `${ProjectsDirectory.getProjectRootPath(projectId)}/${PREVIEW_IMAGE_FILENAME_WITHOUT_EXTENSION}.${fileExtension}`,
      data: base64Data,
      recursive: true,
    })
  },
  checkIfStored: async (projectId: string | number) => {
    try {
      const projectFolderResponse = await Filesystem.readdir({ directory: APP_DIRECTORY, path: ProjectsDirectory.getProjectRootPath(projectId) })
      const filesInProjectFolder = projectFolderResponse.files
      const previewImageFileName = filesInProjectFolder.find((file) => file.name.startsWith(PREVIEW_IMAGE_FILENAME_WITHOUT_EXTENSION))?.name
      if (!previewImageFileName) {
        throw new Error()
      }
      const imageFile = await Filesystem.readFile({
        directory: APP_DIRECTORY,
        path: `${ProjectsDirectory.getProjectRootPath(projectId)}/${previewImageFileName}`,
      })
      if (imageFile) {
        return true
      }
    } catch {
      return false
    }
  },

  getAsBase64FromFilesystem: async (projectId: string | number) => {
    try {
      const projectFolderResponse = await Filesystem.readdir({ directory: APP_DIRECTORY, path: ProjectsDirectory.getProjectRootPath(projectId) })
      const filesInProjectFolder = projectFolderResponse.files
      const previewImageFileName = filesInProjectFolder.find((file) => file.name.startsWith(PREVIEW_IMAGE_FILENAME_WITHOUT_EXTENSION))?.name
      if (!previewImageFileName) {
        throw new Error()
      }
      const imageFile = await Filesystem.readFile({
        directory: APP_DIRECTORY,
        path: `${ProjectsDirectory.getProjectRootPath(projectId)}/${previewImageFileName}`,
      })
      return `data:image/jpeg;base64,${imageFile.data}`
    } catch {
      return null
    }
  },
}

function blobToBase64(blob: Blob): Promise<FileReader['result']> {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
