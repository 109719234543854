import React, { useMemo, useCallback, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import PrevNextButtons from '../../../components/PrevNextButtons'
import ClientBackground from '../../../components/ClientBackground'
import { CLIENT_PRESENTATION_ROUTE, END_SCREEN_ROUTE, PRIVACY_POLICY_ROUTE } from '../../../routes'
import { ArrowRightIcon, TextAreaInput } from '@aposphaere/ui-components'
import { usePresentation } from '../../../lib/contexts/presentationContext'
import ChangeDeviceUserPrompt from '../../../components/ChangeDeviceUserPrompt'
import Spinner from '../../../components/Spinner'
import { useActiveAppointment } from '../../../lib/contexts/activeAppointmentContext'
import { PresentationStatus } from '../../../lib/constants'
import WaitingOverlay from '../../../components/WaitingOverlay'
import { initialQuestionnaireState, questionnaireReducer } from './reducer'
import { getGeneralQuestions, getQuestionsForProject, Question } from '../../../lib/entities/question'
import { useAsync } from '../../../hooks/useAsync'
import { QuerySnapshot } from 'firebase/firestore'
import { saveAnswer } from '../../../lib/entities/answer'
import { QuestionnaireSlider, QuestionnaireSingleChoice, QuestionnaireMultiChoice } from '../../../components/QuestionComponents'
import { useCurrentIpad } from '../../../hooks/entities/useIpads'
import { useDeviceId } from '../../../hooks/useDeviceId'
import { ClientPresentationLocationState } from '../Presentation'
import { useNetworkMode } from '../../../lib/contexts/networkStatusContext'
import { setEmployeeQuestionnaireStatus } from '../../../lib/entities/iPad'
import { usePharmacy } from '../../../lib/contexts/pharmacyContext'

const QuestionnairePage: React.FC = () => {
  const history = useHistory()
  const deviceId = useDeviceId()
  const location = useLocation<ClientPresentationLocationState>()

  const { isOffline } = useNetworkMode()
  const { pharmacy } = usePharmacy()
  const { activeAppointment } = useActiveAppointment()
  const { presentation } = usePresentation()
  const { ipad, isLoading: isIpadLoading } = useCurrentIpad()
  const projectId = location.state?.projectId ? Number(location.state.projectId) : presentation?.project_id

  // Data fetching hooks
  const {
    run: startGettingProjectQuestions,
    data: projectQuestionsSnapshot,
    isLoading: areProjectQuestionsLoading,
  } = useAsync<QuerySnapshot<Question> | null>(null)
  const {
    run: startGettingGeneralQuestions,
    data: generalQuestionsSnapshot,
    isLoading: areGeneralQuestionsLoading,
  } = useAsync<QuerySnapshot<Question> | null>(null)

  const [questionnaireState, dispatch] = useReducer(questionnaireReducer, initialQuestionnaireState)
  const { status, users, questions, answers, userIndex, questionIndex, requiredFinished } = questionnaireState

  const statisticId = activeAppointment?.statistics_id

  const totalQuestionNumber = useMemo(() => questions.length, [questions?.length])
  const question: Question | null = useMemo(() => {
    return questions[questionIndex] ? questions[questionIndex] : null
  }, [questions, questionIndex])

  const ipadUser = users[userIndex] ? users[userIndex] : null
  const answer = answers[questionIndex] ? answers[questionIndex] : null
  const textAnswer = String(answer?.answer || '')
  const showWaitingScreen = status === 'no-questions-for-presentation' || status === 'finished'

  // Get the questions
  useEffect(() => {
    if (projectId) {
      startGettingProjectQuestions(getQuestionsForProject(projectId))
    }
    startGettingGeneralQuestions(getGeneralQuestions())
  }, [projectId, startGettingProjectQuestions, startGettingGeneralQuestions])

  const isDataLoading = areProjectQuestionsLoading || areGeneralQuestionsLoading || isIpadLoading
  const hasData = Boolean((projectQuestionsSnapshot || generalQuestionsSnapshot) && ipad?.users && statisticId)

  // Initialize data
  useEffect(() => {
    if (!hasData || isDataLoading) {
      return
    }
    if (status === 'initializing') {
      const projectQuestions = projectQuestionsSnapshot?.docs.map((doc) => doc.data()) || []
      const generalQuestions = generalQuestionsSnapshot?.docs.map((doc) => doc.data()) || []

      generalQuestions.sort((a, b) => (a.sequence_number > b.sequence_number ? 1 : -1))
      projectQuestions.sort((a, b) => (a.sequence_number > b.sequence_number ? 1 : -1))

      const fetchedQuestions = generalQuestions.concat(projectQuestions)
      const ipadUsers = ipad?.users || []

      if (statisticId) {
        dispatch({ type: 'initialize', questions: fetchedQuestions, users: ipadUsers, statisticsId: statisticId })
      }
    }
  }, [generalQuestionsSnapshot?.docs, hasData, ipad?.users, isDataLoading, projectQuestionsSnapshot?.docs, statisticId, status])

  const setAnswer = useCallback(
    (value: string | number) => {
      if (!question?.id) {
        console.warn('Missing questionId')
        return
      }
      dispatch({ type: 'setAnswer', answer: value, index: questionIndex, questionId: question.id, pharmacyId: pharmacy?.id || 0 })
    },
    [question?.id, questionIndex, pharmacy?.id],
  )

  const toggleAnswer = useCallback(
    (value: string | number) => {
      if (!question?.id) {
        console.warn('Missing questionId')
        return
      }
      dispatch({ type: 'toggleAnswer', answer: value, index: questionIndex, questionId: question.id, pharmacyId: pharmacy?.id || 0 })
    },
    [question?.id, questionIndex, pharmacy?.id],
  )

  const handleBackClick = () => {
    dispatch({ type: 'prevQuestion' })
  }

  useEffect(() => {
    if (requiredFinished && ipadUser?.id) {
      setEmployeeQuestionnaireStatus('required-done', { deviceUuid: deviceId, employeeId: ipadUser?.id })
    }
  }, [deviceId, requiredFinished, ipadUser?.id])

  const handleNextClick = async () => {
    if (!ipadUser?.id || !answers.length) {
      return
    }
    const isLastQuestion = typeof totalQuestionNumber === 'number' && questionIndex === totalQuestionNumber - 1

    if (isLastQuestion) {
      const saveAnswerPromises = answers.map(async (answer) => saveAnswer(answer))
      await Promise.all(saveAnswerPromises)
      setEmployeeQuestionnaireStatus('all-questions-done', { deviceUuid: deviceId, employeeId: ipadUser.id })
      dispatch({ type: 'endOfUserQuestions' })
    }

    if (!isLastQuestion) {
      dispatch({ type: 'nextQuestion' })
    }
  }

  // Handle the final waiting screen
  useEffect(() => {
    if (!showWaitingScreen) {
      return
    }
    const appointmentEnded = !activeAppointment?.active
    const newPresentationStarted = presentation?.status === PresentationStatus.Active

    if (newPresentationStarted) {
      history.push(CLIENT_PRESENTATION_ROUTE)
    }
    if (appointmentEnded) {
      history.push(END_SCREEN_ROUTE)
    }
  }, [activeAppointment?.active, history, presentation?.status, showWaitingScreen])

  useEffect(() => {
    if (isOffline && showWaitingScreen) {
      history.push(END_SCREEN_ROUTE)
    }
  }, [history, isOffline, showWaitingScreen])

  if (status === 'initializing') {
    return <Spinner />
  }

  if (showWaitingScreen) {
    return (
      <WaitingOverlay
        content={
          status === 'finished' ? (
            <h1 className="text-blue-600 text-xl2 font-medium text-center w-full">{'Vielen Dank für Ihre Antworten'}</h1>
          ) : undefined
        }
      />
    )
  }
  if (status === 'waiting-for-next-user') {
    return (
      <ChangeDeviceUserPrompt
        handleBack={() => dispatch({ type: 'cancelNextUserPrompt' })}
        handleNext={() => {
          dispatch({ type: 'startNextUserQuestionnaire' })
        }}
        title="Vielen Dank für Ihre Angaben."
        description="Bitte geben Sie das iPad nun weiter an die nächste Teilnehmer*in. Vielen Dank!"
      />
    )
  }

  return status === 'answering-questions' && question ? (
    <ClientBackground>
      <div className="flex flex-col h-full w-full relative z-9999">
        <div className="flex justify-center flex-1 flex-col items-center">
          <div className="w-6/12 max-w-2xl pt-20">
            <h1 className="text-blue-600 text-2xl font-medium text-center w-full mb-14">{`Befragung zu ${presentation?.name || ''}`}</h1>
            <h2 className="text-blue-600 text-xl1 font-medium text-center w-full">
              {`Frage ${questionIndex !== undefined && questionIndex + 1}/${totalQuestionNumber} für Nutzer 
        ${ipadUser ? ipadUser?.first_name + ' ' + ipadUser?.last_name : ''}:`}
            </h2>
            <h3 className="text-blue-600 text-xl1 font-medium text-center w-full">{question.text}</h3>
          </div>
          <div className="w-full max-w-4xl flex justify-center mt-16">
            {/* SINGLE_CHOICE */}
            {question.type === 'single-choice' && (
              <QuestionnaireSingleChoice
                options={question.options}
                value={Array.isArray(answer?.answer) ? undefined : answer?.answer}
                onChange={setAnswer}
              />
            )}
            {/* MULTI_CHOICE */}
            {(question.type === 'checkboxes' || question.type === 'multi-choice') && (
              <QuestionnaireMultiChoice
                options={question.options}
                values={answer && Array.isArray(answer?.answer) ? answer.answer : []}
                onChange={toggleAnswer}
              />
            )}
            {/* TEXT QUESTION */}
            {question.type === 'text' && (
              <TextAreaInput
                maxLength={500}
                value={textAnswer}
                containerCustomStyle="mt-1 relative rounded-md w-full max-w-2xl"
                textareaCustomStyle="form-input font-body block w-full max-w-2xl h-64 bg-white rounded-md px-4 py-2 text-lg text-black-100 
        leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                  setAnswer(e.currentTarget.value)
                }}
              />
            )}
            {/* SLIDER QUESTION */}
            {question.type === 'slider' && (
              <QuestionnaireSlider
                min={{ value: question?.options?.from ?? 0, label: question?.options.from_label }}
                max={{ value: question?.options?.to ?? 100, label: question?.options.to_label }}
                step={question?.options.step}
                setValue={setAnswer}
                value={typeof answer?.answer === 'number' ? answer.answer : question?.options.from || 0}
              />
            )}
          </div>
        </div>
        <button
          onClick={() => history.push(PRIVACY_POLICY_ROUTE)}
          className="self-center pt-10 z-50  mb-4 flex  font-medium items-center text-lg px-2 py-2 text-blue-700 hover:text-blue-600 focus:outline-none transition ease-in-out duration-150"
        >
          <ArrowRightIcon />
          <span className="ml-2">{'Unsere Datenschutzbedingungen'}</span>
        </button>
        <PrevNextButtons
          nextButton={{
            text: 'Weiter',
            onClick: handleNextClick,
            disabled: question?.is_required && !answer?.answer,
          }}
          prevButton={{ text: 'Zurück', onClick: handleBackClick, disabled: questionIndex === 0 }}
        />
      </div>
    </ClientBackground>
  ) : null
}

export default QuestionnairePage
