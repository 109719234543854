import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import PrivateRoute from '../../components/PrivateRoute'
import { IpadsProvider } from '../../lib/contexts/ipadsContext'
import {
  ATTENDEES_ROUTE,
  CUSTOMERS_ROUTE,
  LOGIN_ROUTE,
  MASTER_PRESENTATION_ROUTE,
  MASTER_ROOT_PAGE_ROUTE,
  MASTER_TRAININGS_ROUTE,
  QUESTIONNAIRE_PARTICIPANTS_ROUTE,
  STATISTICS_ROUTE,
} from '../../routes'
import NotFound from '../NotFound'
import Attendees from './Attendees'
import Customers from './Customers'
import Login from './Login'
import MasterPresentation from './Presentation'
import QuestionnaireParticipants from './QuestionnaireParticipants'
import Statistics from './Statistics'
import Trainings from './Trainings'

export const MasterRoutes = () => {
  return (
    <IpadsProvider>
      <Switch>
        <Redirect exact from={MASTER_ROOT_PAGE_ROUTE} to={CUSTOMERS_ROUTE} />
        <Route path={LOGIN_ROUTE}>
          {' '}
          <Login />{' '}
        </Route>
        <PrivateRoute path={ATTENDEES_ROUTE} rules={{ device: 'Trainer' }}>
          <Attendees />
        </PrivateRoute>
        <PrivateRoute path={CUSTOMERS_ROUTE} rules={{ device: 'Trainer' }}>
          <Customers />
        </PrivateRoute>
        <PrivateRoute path={STATISTICS_ROUTE} rules={{ device: 'Trainer' }}>
          <Statistics />
        </PrivateRoute>
        <PrivateRoute path={MASTER_TRAININGS_ROUTE} rules={{ device: 'Trainer' }}>
          <Trainings />
        </PrivateRoute>
        <PrivateRoute path={MASTER_PRESENTATION_ROUTE} rules={{ device: 'Trainer' }}>
          <MasterPresentation />
        </PrivateRoute>
        <PrivateRoute path={QUESTIONNAIRE_PARTICIPANTS_ROUTE} rules={{ device: 'Trainer' }}>
          <QuestionnaireParticipants />
        </PrivateRoute>

        <Route>
          <NotFound />
        </Route>
      </Switch>
    </IpadsProvider>
  )
}
