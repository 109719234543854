import { gql } from 'graphql-request'

export const allAppointments = gql`
  query getUserAppointmentsByPharmacyCluster() {
    appointments() {
      id
      date
      end_date
      note
      is_deleted
      cancellation_reason
      pharmacy {
        id
      }
      status {
        id
      }

      quarter {
        id
      }
      appointmentType {
        id
      }
      status {
        id
      }
      creator {
        id
      }
      lastEditor {
        id
      }
    }
  }
`

export const getAppointments = gql`
  query getAppointments($clusterId: ID!, $quarterId: ID!, $date: DateRange) {
    appointments(pharmacy_cluster_id: $clusterId, quarter_id: $quarterId, trashed: WITHOUT, date: $date) {
      id
      date
      end_date
      note
      is_deleted
      pharmacy {
        name
        id
        okid
        classification
        address {
          latitude
          longitude
          address_name
          city
          zipcode
        }
      }
      status {
        id
      }
      appointmentType {
        id
        label
      }
      creator {
        id
        name
      }
      lastEditor {
        id
      }
      order_items {
        project_id
        amount
        project {
          name
          is_orderable
        }
      }
      status_id
      cancellation_reason
      finished_presentations {
        id
        endtime
        number_of_participants
        device_count
        starttime
        time_spent
        adjusted_number_of_participants
        endtime
        project {
          name
          id
        }
        trainer {
          id
          name
        }
      }
      contact_person
    }
  }
`

export const getNextPossibleDateQuery = gql`
  query getNextPossibleDate($quarter_id: ID, $pharmacy_id: ID) {
    getNextPossibleDate(quarter_id: $quarter_id, pharmacy_id: $pharmacy_id)
  }
`
