import { Button, ButtonKind } from '@aposphaere/ui-components'
import React from 'react'
import { useHistory } from 'react-router'
import { AppLauncher } from '@capacitor/app-launcher'
import { Dialog } from '@capacitor/dialog'
import PrevNextButtons from '../components/PrevNextButtons'
import { useActiveAppointment } from '../lib/contexts/activeAppointmentContext'
import { CUSTOMERS_ROUTE, HOME_PAGE_ROUTE } from '../routes'
import { usePharmacy } from '../lib/contexts/pharmacyContext'
import QRCode from '../components/QRCodeCard'
import { useAuth } from '../lib/contexts/authContext'

const EndScreen: React.FC = () => {
  const { pharmacy } = usePharmacy()
  const campusRegistrationCode = pharmacy?.campus_registration_code
  const { removeActiveAppointment } = useActiveAppointment()
  const { user } = useAuth()
  const history = useHistory()

  const qrImages = [
    {
      qrCodeImageSrc: '/qrcode_app_store.svg',
      qrCodeImageAlt: 'QR Code App Store',
      logoImageSrc: '/download_on_app_store.png',
      logoImageAlt: 'Download on the App Store',
      link: 'https://apps.apple.com/de/app/aposph%C3%A4re-campus/id1498775063',
    },
    {
      qrCodeImageSrc: '/qrcode_play_store.svg',
      qrCodeImageAlt: 'QR Code Play Store',
      logoImageSrc: '/get_it_on_google_play.png',
      logoImageAlt: 'Get it on Google Play',
      link: 'https://play.google.com/store/apps/details?id=com.apocampus',
    },
  ]

  const handleAppointmentEnding = () => {
    removeActiveAppointment()

    if (user && user.type === 'Client') {
      history.push(HOME_PAGE_ROUTE)
    }
    if (user?.type === 'Trainer') {
      history.push(CUSTOMERS_ROUTE)
    }
  }

  const openExternalApp = async () => {
    const pharmacyID = pharmacy?.id
    const { value } = await AppLauncher.canOpenUrl({ url: 'qmrtool://?startupdata=' + pharmacyID })
    if (value === true) {
      await AppLauncher.openUrl({ url: 'qmrtool://?startupdata=' + pharmacyID })
    } else {
      await Dialog.alert({
        title: 'Fehler',
        message: 'Die App scheint nicht auf ihrem Gerät installiert zu sein, bitte kontaktieren sie den Support.',
      })
    }
  }

  return (
    <div className="bg-gray-200 min-h-screen h-full overflow-y-auto">
      <div
        className="absolute z-0 w-full min-h-screen h-auto bg-blue-gradient"
        style={{ clipPath: 'polygon(0% 20%, 0 0, 54% 0, 24% 55%, 42% 100%, 0 100%, 0% 80%)' }}
      ></div>
      <div className="flex flex-row justify-between max-w-screen-2xl mx-auto">
        <div className="bg-white p-4 rounded-b-3xl z-10">
          <img className="h-20 w-auto p-2" src="/logo_end_screen.png" alt="Aposphäre Campus" />
        </div>
        <div className="mt-8 mr-4 2lg:mr-0">
          <Button kind={ButtonKind.outlinedPrimary} customStyle="py-6 px-8 border-t-2 border-b-2 border-l-2 border-r-2" onClick={openExternalApp}>
            {'Hier gehts zu aposurvey'}
          </Button>
        </div>
      </div>
      <div className="flex flex-row w-full max-w-screen-2lg mx-auto mt-24 px-5">
        <div className="w-2/4 p-16 border border-gray-200 shadow-2xl rounded-3xl z-10 bg-white">
          <h1 className="text-blue-600 text-2lg  text-center w-full font-medium m-0 mb-2">
            Entdecke jetzt Deine neue
            <span className="block font-bold mt-2">Schulungs-App!</span>
          </h1>
          <div className="flex flex-row justify-between mt-8 w-full">
            {qrImages.map((item) => {
              return (
                <QRCode
                  key={item.logoImageSrc}
                  qrCodeImageSrc={item.qrCodeImageSrc}
                  qrCodeImageAlt={item.qrCodeImageAlt}
                  logoImageSrc={item.logoImageSrc}
                  logoImageAlt={item.logoImageAlt}
                  link={item.link}
                />
              )
            })}
          </div>
          {campusRegistrationCode && (
            <>
              <h3 className="text-pink-500 text-xl2 font-bold text-center m-0 mt-8">Euer Apotheken-Code:</h3>
              <span className="text-center block text-xl2 font-bold mt-3">{campusRegistrationCode}</span>
            </>
          )}
        </div>
        <div className="w-2/4 relative z-0">
          <div className="flex justify-center items-center w-40 h-40 bg-pink-gradient text-white text-2xl font-bold rounded-full absolute left-52 -mt-28 z-30 border-4 border-white">
            NEU!
          </div>
          <img className="h-auto absolute -ml-20 -mt-14" src="/app_screen.png" alt="Aposphäre Campus Screen" />
        </div>
      </div>
      {user?.type === 'Trainer' && <PrevNextButtons nextButton={{ onClick: handleAppointmentEnding, text: 'Beenden' }} />}
      {user?.type === 'Client' && <PrevNextButtons nextButton={{ onClick: handleAppointmentEnding, text: 'Schließen' }} />}
    </div>
  )
}

export default EndScreen
