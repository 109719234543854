import React from 'react'

interface CreateRegisterFnArgs<T> {
  values: T
  handleChange: (e: React.ChangeEvent<unknown>) => void
  handleBlur: (e: React.FocusEvent<unknown, Element> | React.ChangeEvent<unknown>) => void
}

/**
 * ### createRegisterFunction
 * Provides a type-safe way for adding handlers to an input field which uses Formik
 *
 * It provides
 * - name,
 * - id,
 * - onChange,
 * - onBlur
 * properties
 *
 * example usage:
 * ```tsx
 * const initialValues = { value1: 'string', value2: 0 }
 * //...
 * const { values, onChange, onBlur } = useFormik({ initialValues })
 * const register = createRegisterFunction({values, onChange, onBlur})
 * //...
 * return(
 * <form>
 *  <input {...register('value1')} />
 *  <input {...register('value2')} />
 *  <input {...register('value-not-defined')} /> // TS ERROR
 * </form>
 * )
 * ```
 */
export function createRegisterFunction<T>({ values, handleChange, handleBlur }: CreateRegisterFnArgs<T>) {
  return function <K extends keyof T>(name: K) {
    return { value: values[name], name, id: name, onChange: handleChange, onBlur: handleBlur }
  }
}
