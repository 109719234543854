import React, { createContext, useCallback } from 'react'
import { QueryDocumentSnapshot } from 'firebase/firestore'
import { Dispatch, ReactNode, useContext, useEffect, useState } from 'react'

import { Pharmacy } from '@aposphaere/core-kit'
import { getPharmacyById } from '../entities/pharmacy'
import { useAsync } from '../../hooks/useAsync'

/*
 * CONTEXT
 */
interface PharmacyContext {
  pharmacySnapshot: QueryDocumentSnapshot<Pharmacy> | null
  pharmacy: Pharmacy | null
  isLoading: boolean
  isError: boolean
  isIdle: boolean
  error: unknown
  reset: () => void
  status: 'idle' | 'pending' | 'resolved' | 'rejected'

  setPharmacyId: Dispatch<React.SetStateAction<number | null>>
}
const Context = createContext<PharmacyContext | null>(null)
Context.displayName = 'PharmacyContext'

/*
 * PROVIDER
 */
export const PharmacyProvider = ({ children }: { children: ReactNode }) => {
  const [pharmacyId, setPharmacyId] = useState<number | null>(null)

  const { run, isLoading, isError, isIdle, error, data, reset: resetPharmacyData, status } = useAsync<QueryDocumentSnapshot<Pharmacy> | null>(null)

  useEffect(() => {
    if (pharmacyId) {
      run(getPharmacyById(pharmacyId))
    } else {
      resetPharmacyData()
    }
  }, [pharmacyId, resetPharmacyData, run])

  const reset = useCallback(() => {
    setPharmacyId(null)
    resetPharmacyData()
  }, [resetPharmacyData])

  return (
    <Context.Provider
      value={{
        setPharmacyId,
        reset,
        pharmacySnapshot: data,
        pharmacy: data?.data() || null,
        isIdle,
        isLoading,
        isError,
        error,
        status,
      }}
    >
      {children}
    </Context.Provider>
  )
}

/*
 * HOOKS
 */
export const usePharmacy = () => {
  const context = useContext(Context)

  if (!context) {
    throw new Error("usePharmacy can't be used without it's Provider")
  }
  return context
}
