import React, { createContext, useContext } from 'react'
import { useAppProvider } from '../../hooks/useAppProvider'

export interface IAppContext {
  intendedUserCount?: number
  addedUsers?: number
  setIntendedUSerCount: React.Dispatch<React.SetStateAction<number>>
  setAddedUsers: React.Dispatch<React.SetStateAction<number>>
  cleanLocalStorage: () => void
}

interface AppProviderProps {
  children?: React.ReactNode
}

export const AppContext = createContext<IAppContext | null>(null)
AppContext.displayName = 'AppContext'

const AppProvider: React.FunctionComponent<AppProviderProps> = ({ children }) => {
  const appData = useAppProvider()

  return <AppContext.Provider value={appData}>{children}</AppContext.Provider>
}

/*
 * HOOK
 */

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error("useAppContext can't be used without it's Provider")
  }

  return context
}

export default AppProvider
