import React from 'react'
import { useHistory } from 'react-router'
import { CLIENT_TRAININGS_ROUTE, SETUP_USERS_ROUTE, WAITING_ROUTE } from '../../../routes'
import { useAppContext } from '../../../lib/contexts/appContext'
import ChangeDeviceUserPrompt from '../../../components/ChangeDeviceUserPrompt'
import { useNetworkMode } from '../../../lib/contexts/networkStatusContext'

const UsersIpadChange: React.FC = () => {
  const { intendedUserCount: userCount, addedUsers } = useAppContext()
  const { isOnline } = useNetworkMode()
  const history = useHistory()
  const goBack = () => {
    history.push(SETUP_USERS_ROUTE)
  }

  const next = () => {
    if (typeof addedUsers !== 'number' || typeof userCount !== 'number') {
      return
    }

    const allUsersAdded = addedUsers >= userCount

    if (!allUsersAdded) {
      history.push(SETUP_USERS_ROUTE)
    }

    if (allUsersAdded && isOnline) {
      history.push(WAITING_ROUTE)
    }

    if (allUsersAdded && !isOnline) {
      history.push(CLIENT_TRAININGS_ROUTE)
    }
  }

  return (
    <ChangeDeviceUserPrompt
      handleBack={goBack}
      handleNext={next}
      title={`Vielen Dank für Ihre Angaben. Nutzer ${addedUsers} erfolgreich eingerichtet`}
      description="Bitte geben Sie das iPad nun weiter an die nächste Teilnehmer*in für die Erfassung der Nutzerdaten. Vielen Dank!"
    />
  )
}

export default UsersIpadChange
