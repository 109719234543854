import { useEffect, useState } from 'react'
import { Device, DeviceInfo } from '@capacitor/device'

export const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | undefined>()

  useEffect(() => {
    getDeviceUUID()
  }, [])

  const getDeviceUUID = async () => {
    await Device.getInfo().then((res) => {
      setDeviceInfo(res)
    })
  }

  return deviceInfo
}
