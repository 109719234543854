import React, { useEffect, useState } from 'react'
import { Device } from '@capacitor/device'
import StoreProjectsButton from '../StoreProjectsButton'
import { Button, ButtonKind, Modal, ModalKind } from '@aposphaere/ui-components'
import { useAuth } from '../../lib/contexts/authContext'
import { useIsMobile } from '../../hooks/useIsMobile'
import CopyButton from '../CopyButton'

interface SettingsModalProps {
  closeModal: () => void
}

const SettingsModal = ({ closeModal }: SettingsModalProps) => {
  const [uuid, setUUID] = useState<string>('')
  const { user } = useAuth()
  const isMobile = useIsMobile()

  const getDeviceUUID = async () => {
    await Device.getId().then((res) => setUUID(res.uuid))
  }

  useEffect(() => {
    getDeviceUUID()
  }, [])

  const displayedName = user?.entry?.name || 'Unbekannt'

  return (
    <div className="max-w-4x">
      <Modal kind={ModalKind.ms} title={'SystemInfo'} onClose={closeModal} onBack={() => null}>
        <div className="grid grid-cols-2">
          <div className="border-b border-gray-400 mr-8 ml-5 ">
            <p className="text-gray-900 text-lg font-body font-medium my-5">Angemeldet als: {displayedName}</p>
          </div>

          <div className="border-b border-gray-400 ml-8 mr-5">
            <p className="text-gray-900 text-lg font-body font-medium my-5">Eindeutiger Code: {user?.entry?.trainer_code || 'Unbekannt'}</p>
          </div>

          <div className="border-b border-gray-400 mr-8 ml-5">
            <p className="text-gray-900 text-lg font-body font-medium my-5">Gerätetyp: {user?.type || 'Unbekannt'}</p>
          </div>

          <div className="flex items-center border-solid border-b text-left border-gray-400 ml-8 mr-5 text-gray-900 text-lg font-body font-medium py-5">
            Device ID: <span className="color-gray-500 text-base pl-2">{uuid}</span>
            <CopyButton textToCopy={uuid} />
          </div>

          <div className="border-b border-gray-400 ml-5 mr-5">
            <p className="text-gray-900 text-lg font-body font-medium my-5">
              Debug Info: <span className="color-gray-500">Keine Vorhanden</span>
            </p>
          </div>
        </div>
        <div className="mx-5 my-10 flex justify-between w-full">
          <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
            {'Abbrechen'}
          </Button>
          {isMobile && (
            <div className="flex">
              <div className="mr-2 ">
                <StoreProjectsButton kind={ButtonKind.outlinedSecondary} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default SettingsModal
