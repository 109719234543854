import {
  deleteDoc,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  query as firestoreQuery,
  setDoc,
  UpdateData,
  updateDoc,
  where as firestoreWhere,
  WhereFilterOp,
} from 'firebase/firestore'
import { createCollection, db } from '../../firebaseConfig'

type WhereArgs<Type> = {
  [Property in keyof Type]?: Type[Property] | [opStr: WhereFilterOp, value: Type[Property] | Type[Property][]]
}

export function initFirebaseEntity<T>(collectionName: string, converter?: FirestoreDataConverter<T>) {
  const collection = createCollection<T>(collectionName)
  const where = getTypedWhere<T>()

  function query({ where }: { where: WhereArgs<T> }) {
    return firestoreQuery(collection, ...getWhereArray(where))
  }
  /*
   * GET
   */
  function get(docId: string) {
    return getDoc(doc(collection, docId))
  }
  function getAll() {
    return getDocs(firestoreQuery(collection))
  }
  function getMany(args: { where: WhereArgs<T> }) {
    const whereArray = getWhereArray(args.where)
    return getDocs(firestoreQuery(collection, ...whereArray))
  }
  async function getFirst(args: { where: WhereArgs<T> }) {
    const result = await getMany({ where: args.where })
    return result.docs?.[0] ? result.docs?.[0] : null
  }

  /*
   * UPDATE
   */
  function update(id: string, data: UpdateData<T>) {
    const docRef = doc(db, collectionName, id) as DocumentReference<T>
    return updateDoc(docRef, data)
  }
  /*
   * CREATE
   */
  function create(data: T) {
    const newDocument = doc(collection)
    setDoc(newDocument, data)
    return newDocument
  }
  /*
   * DELETE
   */
  function deleteEntry(id: string) {
    const docRef = doc(db, collectionName, id) as DocumentReference<T>
    return deleteDoc(docRef)
  }

  async function deleteMany(args: { where: WhereArgs<T> }) {
    const whereArray = getWhereArray(args.where)
    const itemsToDelete = await getDocs(firestoreQuery(collection, ...whereArray))
    const deletePromises = itemsToDelete.docs.map((doc) => deleteDoc(doc.ref))
    return Promise.all(deletePromises)
  }

  return {
    collectionName,
    collection,
    where,
    query,

    get,
    getMany,
    getFirst,
    getAll,

    create,
    update,

    delete: deleteEntry,
    deleteMany,
  }
}

/*
 * UTILS
 */

export function getTypedWhere<T>() {
  return function typedWhere<K extends keyof T>(fieldPath: K, opStr: WhereFilterOp, value: T[K] | T[K][]) {
    if (typeof fieldPath !== 'string') {
      throw new Error('Error using [where]: the fieldValue can only be string')
    }
    return firestoreWhere(fieldPath, opStr, value)
  }
}

function getWhereArray<T>(whereArgs: WhereArgs<T>) {
  const whereArray = Object.entries(whereArgs).map(([key, whereValue]) => {
    const operation: WhereFilterOp = Array.isArray(whereValue) ? whereValue[0] : '=='
    const comparisonValue = Array.isArray(whereValue) ? whereValue[1] : whereValue
    return firestoreWhere(key, operation, comparisonValue)
  })
  return whereArray
}
