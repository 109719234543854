import { getDocs, query, where, collection } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from '../../firebaseConfig'

import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { getProjectWhereProjectId, Project } from '../../lib/entities/project'

interface PharmacyProject {
  id: number
  pharmacy_id: number
  project_id: number
}

export const useGetAllProjects = () => {
  const [allProjects, setAllProjects] = useState<Project[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true)
  useEffect(() => {
    const fetchProjects = async () => {
      setLoadingProjects(true)
      const data: Project[] = []
      const projectsQuery = query(collection(db, 'projects'))
      const querySnapshot = await getDocs(projectsQuery)
      querySnapshot.forEach(async (doc) => {
        const project = doc.data() as Project
        data.push(project)
      })
      setAllProjects(data)
      setLoadingProjects(false)
    }
    fetchProjects().catch(console.error)
  }, [])
  return { allProjects, loadingProjects }
}

export const usePharmacyProjects = () => {
  const { pharmacy } = usePharmacy()
  const [pharmacyProjects, setPharmacyProjects] = useState<Project[]>([])
  const [loadingPharamcyProjects, setLoadingPharamcyreProjects] = useState<boolean>(true)

  useEffect(() => {
    if (!pharmacy?.id) {
      console.warn("Pharmacy is not set. Can't get it's projects")
      return
    }
    const fetchPharmacyProjects = async () => {
      try {
        setPharmacyProjects([])
        setLoadingPharamcyreProjects(true)

        const pharmacyProjectsQuery = query(collection(db, 'pharmacy_project'), where('pharmacy_id', '==', pharmacy.id))
        const pharmacyProjectsSnapshot = await getDocs(pharmacyProjectsQuery)
        const projectIds = pharmacyProjectsSnapshot.docs?.map((doc) => (doc.data() as PharmacyProject).project_id)

        const projects = await Promise.all(projectIds.map((projectId) => getProjectWhereProjectId(projectId)))
        setPharmacyProjects(projects)
      } catch (e) {
        if (e instanceof Error) {
          console.error(e)
        }
      } finally {
        setLoadingPharamcyreProjects(false)
      }
    }
    fetchPharmacyProjects().catch()
  }, [pharmacy?.id])

  return { pharmacyProjects, loadingPharamcyProjects }
}
