import { deleteDoc, DocumentReference, getDocs, query, QuerySnapshot, where, WhereFilterOp } from 'firebase/firestore'
import { initFirebaseEntity } from '../../utils/initFirebaseEntity'
import { ActiveAppointment, ActiveAppointmentFirebaseEntry, ActiveAppointmentsWhere } from './model'

const { collection: activeAppointmentCollection, ...activeAppointment } = initFirebaseEntity<ActiveAppointmentFirebaseEntry>('active_appointment')

export const activeAppointmentWhere = activeAppointment.where

/*
 * GET
 */
const getActiveAppointment = activeAppointment.get

interface Options {
  where: ActiveAppointmentsWhere
}

function getActiveAppointments(options?: Options): Promise<QuerySnapshot<ActiveAppointmentFirebaseEntry>> {
  const whereFns = options?.where
    ? Object.entries(options.where).map(([key, whereValue]) => {
        const operation: WhereFilterOp = Array.isArray(whereValue) ? whereValue[0] : '=='
        const comparisonValue = Array.isArray(whereValue) ? whereValue[1] : whereValue

        const keyMap: [keyof ActiveAppointmentsWhere, keyof ActiveAppointmentFirebaseEntry][] = [
          ['trainerCode', 'trainer_code'],
          ['pharmacyId', 'pharmacy_id'],
        ]

        const mappedKey = keyMap.find(([keyToMap, keyMapped]) => keyToMap === key)?.[1] || key
        return where(mappedKey, operation, comparisonValue)
      })
    : []

  const firebaseQuery = query(activeAppointmentCollection, ...whereFns)
  return getDocs(firebaseQuery)
}

const getFirstActiveAppointment = async ({ where }: { where: ActiveAppointmentsWhere }) => {
  const activeAppointments = await getActiveAppointments({ where })
  return activeAppointments?.docs[0]
}

/*
 * CREATE
 */
async function createActiveAppointment(
  data: Omit<ActiveAppointment, 'presentationId' | 'active'>,
): Promise<DocumentReference<ActiveAppointmentFirebaseEntry>> {
  const dataToCreate: ActiveAppointmentFirebaseEntry = {
    active: true,
    trainer_code: data.trainerCode,
    pharmacy_id: data.pharmacyId,
    appointment_doc_id: data.appointmentDocId,
  }

  await deleteActiveAppointments({ where: { trainerCode: data.trainerCode } })
  return activeAppointment.create(dataToCreate)
}

/*
 * UPDATE
 */

const updateActiveAppointment = activeAppointment.update

/*
 * DELETE
 */
const deleteActiveAppointment = (docRef: DocumentReference<ActiveAppointmentFirebaseEntry>) => {
  return deleteDoc(docRef)
}
const deleteActiveAppointments = async (arg: { where: ActiveAppointmentsWhere } | { refs: DocumentReference<ActiveAppointmentFirebaseEntry>[] }) => {
  if ('where' in arg) {
    const appointmentsToDelete = await getActiveAppointments({ where: arg.where })
    const deletePromises = appointmentsToDelete.docs.map((appointmentDoc) => deleteActiveAppointment(appointmentDoc.ref))
    return Promise.all(deletePromises)
  }

  if ('refs' in arg) {
    const deletePromises = arg.refs.map((ref) => deleteActiveAppointment(ref))
    return Promise.all(deletePromises)
  }
}

export {
  activeAppointmentCollection,
  createActiveAppointment,
  updateActiveAppointment,
  deleteActiveAppointment,
  deleteActiveAppointments,
  getActiveAppointment,
  getFirstActiveAppointment,
  getActiveAppointments,
}
