import React, { useEffect } from 'react'

// Source: https://github.com/kentcdodds/bookshelf/blob/main/src/utils/hooks.js

type State<T> =
  | {
      status: 'idle'
      data: null
      error: null
    }
  | {
      status: 'pending'
      data: null
      error: null
    }
  | {
      status: 'rejected'
      data: null
      error: Error
    }
  | {
      status: 'resolved'
      data: T
      error: null
    }

const defaultInitialState: State<unknown> = { status: 'idle', data: null, error: null }

// Example usage:
// const {data, error, status, run} = useAsync()
// React.useEffect(() => {
//   run(fetchPokemon(pokemonName))
// }, [pokemonName, run])
function useAsync<T>(initialState: T) {
  const initialStateRef = React.useRef<State<T>>({
    ...(defaultInitialState as State<T>),
    ...initialState,
  })

  const [{ status, data, error }, setState] = React.useState(initialStateRef.current)

  const run = React.useCallback(
    (promise: Promise<T>) => {
      setState({ status: 'pending', data: null, error: null })
      return promise.then(
        (data) => {
          setState({ data, status: 'resolved', error: null })
          return data
        },
        (error: unknown) => {
          let errorToReturn = new Error('Unknown error')

          if (error instanceof Error) {
            errorToReturn = error
            errorToReturn.message = errorToReturn.message || 'Unknown error'
          }
          if (typeof error === 'string') {
            errorToReturn = new Error(error)
          }

          setState({ status: 'rejected', error: errorToReturn, data: null })
          return error
        },
      )
    },
    [setState],
  )

  const setData = React.useCallback((data) => setState((prev) => ({ ...prev, data })), [setState])
  const setError = React.useCallback((error) => setState((prev) => ({ ...prev, error })), [setState])
  const reset = React.useCallback(() => setState(initialStateRef.current), [setState])

  useEffect(() => {
    console.error(error)
  }, [error])

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  }
}

export { useAsync }
