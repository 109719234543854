import { ButtonKind } from '@aposphaere/ui-components'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import PrevNextButtons from '../../components/PrevNextButtons'
import PageTitleSection from '../../components/PageTitleSection'
import Spinner from '../../components/Spinner'
import TrainingCard from '../../components/TrainingCard'
import { CLIENT_PRESENTATION_ROUTE } from '../../routes'
import { useGetAllProjects } from '../../hooks'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { ClientPresentationLocationState } from './Presentation'
import { Project } from '../../lib/entities/project'

const ClientTrainings: React.FC = () => {
  const { pharmacy } = usePharmacy()

  const history = useHistory()
  const [selectedCard, setSelectedCard] = useState<Project | null>(null)
  const { allProjects, loadingProjects } = useGetAllProjects()

  const loading = loadingProjects

  const handleClick = () => {
    //todo
  }

  const goBack = () => {
    history.goBack()
  }

  const next = () => {
    const locationState: ClientPresentationLocationState = {
      projectId: selectedCard?.id || undefined,
    }
    history.push({ pathname: CLIENT_PRESENTATION_ROUTE, state: locationState })
  }

  const chooseTraining = (item: Project) => {
    setSelectedCard(item)
  }

  return (
    <div className="bg-white">
      {loading && <Spinner />}
      <div className="w-full min-h-screen">
        <PageTitleSection
          pageTitle="Schulung wählen"
          handleClick={handleClick}
          label={pharmacy?.name ? `${pharmacy.name} | Slot 1` : ''}
          iconType="home"
        />
        <div className="p-4 float-left pb-28 w-full bg-white">
          {allProjects?.length
            ? allProjects.map((training, index) => {
                return (
                  <TrainingCard
                    key={training.id}
                    project={training}
                    index={index}
                    selectedCard={selectedCard?.id ? Number(selectedCard?.id) : null}
                    onClick={() => chooseTraining(training)}
                  />
                )
              })
            : null}
        </div>
        <PrevNextButtons
          customStyle="absolute bg-white flex w-full justify-between align-end px-7 py-4 bottom-0 shadow-top"
          prevButton={{ onClick: goBack, kind: ButtonKind.secondary, text: 'Zurück' }}
          nextButton={{ onClick: next, kind: ButtonKind.secondaryGreen, text: 'Starten', disabled: !selectedCard }}
        />
      </div>
    </div>
  )
}

export default ClientTrainings
