import { fetchExtendedAppointments } from '../entities/appointment'
import { fetchPharmaciesOfUser } from '../entities/pharmacy'
import { getAllProjects } from '../entities/project'
import { getAllQuestions } from '../entities/question'

export async function loadDataForOfflineSupport({ userType, userId }: { userType: 'Trainer' | 'Client'; userId: number }) {
  try {
    const promises: Promise<unknown>[] = []

    if (userType === 'Client') {
      promises.push(getAllProjects(), getAllQuestions())
    }
    if (userType === 'Trainer') {
      promises.push(getAllProjects(), fetchExtendedAppointments(new Date(), userId), fetchPharmaciesOfUser(userId))
    }

    await Promise.all(promises)
  } catch (e) {
    console.error('Error: Data fetching for Offline support:', e)
  }
}
