import React from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { ENTRY_SCREEN_ROUTE, LOGIN_ROUTE } from '../../routes'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import Spinner from '../Spinner'
import { useAuth } from '../../lib/contexts/authContext'

type Rules = {
  device?: 'Trainer' | 'Client'
  online?: boolean
}
interface Props extends RouteProps {
  rules?: Rules
}

const PrivateRoute = ({ rules, ...routeProps }: Props) => {
  const { user, loading } = useAuth()
  const location = useLocation()
  const { isOnline } = useNetworkMode()

  function validateRules() {
    const ruleCheck: Record<keyof Rules, boolean | undefined> = {
      device: undefined,
      online: undefined,
    }

    if (rules && 'device' in rules) {
      ruleCheck.device = rules.device === user?.type
    }

    if (rules && 'online' in rules) {
      if (isOnline === undefined) {
        return null
      }
      ruleCheck.online = rules.online === isOnline
    }

    const isComplyingWithRules = Object.values(ruleCheck).every((isAllowed) => isAllowed === true || isAllowed === undefined)
    return isComplyingWithRules
  }

  function getTargetRouteType() {
    let targetRouteType: 'Client' | 'Trainer' | undefined = undefined
    if (location.pathname.startsWith('/client')) {
      targetRouteType = 'Client'
    }
    if (location.pathname.startsWith('/master')) {
      targetRouteType = 'Trainer'
    }
    return targetRouteType
  }

  const isComplyingWithRules = validateRules()
  const targetRouteType = getTargetRouteType()
  const routeNeedsDifferentDeviceType = targetRouteType && user?.type !== targetRouteType

  const isRouteAllowed = !routeNeedsDifferentDeviceType && user && isComplyingWithRules

  if (loading.initialSignin) {
    return <Spinner />
  }

  if (isRouteAllowed) {
    return <Route {...routeProps} />
  }

  if (targetRouteType === 'Trainer') {
    return <Redirect to={LOGIN_ROUTE} />
  }
  return <Redirect to={ENTRY_SCREEN_ROUTE} />
}

export default PrivateRoute
