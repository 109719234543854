import React, { useEffect, useState } from 'react'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { Project } from '../../lib/entities/project'
import { PreviewImage } from '../../lib/utils/storePresentations/previewImages'

export type TrainingCardProps = {
  project: Project
  onClick: () => void
  selectedCard: number | null
  index: number
}

const DEFAULT_PREVIEW_IMAGE_SRC = '/presentations/default.png'

const TrainingCard: React.FunctionComponent<TrainingCardProps> = ({ project, onClick, selectedCard, index }) => {
  const { isOnline, isOffline } = useNetworkMode()

  const [previewUrl, setPreviewUrl] = useState(DEFAULT_PREVIEW_IMAGE_SRC)

  useEffect(() => {
    const updatePreviewUrl = async () => {
      if (isOnline && project?.presentation_preview_url) {
        setPreviewUrl(project.presentation_preview_url)
        return
      }
      if (isOffline) {
        const foundImageData = await PreviewImage.getAsBase64FromFilesystem(String(project.id))
        setPreviewUrl(foundImageData || DEFAULT_PREVIEW_IMAGE_SRC)
        return
      }
    }
    updatePreviewUrl()
  }, [isOffline, isOnline, project.id, project.presentation_preview_url])

  return (
    <div className={`${selectedCard === project.id ? '' : 'border border-solid border-gray-100'} w-1/4 float-left`} data-testid={`project-${index}`}>
      <div
        className={`${
          selectedCard === project.id ? 'border-2 border-blue-600 shadow-lg bg-turquoise-50' : 'border border-gray-400'
        } mx-2.5 my-4 border-solid rounded-md cursor-pointer`}
        onClick={onClick}
      >
        <img
          className="w-full h-80 rounded-tl-md rounded-tr-md object-cover"
          src={previewUrl}
          alt={`Logo of ${project.name}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = '/presentations/default.png'
          }}
        />
        <div className="p-5">
          <h2 className="m-0 text-blue-700 font-medium text-lg pb-7">{project.name}</h2>
          <p className="text-blue-700 font-normal text-base2">
            Status:
            <span className={`${project.active ? 'bg-green-750' : 'bg-gray-400'} w-5 h-5 rounded-full align-middle inline-block ml-4`}></span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default TrainingCard
