import { gql } from 'graphql-request'

export const userOfftimes = gql`
  query userOfftimes($pharmacyClusterId: ID, $date: DateRange) {
    offtimes(pharmacy_cluster_id: $pharmacyClusterId, date: $date) {
      id
      date
      whole_day
      offtime_type
    }
  }
`
