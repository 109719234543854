import { Dialog } from '@capacitor/dialog'
import { Button, ButtonKind } from '@aposphaere/ui-components'
import React, { useEffect, useState } from 'react'
import { AppLauncher } from '@capacitor/app-launcher'
import { useHistory } from 'react-router'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { ATTENDEES_ROUTE, MASTER_TRAININGS_ROUTE } from '../../routes'
import SlotItem from '../SlotItem'
import { activeAppointmentCollection } from '../../lib/entities/activeAppointment'
import { deleteIpads } from '../../lib/entities/iPad'
import { ExtendedAppointment } from '../../lib/entities/appointment'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { getTimeFromFirestoreTimestamp } from '../../lib/utils/convertTime'
import { useAuth } from '../../lib/contexts/authContext'

export type AppointmentCardProps = {
  appointment: ExtendedAppointment
  index: number
}

const AppointmentCard: React.FunctionComponent<AppointmentCardProps> = ({ appointment, index }) => {
  const history = useHistory()
  const [showSlots, setShowSlots] = useState<boolean>(false)
  const [projects, setProjects] = useState<string>('')
  const auth = useAuth()
  const trainerCode = auth?.user?.entry?.trainer_code
  const { isOffline } = useNetworkMode()
  const { setActiveAppointmentId } = useActiveAppointment()

  const from = appointment?.date ? getTimeFromFirestoreTimestamp(appointment?.date as Timestamp) : ''
  const till = appointment?.enddate ? getTimeFromFirestoreTimestamp(appointment?.enddate as Timestamp) : ''

  useEffect(() => {
    if (appointment?.projects && !!appointment?.projects?.length) {
      const projectsString = appointment.projects.map((item) => item.name).join(' , ')
      setProjects(projectsString)
    }
  }, [appointment])

  const handleMoveToNextPage = async () => {
    const pharmacyId = appointment?.pharmacy?.id
    const trainerCode = auth?.user?.entry?.trainer_code
    const appointmentDocId = appointment.docId

    if (pharmacyId && trainerCode && appointmentDocId) {
      const newActiveAppointmentDocRef = doc(activeAppointmentCollection)
      setDoc(newActiveAppointmentDocRef, {
        active: true,
        pharmacy_id: pharmacyId,
        trainer_code: trainerCode,
        appointment_doc_id: appointmentDocId,
      })

      setActiveAppointmentId(newActiveAppointmentDocRef.id)
      history.push(isOffline ? MASTER_TRAININGS_ROUTE : ATTENDEES_ROUTE)
    }
  }

  const onSlotSelectorBtnClick = async () => {
    await deleteIpads({ where: { trainer_code: trainerCode } })
    const hasMultipleProjects = appointment?.projects && appointment?.projects?.length > 1

    hasMultipleProjects ? setShowSlots(!showSlots) : await handleMoveToNextPage()
  }

  const onSlotClick = async () => {
    //todo check again, is needed to store also slotId
    await handleMoveToNextPage()
  }

  const openExternalApp = async () => {
    const pharmacyID = appointment.pharmacy?.id
    const { value } = await AppLauncher.canOpenUrl({ url: 'qmrtool://?startupdata=' + pharmacyID })
    if (value === true) {
      await AppLauncher.openUrl({ url: 'qmrtool://?startupdata=' + pharmacyID })
    } else {
      await Dialog.alert({
        title: 'Fehler',
        message: 'Die App scheint nicht auf ihrem Gerät installiert zu sein, bitte kontaktieren sie den Support.',
      })
    }
  }

  const haveMultipleAppointmentProjects = appointment?.projects && appointment.projects.length > 1

  return (
    <div
      className="flex flex-row justify-start w-full bg-white overflow-hidden rounded-md border border-solid border-gray-400 mb-4 font-body text-blue-700"
      data-testid="appointment-card"
    >
      <div className="w-28 py-8 text-center text-lg font-medium">
        {from && till && (
          <>
            <span>{from}</span>
            <hr className="w-4 h-0 mx-auto my-3 border border-blue-700" />
            <span>{till}</span>
          </>
        )}
      </div>
      <div className="w-full py-5 pr-5">
        <div className="bg-white py-2 border-b border-gray-400">
          <div className="flex w-full justify-between font-medium text-lg">
            <p className="font-medium" data-testid="appointment-pharmacy-name">
              {appointment?.pharmacy?.name || ''}
            </p>
            {appointment?.id && <span>PIN {appointment?.id}</span>}
          </div>
          <div className="flex w-full justify-between font-normal text-lg">
            <p data-testid="address">
              {appointment?.pharmacy?.address !== undefined
                ? appointment?.pharmacy?.address?.address_name + ', ' + appointment?.pharmacy?.address?.city || ''
                : null}
            </p>
            {appointment.pharmacy?.phone || ('' && <span className="font-medium">Tel.: {appointment.pharmacy?.phone || ''}</span>)}
          </div>
        </div>
        <div className={`bg-white py-2 flex justify-between`}>
          <p className="font-normal text-lg">{projects}</p>
          <div className="flex flex-row justify-between">
            <div>
              <Button customStyle="mr-3" kind={ButtonKind.outlinedSecondary} onClick={openExternalApp}>
                {'APOSurvey'}
              </Button>
            </div>
            <div data-testid={`termin-auswahlen-${index}`}>
              <Button kind={ButtonKind.outlinedPrimary} onClick={onSlotSelectorBtnClick}>
                {'Termin auswählen'}
              </Button>
            </div>
          </div>
        </div>
        {haveMultipleAppointmentProjects && showSlots && (
          <div className="bg-white pt-5">
            <h2 className="font-medium text-xs text-gray-600 m-0">TERMIN AUSWÄHLEN:</h2>
            {appointment?.projects?.map((project, i) => {
              return <SlotItem key={i} project={project} index={i} onClick={onSlotClick} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default AppointmentCard
