import { TabBar, TabBarItem, TabBarItemKind } from '@aposphaere/ui-components'
import React, { useEffect, useState } from 'react'
import { StatusBar } from '@capacitor/status-bar'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import NavBar from '../components/NavBar'
import { useDeviceInfo } from '../hooks/useDeviceInfo'
import SettingsButton from '../components/SettingsButton'
import SettingsModal from '../components/SettingsModal'
import SignOutButton from '../components/SignOutButton'
import { ATTENDEES_ROUTE, CUSTOMERS_ROUTE, LOGIN_ROUTE, MASTER_PRESENTATION_ROUTE, STATISTICS_ROUTE, MASTER_TRAININGS_ROUTE } from '../routes'
import { useAuth } from '../lib/contexts/authContext'

const MainLayout: React.FC<RouteComponentProps> = ({ children, location }) => {
  const history = useHistory()
  const { user } = useAuth()
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false)
  const isStatisticsScreen = location.pathname === STATISTICS_ROUTE
  const isMaster = user?.type === 'Trainer'
  const deviceInfo = useDeviceInfo()

  useEffect(() => {
    if (!isMaster && deviceInfo?.platform === 'ios') {
      StatusBar.hide().catch((error) => {
        console.error('An error while hiding the statusbar occurred', error)
      })
    } else if (deviceInfo?.platform === 'ios') {
      StatusBar.show().catch((error) => {
        console.error('An error while showing the statusbar occured', error)
      })
    }
  }, [deviceInfo?.platform, isMaster])
  const show = location.pathname.includes('/master') && location.pathname !== LOGIN_ROUTE && location.pathname !== MASTER_PRESENTATION_ROUTE
  return (
    <>
      {show ? (
        <div className="z-50 bg-gray-100 font-body h-full overflow-y-auto">
          <>
            <NavBar logoUrl="/logo_master_white.png" logoUrlSmall="/logo_master_white.png" logoAlt="Presenter App Logo">
              <div>
                <TabBar>
                  <TabBarItem
                    kind={TabBarItemKind.primary}
                    selected={location.pathname === CUSTOMERS_ROUTE}
                    disabled={isStatisticsScreen}
                    onClick={() => history.push(CUSTOMERS_ROUTE)}
                  >
                    <span className="px-2.5 text-lg">{'Kunden'}</span>
                  </TabBarItem>
                  <TabBarItem
                    kind={TabBarItemKind.primary}
                    selected={location.pathname === ATTENDEES_ROUTE}
                    disabled={true}
                    onClick={() => history.push(ATTENDEES_ROUTE)}
                  >
                    <span className="px-2.5 text-lg">{'Teilnehmer'}</span>
                  </TabBarItem>
                  <TabBarItem
                    kind={TabBarItemKind.primary}
                    selected={location.pathname === MASTER_TRAININGS_ROUTE}
                    disabled={true}
                    onClick={() => history.push(MASTER_TRAININGS_ROUTE)}
                  >
                    <span className="px-2.5 text-lg">{'Schulung'}</span>
                  </TabBarItem>
                  <TabBarItem
                    kind={TabBarItemKind.primary}
                    selected={location.pathname === STATISTICS_ROUTE}
                    disabled={true}
                    onClick={() => history.push(STATISTICS_ROUTE)}
                  >
                    <span className="px-2.5 text-lg">{'Statistik'}</span>
                  </TabBarItem>
                </TabBar>
              </div>

              <div className="flex items-center">
                <div className="flex items-center text-white">
                  <SettingsButton onClick={() => setShowSettingsModal(!showSettingsModal)} />
                </div>
                {!isStatisticsScreen && (
                  <>
                    <div className="border-l border-white border-solid w-px h-10 ml-" />
                    <div className="flex items-center text-white" data-testid="logout">
                      <SignOutButton />
                    </div>
                  </>
                )}
              </div>
            </NavBar>
          </>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
      {showSettingsModal && <SettingsModal closeModal={() => setShowSettingsModal(false)} />}
    </>
  )
}

export default withRouter(MainLayout)
