import { useCallback } from 'react'
import { IAppContext } from '../lib/contexts/appContext'
import { useLocalStorage } from './useLocalStorage'

export const useAppProvider = (): IAppContext => {
  const [usersNumber, setUsersNumber] = useLocalStorage('usersNumber', 0)
  const [addedUsers, setAddedUsers] = useLocalStorage('addedUsers', 0)

  const cleanLocalStorage = useCallback(() => {
    setUsersNumber(0)
    setAddedUsers(0)
  }, [setAddedUsers, setUsersNumber])

  return {
    intendedUserCount: usersNumber,
    setIntendedUSerCount: setUsersNumber,
    addedUsers,
    setAddedUsers,
    cleanLocalStorage,
  }
}
