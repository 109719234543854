import React from "react";
import { Button, ButtonKind } from "@aposphaere/ui-components";


interface ButtonProps {
  onClick: () => void
  disabled?: boolean
  kind?: ButtonKind
  text: string
}
interface PrevNextButtonsProps {
  prevButton?: ButtonProps,
  nextButton?: ButtonProps,
  cancelButton?: ButtonProps,
  customStyle?: string
}

const PrevNextButtons = ({prevButton, nextButton, cancelButton, customStyle}: PrevNextButtonsProps) => {
    return(
      <div className={`${customStyle ? customStyle : "absolute flex w-full justify-between align-end px-7 py-4 bottom-0"}`}>
        {prevButton && <Button disabled={prevButton.disabled} kind={prevButton.kind || ButtonKind.primary} onClick={prevButton.onClick}>
          {prevButton.text}
        </Button>}          
        <div className="flex ml-auto">
          {cancelButton && <Button kind={cancelButton.kind || ButtonKind.outlinedDanger} disabled={cancelButton.disabled} onClick={cancelButton.onClick}>
            {cancelButton.text}
          </Button>}
          {nextButton && (
          <div className="ml-5">
          <Button disabled={nextButton.disabled} kind={nextButton.kind || ButtonKind.primary} onClick={nextButton.onClick}>
            {nextButton.text}
          </Button>
          </div>

          )}
        </div>
      </div>
    )
}

export default PrevNextButtons