import React, { useState } from 'react'
import { Modal, ModalKind } from '@aposphaere/ui-components'
import { useAuth } from '../../lib/contexts/authContext'
import { useDeviceId } from '../../hooks/useDeviceId'
import CopyButton from '../CopyButton'

interface InjectedProps {
  openModal?: () => void
}

interface Props {
  children(props: InjectedProps): React.ReactNode
}

export const DeviceIdModal = ({ children }: Props) => {
  const auth = useAuth()
  const deviceId = useDeviceId()
  const [show, setShowModal] = useState(false)

  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  return (
    <>
      {children({ openModal })}
      {show && (
        <Modal kind={ModalKind.ms} noPadding title={'Informationen'} onClose={closeModal} onBack={closeModal}>
          <div className="flex flex-col justify-center w-full p-8 text-gray-900">
            <span className={`flex text-xl mx-6 mt-6 ${auth.deviceName ? 'mb-0' : 'mb-6'}`}>
              <span className="font-medium inline-block mr-5">Device ID: </span>
              <span>{deviceId}</span>
              <CopyButton textToCopy={deviceId} />
            </span>
            {auth.deviceName ? (
              <span className="text-xl mx-6 mb-6">
                <span className="font-medium inline-block mr-5">Device Name: </span>
                <span>{auth.deviceName}</span>
              </span>
            ) : null}
          </div>
        </Modal>
      )}
    </>
  )
}

export default DeviceIdModal
