import { doc, DocumentSnapshot } from 'firebase/firestore'
import React, { createContext, useCallback, useMemo } from 'react'
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect } from 'react'
import { usePrevious } from '../../hooks/usePrevious'
import { useDocumentSubscription } from '../../hooks/useDocumentSubscription'
import { activeAppointmentCollection, ActiveAppointmentFirebaseEntry, deleteActiveAppointment } from '../entities/activeAppointment'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useAuth } from './authContext'
import { SubscriptionStatus } from '../models/subscriptionState'

/*
 * CONTEXT
 */
interface ActiveAppointmentContext {
  activeAppointmentId: string | null
  setActiveAppointmentId: Dispatch<SetStateAction<string | null>>
  removeActiveAppointment: () => void
  activeAppointmentSnapshot: DocumentSnapshot<ActiveAppointmentFirebaseEntry> | null
  activeAppointment: ActiveAppointmentFirebaseEntry | null
  isLoading: boolean
  status: SubscriptionStatus
}
const Context = createContext<ActiveAppointmentContext | null>(null)
Context.displayName = 'ActiveAppointmentContext'

/*
 * PROVIDER
 */
export const ActiveAppointmentProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()

  const [activeAppointmentId, setActiveAppointmentId] = useLocalStorage<string | null>('active_appointment_id', null)
  const previousActiveAppointmentId = usePrevious(activeAppointmentId)

  const activeAppointmentRef = useMemo(() => (activeAppointmentId ? doc(activeAppointmentCollection, activeAppointmentId) : null), [
    activeAppointmentId,
  ])

  const { data: activeAppointmentSnapshot, isLoading, status } = useDocumentSubscription(activeAppointmentRef)

  const isMaster = user?.type === 'Trainer' && user?.entry?.trainer_code

  const removeActiveAppointment = useCallback(() => {
    setActiveAppointmentId(null)
  }, [])

  useEffect(() => {
    const hasPreviousAppointment = previousActiveAppointmentId
    const isPreviousDifferent = previousActiveAppointmentId !== activeAppointmentId

    if (hasPreviousAppointment && isPreviousDifferent && isMaster) {
      deleteActiveAppointment(doc(activeAppointmentCollection, previousActiveAppointmentId)).catch((e) => {
        console.error('Could not delete the previous activeApopintment')
        console.error(e)
      })
    }
  }, [activeAppointmentId, previousActiveAppointmentId, isMaster])

  return (
    <Context.Provider
      value={{
        activeAppointmentId,
        activeAppointmentSnapshot,
        activeAppointment: activeAppointmentSnapshot?.data() || null,
        isLoading,
        setActiveAppointmentId,
        removeActiveAppointment,
        status,
      }}
    >
      {children}
    </Context.Provider>
  )
}

/*
 * HOOKS
 */
export const useActiveAppointment = () => {
  const context = useContext(Context)

  if (!context) {
    throw new Error("useActiveAppointment can't be used without it's Provider")
  }
  return context
}
