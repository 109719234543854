import React, { FunctionComponent, useMemo, useCallback } from 'react'

export enum OfftimeKind {
  HomeOffice = 'Home Office (blau)',
  Urlaub = 'Urlaub (grün)',
  Privat = 'Privat (grau)',
  Tagung = 'Tagung (gelb)',
  Krank = 'Krank (rot)',
  Unbesetzt = 'Unbesetzt (lila)',
}

export enum OfftimeColorCode {
  HomeOffice = 'bg-blue-700',
  Urlaub = 'bg-green-900 ',
  Privat = 'bg-gray-600',
  Tagung = 'bg-yellow-900',
  Krank = 'bg-red-800',
  Unbesetzt = 'bg-purple-700',
}

export type OfftimeLabelProps = {
  kind: OfftimeKind
  offtimeType?: OfftimeKind
  labelInfo: string
  onClick: (kind: OfftimeKind | undefined) => void
  disabled?: boolean
  isUserCallCenter?: boolean
}

const OfftimeLabel: FunctionComponent<OfftimeLabelProps> = ({ disabled, kind, labelInfo, onClick, offtimeType }) => {
  const handleOnClick = useCallback(() => {
    if (kind === offtimeType) {
      onClick(undefined)
    } else {
      onClick(kind)
    }
  }, [kind, offtimeType, onClick])

  const generateVacationKeyKind = useMemo((): OfftimeColorCode => {
    switch (kind) {
      case OfftimeKind.HomeOffice:
        return OfftimeColorCode.HomeOffice
      case OfftimeKind.Privat:
        return OfftimeColorCode.Privat
      case OfftimeKind.Tagung:
        return OfftimeColorCode.Tagung
      case OfftimeKind.Urlaub:
        return OfftimeColorCode.Urlaub
      case OfftimeKind.Krank:
        return OfftimeColorCode.Krank
      case OfftimeKind.Unbesetzt:
        return OfftimeColorCode.Unbesetzt
      default:
        return undefined
    }
  }, [kind])
  const selected = offtimeType === kind
  return (
    <div
      onClick={() => handleOnClick()}
      className={`flex whitespace-no-wrap justify-center text-white capitalize items-center text-center font-body text-sm 2xl:text-base cursor-pointer w-18pc border-solid m-1 rounded py-0.5 transition duration-300 ease-in-out hover:shadow-md hover:border hover:border-black ${generateVacationKeyKind} ${
        selected ? 'border-2 border-black shadow-md py-1.5' : ''
      }`}
    >
      {labelInfo}
    </div>
  )
}

export default OfftimeLabel
