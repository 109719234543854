import { ApoClient } from '@aposphaere/core-kit'
import { useCallback, useEffect, useState } from 'react'
import { AuthLoading, DeviceUser, IAuthContext } from '../lib/contexts/authContext'
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth'
import { getUser } from '../lib/entities/user'
import { signInMainIpad } from '../lib/mutations/signIn'
import { useLocalStorage } from './useLocalStorage'
import { useDeviceId } from './useDeviceId'
import { loadDataForOfflineSupport } from '../lib/utils/loadDataForOfflineSupport'

const USER_LOCAL_STORAGE_KEY = 'user'
const DEVICE_NAME_LOCAL_STORAGE_KEY = 'device-name'

const auth = getAuth()

export type MainLoginResponse = {
  firebaseMainSignin: {
    // the uid of the user
    uid: number
    // the firebase token
    token: string
    // the device name set in the BE
    device_name: string
  }
}

export type ClientLoginResponse = {
  firebaseSignin: {
    // the uid of the user
    id: number
    // the firebase token
    token: string
    // the device name set in the BE
    device_name: string
  }
}

export enum SignInErrorType {
  DeviceIdDoesNotMatch = 'Das Gerät ist derzeit nicht bekannt. Eine Eintragnung über den Administrator ist notwendig.',
  BadCredentials = 'Falscher Benutzername oder Passwort',
  Unknown = 'Ein Fehler ist passiert',
}

const initialLoadingState: AuthLoading = {
  initialSignin: true,
  manualSignin: false,
}

export const useAuthProvider = (): IAuthContext => {
  const [user, setUser] = useLocalStorage<DeviceUser | null>(USER_LOCAL_STORAGE_KEY, null)
  const [deviceName, setDeviceName] = useLocalStorage(DEVICE_NAME_LOCAL_STORAGE_KEY, '')
  const [loading, setLoading] = useState<AuthLoading>(initialLoadingState)

  const deviceId = useDeviceId()

  const logout = useCallback(() => {
    signOut(auth).then(() => {
      setUser(null)
    })
  }, [setUser])

  useEffect(() => {
    if (!deviceId) {
      return
    }
    if (loading.initialSignin) {
      const handleAutomaticLogin = async () => {
        try {
          if (!user) {
            logout()
            setLoading({ initialSignin: false, manualSignin: false })
            return
          }

          // if we have token for the user, try to login, verifying the token, and the user
          const customToken = user.customToken
          if (customToken) {
            const firebaseUserCredentials = await signInWithCustomToken(auth, customToken)

            const userSnapshot = await getUser(firebaseUserCredentials.user.uid)
            const userEntry = userSnapshot?.data?.()
            if (!userEntry) {
              console.warn('User does not exist')
              return false
            }

            setUser({ type: user?.type, account: firebaseUserCredentials?.user, entry: userEntry, customToken: customToken })
          }
        } catch (e) {
          console.warn('Error happened. Logging out')
          logout()
        } finally {
          setLoading((prevState) => ({ ...prevState, initialSignin: false }))
        }
      }
      handleAutomaticLogin()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading.initialSignin, logout, setUser, deviceId])

  const signIn = useCallback(
    async (email: string, password: string, deviceId: string, userType: 'Trainer' | 'Client') => {
      const client = new ApoClient()
      try {
        setLoading((prevState) => ({ ...prevState, manualSignin: true }))
        // First we need to verify at the CRM if our credentials match
        // if so we get a firebase token returned
        const crmLoginResponse = await client.request<MainLoginResponse>(signInMainIpad, { email, password, deviceId })
        const deviceName = crmLoginResponse?.data?.firebaseMainSignin?.device_name
        if (!deviceName) {
          return false
        }
        setDeviceName(deviceName)

        // We've got either no result or an error back
        if (!crmLoginResponse?.data || crmLoginResponse.errors !== undefined) {
          return false
        }

        // Signing in into Firebase with the given token
        const customToken = crmLoginResponse?.data.firebaseMainSignin.token
        const firebaseUserCredentials = await signInWithCustomToken(auth, customToken)
        const userId = firebaseUserCredentials.user?.uid
        // If that worked we're reading the user from our Firestore
        const userSnapshot = await getUser(userId)
        const user = userSnapshot?.data?.()

        // Consistency is king. Therefore, let's check for it.
        if (!user || user.trainer_code === undefined) {
          console.warn('User does not exist')
          return false
        }
        setUser({ type: userType, entry: user, account: firebaseUserCredentials?.user, customToken: customToken })
        await loadDataForOfflineSupport({ userType: userType, userId: Number(firebaseUserCredentials.user.uid) })
        return true
      } catch (crmLoginError) {
        if (typeof crmLoginError === 'string') {
          if (crmLoginError.includes('Device ID')) {
            throw new Error(SignInErrorType.DeviceIdDoesNotMatch)
          }
          if (crmLoginError.includes('password')) {
            throw new Error(SignInErrorType.BadCredentials)
          }
          throw new Error(SignInErrorType.Unknown)
        }
        console.error(crmLoginError)
        return false
      } finally {
        setLoading((prevState) => ({ ...prevState, manualSignin: false }))
      }
    },
    [deviceId, setDeviceName, setUser],
  )

  const signInMain = useCallback(async (email: string, password: string, deviceId: string) => signIn(email, password, deviceId, 'Trainer'), [signIn])

  const signInClient = useCallback(async (email: string, password: string, deviceId: string) => signIn(email, password, deviceId, 'Client'), [signIn])

  return {
    user,
    loading,
    signInMain,
    deviceName,
    signInClient,
    logout,
  }
}
