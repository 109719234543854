import { Timestamp } from 'firebase/firestore'

const format = (number: number) => {
  return String(number).padStart(2, '0')
}

export const convertSecondsToHHMMFormat = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  return `${format(hours)}:${format(minutes)}`
}

export const getTimeFromTimestamps = (startTime: number, endTime: number) => {
  const difference = Math.abs(endTime - startTime)
  const resultInMinutes = Math.round(difference / 60000)
  return toHoursAndMinutes(resultInMinutes)
}

const toHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
}

export const getTimeFromFirestoreTimestamp = (timestamp: Timestamp) => {
  let date = timestamp.toDate()
  const hoursAndMinutes = padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes())
  return hoursAndMinutes
}

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0')
}
