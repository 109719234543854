import React from 'react'

const NotFound: React.FC = () => {
  return (
    <div className="w-full h-screen flex flex-wrap overflow-hidden bg-orange-100">
      <div className="bg-white h-64 w-110 transform -rotate-3 -mt-32 px-64 -ml-3" />
      <div className="absolute ml-16 mt-16">
        {/* todo replace with presenter-app logo */}
        <img className="h-11 w-auto" src="/logo_master.png" alt="Aposphäre Presenter App" />
      </div>
      <div className="flex absolute w-full h-full bg-no-repeat bg-center flex-col justify-center items-center">
        <h1 className="text-blue-700 text-2xl font-semibold text-center w-full mb-8 mt-0">{'404'}</h1>
        <h2 className="text-blue-700 text-3xl font-semibold text-center w-full mb-8 mt-0">{'Seite wurde nicht gefunden!'}</h2>
      </div>
      <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-700 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
    </div>
  )
}

export default NotFound
