import { onSnapshot, Query, QuerySnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { SubscriptionState, SubscriptionStatus } from '../lib/models/subscriptionState'

export function useQuerySubscription<T>(query: Query<T> | null) {
  const [state, setState] = useState<SubscriptionState<QuerySnapshot<T>>>({ status: SubscriptionStatus.Pending })

  const status = state.status
  const data = 'data' in state ? state.data : null
  const error = 'error' in state ? state.error : null

  useEffect(() => {
    if (!query) {
      setState({ status: SubscriptionStatus.Idle })
    }
    if (query && status === SubscriptionStatus.Idle) {
      setState({
        status: SubscriptionStatus.Pending,
      })
    }
  }, [query, status])

  // Set the connection
  useEffect(() => {
    if (!query) {
      return
    }

    const unsubscribe = onSnapshot(query, {
      next: (snapshot) => {
        setState({ data: snapshot || null, status: SubscriptionStatus.Resolved })
      },
      error: (error) => {
        setState({ status: SubscriptionStatus.Error, error: error })
      },
    })

    return () => {
      unsubscribe?.()
    }
  }, [query, status])

  useEffect(() => {
    console.error(error)
  }, [error])

  return { status, data, isLoading: status === 'pending', error }
}
