import { gql } from 'graphql-request'

export const getFinishedPresentationsForAppointment = gql`
  query getFinishedPresentationsForAppointment($appointmentId: ID!) {
    finishedPresentationsForAppointment(appointment_id: $appointmentId) {
      id
      endtime
      starttime
      number_of_participants
      time_spent
      adjusted_number_of_participants
      project {
        name
        id
      }
    }
  }
`
export const getFinishedPresentationsPerCluster = gql`
  query getFinishedPresentationsPerCluster($pharmacyClusterId: ID!, $date: DateRange!) {
    finishedPresentationsPerCluster(pharmacy_cluster_id: $pharmacyClusterId, date: $date) {
      id
      starttime
      number_of_participants
      device_count
      project {
        name
        id
      }
      pharmacy {
        name
        id
        address {
          address_name
          zipcode
          city
        }
      }
      appointment {
        contact_person
        note
      }
      trainer {
        name
      }
      erstschulung
      time_spent
      adjusted_number_of_participants
    }
  }
`
export const getFinishedPresentationsPerTrainer = gql`
  query getFinishedPresentationsPerTrainer($trainer_id: ID!, $date: DateRange!) {
    finishedPresentationsPerTrainer(trainer_id: $trainer_id, date: $date) {
      id
      starttime
      number_of_participants
      device_count
      project {
        name
        id
      }
      pharmacy {
        name
        id
        address {
          address_name
          zipcode
          city
        }
      }
      appointment {
        contact_person
        note
      }
      trainer {
        name
      }
      erstschulung
      time_spent
      adjusted_number_of_participants
    }
  }
`
