import React, { HTMLProps, useEffect, useState, useRef } from 'react'
import { IncomingPresentationPostMessage, sendPresentationPostMessage } from '../../lib/models/presentationPostMessage'
import { usePresentation } from '../../lib/contexts/presentationContext'

const INITIAL_SLIDE = 'slide_0'

export type PresentationMessageHandlers = {
  onSlideChange?: (slide: string) => void
  onTimeMessage?: (totalTime?: number | undefined, minTime?: number | undefined) => void
  onFinished?: ({ endTime, presentationTimeInSeconds }: { endTime: number; presentationTimeInSeconds: number }) => void
  onVideoSrc?: (videoSrc: string) => void
  onClickPopUp?: (clickId: string) => void
}

interface Props extends HTMLProps<HTMLIFrameElement> {
  src: string
  slide?: string
  controllable: boolean
  /**
   * Important: The handlers have to be memoized
   */
  messageHandlers?: PresentationMessageHandlers
}

const PresentationIframe = ({ controllable, slide = INITIAL_SLIDE, src, messageHandlers, ...iframeProps }: Props) => {
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const { presentation } = usePresentation()
  const clickedPopUp = presentation?.clicked_pop_up

  useEffect(() => {
    const iframe = iframeRef.current
    if (slide && iframe && iframeLoaded) {
      sendPresentationPostMessage(iframe, { type: 'goPage', command: { viewid: slide } })
    }
  }, [iframeLoaded, slide])

  useEffect(() => {
    const iframe = iframeRef.current
    if (clickedPopUp && iframe && iframeLoaded) {
      sendPresentationPostMessage(iframe, { type: 'goPage', command: { viewid: 'none', command: { clickevent: clickedPopUp } } })
    }
  }, [clickedPopUp, iframeLoaded])

  useEffect(() => {
    setIframeLoaded(false)
  }, [src])

  // Listen to messages from the iframe
  useEffect(() => {
    const messageHandler = async (event: MessageEvent<IncomingPresentationPostMessage>) => {
      const message = event.data

      if (message.type === 'time') {
        messageHandlers?.onTimeMessage?.(message?.totalTime, message?.minTime)
      }

      if (message.type === 'goPage' && message.command.viewid !== 'none') {
        messageHandlers?.onSlideChange?.(message.command.viewid)
      }

      if (message.type === 'finished') {
        const bookedTimeInSeconds = message?.totalTime
        const presentationTimeInSeconds = bookedTimeInSeconds - message?.startTime + message?.overtime
        const currentDate = new Date()
        const endTime = currentDate.getTime()

        messageHandlers?.onFinished?.({ endTime, presentationTimeInSeconds })
      }
      if (message.type === 'videoSrc') {
        messageHandlers?.onVideoSrc?.(message.command.src)
      }
      if (message.type === 'goPage' && message.command.viewid === 'none') {
        messageHandlers?.onClickPopUp?.(message.command.command.clickevent)
      }
    }

    window.addEventListener('message', messageHandler)

    return () => window.removeEventListener('message', messageHandler)
  }, [messageHandlers])

  // Make iframe interactable
  useEffect(() => {
    if (iframeLoaded && controllable) {
      const makeIframeControllable = () => {
        const iframe = iframeRef.current

        if (iframe?.contentWindow) {
          sendPresentationPostMessage(iframe, { type: 'showButton' })
        }
      }
      makeIframeControllable()
    }
  }, [iframeLoaded, controllable, src])

  return (
    <>
      <iframe
        ref={iframeRef}
        title="Presentation-iframe"
        data-testid="presentation-iframe"
        {...iframeProps}
        onLoad={() => setIframeLoaded(true)}
        src={src}
      />
    </>
  )
}

export default PresentationIframe
