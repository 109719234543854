import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'
import { useAddIpad } from '../../hooks/entities/useIpads'
import { useDeviceId } from '../../hooks/useDeviceId'
import { USERS_NUMBER_ROUTE } from '../../routes'
import { activeAppointmentCollection, activeAppointmentWhere } from '../../lib/entities/activeAppointment'
import { useAuth } from '../../lib/contexts/authContext'
import { useAppContext } from '../../lib/contexts/appContext'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import { useFirstQuerySubscription } from '../../hooks/useFirstQuerySubscription'
import { query } from 'firebase/firestore'
import Header from '../../components/Header'
import { IconButton, IconButtonKind, LogoutIcon } from '@aposphaere/ui-components'

const Home: React.FC = () => {
  const uuid = useDeviceId()
  const { deviceName } = useAuth()
  const { setAddedUsers, setIntendedUSerCount: setUsersNumber } = useAppContext()
  const [addIpad] = useAddIpad()
  const history = useHistory()
  const { activeAppointment, setActiveAppointmentId } = useActiveAppointment()
  const { isOnline } = useNetworkMode()
  const { user, logout } = useAuth()

  useEffect(() => {
    if (user?.entry?.trainer_code && isOnline === false) {
      const handleOfflineMode = async () => {
        addIpad({
          device_id: uuid,
          device_name: deviceName ? deviceName : uuid,
          trainer_code: user?.entry.trainer_code,
          connected: false,
          pta_ids: [],
          users: [],
        })
        history.push(USERS_NUMBER_ROUTE)
      }
      handleOfflineMode()
    }
  }, [isOnline, history, user?.entry.trainer_code, addIpad, uuid, deviceName])

  const activeAppointmentQuery = useMemo(
    () =>
      user?.entry?.trainer_code
        ? query(
            activeAppointmentCollection,
            activeAppointmentWhere('active', '==', true),
            activeAppointmentWhere('trainer_code', '==', user.entry.trainer_code),
          )
        : null,
    [user?.entry?.trainer_code],
  )

  const { data: foundActiveAppointment } = useFirstQuerySubscription(activeAppointmentQuery)

  // Init
  useEffect(() => {
    setAddedUsers(0)
    setUsersNumber(0)
    setActiveAppointmentId(null)
  }, [setActiveAppointmentId, setAddedUsers, setUsersNumber])

  useEffect(() => {
    if (foundActiveAppointment?.id) {
      setActiveAppointmentId(foundActiveAppointment.id)
    }
  }, [foundActiveAppointment?.id, setActiveAppointmentId])

  useEffect(() => {
    const handleAppointmentStart = async () => {
      if (!user?.entry.trainer_code || !uuid || !deviceName || !activeAppointment) {
        return
      }
      addIpad({
        device_id: uuid,
        device_name: deviceName ? deviceName : uuid,
        trainer_code: user?.entry.trainer_code,
        connected: false,
        pta_ids: [],
        users: [],
      })
      history.push(USERS_NUMBER_ROUTE)
    }

    handleAppointmentStart()
  }, [activeAppointment, addIpad, deviceName, history, isOnline, user?.entry.trainer_code, uuid])

  return (
    <div className="absolute w-full h-screen flex flex-wrap overflow-hidden bg-orange-100 font-body">
      <Header>
        <IconButton
          kind={IconButtonKind.custom}
          icon={<LogoutIcon />}
          additionalCss="mr-10 mt-5 text-blue-600 active:text-white hover:text-white focus:outline-none hover:text-blue-700 rounded active:bg-blue-800 transition ease-in-out duration-150 z-10"
          onClick={logout}
        />
      </Header>
      <div className="flex absolute w-full h-full bg-no-repeat bg-center flex-col justify-center items-center">
        <img className="absolute right-0 mt-44 -mr-44 animate-spin-slow top-0" src="/login-rotate-1.svg" alt="" />
        <img className="absolute bottom-28 animate-wiggle -ml-24 left-0" src="/login-rotate-2.svg" alt="" />
        <img className="absolute right-0 mt-32 -mr-96 top-0" src="/assets/icons/bg-login.svg" alt="" />
        <img src="/assets/icons/icon-wait.svg" alt="" className="mb-12 mx-auto" />

        <h1 className="text-blue-600 text-xl2 font-medium text-center w-full">{'Warten auf den Beginn des Termins'}</h1>
      </div>
      <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-400 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
    </div>
  )
}

export default Home
