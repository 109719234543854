import React from 'react'
import Loader from '../../Icons/loader.gif'

type SpinnerProps = {
  message?: string
  fixed?: boolean
}

const Spinner = ({ message, fixed = true }: SpinnerProps) => (
  <div className={`${fixed ? 'h-screen w-screen z-9999 top-0 left-0 fixed' : 'h-full w-full'} bg-white flex items-center justify-center `}>
    <div className="flex items-center justify-center flex-wrap max-w-48">
      <img className={`${fixed && '-mt-16'} flex w-24`} src={Loader} alt="" />
      <br />
      <div className="w-full text-center mx-auto flex justify-center font-body mt-4 text-base text-blue-400 leading-5">
        {message ? message : 'Daten werden geladen.'}
      </div>
    </div>
  </div>
)

export default Spinner
