import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useEndPresentation, updatePresentationConnectedIpads, useSetPresentationTimes } from '../../hooks/entities/usePresentation'
import Spinner from '../../components/Spinner'
import { usePresentation } from '../../lib/contexts/presentationContext'
import { IncomingPresentationPostMessage } from '../../lib/models/presentationPostMessage'
import { updatePresentationCurrentSlide, updateClickedPopUp, updateVideoSrc } from '../../lib/entities/presentation'
import { QUESTIONNAIRE_PARTICIPANTS_ROUTE, MASTER_TRAININGS_ROUTE } from '../../routes'
import { useProjectWithIframeData } from '../../hooks/useProjectWithIframeData'
import WaitingOverlay from '../../components/WaitingOverlay'
import { Button, ButtonKind } from '@aposphaere/ui-components'
import { updateStatistic } from '../../lib/entities/statistic'
import { useCurrentIpads } from '../../lib/contexts/ipadsContext'
import PresentationIframe, { PresentationMessageHandlers } from '../../components/Presentation'
import { PresentationVideo } from '../../components/Presentation/Video'

const INITIAL_SLIDE = 'slide_0'

const MasterPresentation: React.FC = () => {
  const history = useHistory()
  const location = useLocation<{ statisticDocId: string }>()
  const [statisticDocId] = useState(location?.state?.statisticDocId)
  const [videoSource, setVideoSource] = useState<string | null>()
  const { presentation, presentationSnapshot, isLoading: presentationLoading } = usePresentation()
  const [currentSlide, setCurrentSlide] = useState(INITIAL_SLIDE)
  const [clickedPopUp, setClickedPopUp] = useState<string | null>(null)
  const { isLoading: projectLoading, error: projectError, iframeSrc } = useProjectWithIframeData(presentation?.project_id)
  const { ipads } = useCurrentIpads()
  const isLoading = presentationLoading || projectLoading

  const endPresentation = useEndPresentation()
  const setPresentationTimes = useSetPresentationTimes()

  const getPresentationPtaIds = useCallback(() => {
    const ptaIds: string[] = []
    presentation?.connectedIpads?.forEach((item) => {
      ptaIds.push(...item.pta_ids)
    })
    return ptaIds
  }, [presentation?.connectedIpads])

  useEffect(() => {
    if (projectError) {
      console.error('Project loading error: ', projectError?.message)
    }
  }, [projectError])

  // Listen to messages from the iframe
  useEffect(() => {
    const messageHandler = async (event: MessageEvent<IncomingPresentationPostMessage>) => {
      const message = event.data

      if (message.type === 'goPage' && message.command.viewid === 'none') {
        setClickedPopUp(message.command.command.clickevent)
      }

      if (message.type === 'time') {
        const presentationTimeInSeconds = 0
        const bookedTimeInSeconds = message?.totalTime || 0
        const minTimeInSeconds = message?.minTime || 0

        setPresentationTimes(presentationTimeInSeconds, bookedTimeInSeconds, minTimeInSeconds)
      }

      if (message.type === 'goPage' && message?.command?.viewid !== 'none') {
        setCurrentSlide(message.command.viewid)
      }

      if (message.type === 'videoSrc') {
        setVideoSource(message.command.src)
      }

      if (message.type === 'finished') {
        const bookedTimeInSeconds = message?.totalTime
        const presentationTimeInSeconds = bookedTimeInSeconds - message?.startTime + message?.overtime
        const currentDate = new Date()
        const endTime = currentDate.getTime()

        endPresentation(presentationTimeInSeconds)
        updateStatistic(statisticDocId, { end_time: endTime, pta_ids: getPresentationPtaIds() })
        history.push(QUESTIONNAIRE_PARTICIPANTS_ROUTE)
      }
    }

    window.addEventListener('message', messageHandler)

    return () => window.removeEventListener('message', messageHandler)
  }, [endPresentation, getPresentationPtaIds, history, presentationSnapshot?.id, setPresentationTimes, statisticDocId])

  // Send slide changes to firestore
  useEffect(() => {
    if (!presentationSnapshot?.id) {
      return
    }
    updatePresentationCurrentSlide(presentationSnapshot.id, currentSlide)
  }, [currentSlide, presentationSnapshot?.id])

  const postMessageHandlers: PresentationMessageHandlers = useMemo(() => {
    return {
      onFinished({ endTime, presentationTimeInSeconds }) {
        endPresentation(presentationTimeInSeconds)
        updateStatistic(statisticDocId, { end_time: endTime, pta_ids: getPresentationPtaIds() })
        history.push(QUESTIONNAIRE_PARTICIPANTS_ROUTE)
      },
      onSlideChange(slide) {
        setCurrentSlide(slide)
      },
      onTimeMessage(totalTime?, minTime?) {
        const presentationTimeInSeconds = 0
        const bookedTimeInSeconds = totalTime || 0
        const minTimeInSeconds = minTime || 0

        setPresentationTimes(presentationTimeInSeconds, bookedTimeInSeconds, minTimeInSeconds)
        setPresentationTimes(presentationTimeInSeconds, bookedTimeInSeconds, minTimeInSeconds)
      },
      onVideoSrc(videoSrc) {
        setVideoSource(videoSrc)
      },
      onClickPopUp(clickId) {
        setClickedPopUp(clickId)
      },
    }
  }, [endPresentation, getPresentationPtaIds, history, setPresentationTimes, statisticDocId])

  // Set connected Ipads
  useEffect(() => {
    if (!presentationSnapshot?.id || !ipads?.length) {
      return
    }
    const updatePresentationIpads = async () => {
      await updatePresentationConnectedIpads(presentationSnapshot?.id, ipads)
    }
    updatePresentationIpads()
  }, [ipads, presentationSnapshot?.id])

  // Use video source from postmessage
  useEffect(() => {
    if (presentationSnapshot?.id) {
      updateVideoSrc(videoSource, presentationSnapshot?.id)
    } else {
      return
    }
  }, [videoSource, presentationSnapshot?.id])

  // Send popup clicks to firestore
  useEffect(() => {
    if (!presentationSnapshot?.id) {
      return
    }
    updateClickedPopUp(presentationSnapshot.id, clickedPopUp)
  }, [clickedPopUp, presentationSnapshot?.id])

  if (isLoading || !presentation) {
    return <Spinner />
  }

  if (!iframeSrc) {
    return (
      <WaitingOverlay
        content={
          <>
            <h2 className="text-black-100 mb-13">Fehlende Präsentations-URL</h2>
            <Button kind={ButtonKind.secondary} onClick={() => history.push(MASTER_TRAININGS_ROUTE)}>
              Zurück
            </Button>
          </>
        }
      />
    )
  }

  const heightRatio = 768
  const widthRatio = 1024
  const maxHeight = `${Math.round((heightRatio / widthRatio) * 100)}vw`

  return (
    <div className="flex justify-center items-center h-full toolbar-ios">
      <PresentationIframe
        controllable={true}
        src={iframeSrc}
        slide={currentSlide}
        className="h-screen"
        style={{ aspectRatio: `${widthRatio}/${heightRatio}`, maxWidth: '100vw', maxHeight: maxHeight }}
        messageHandlers={postMessageHandlers}
      />
      {videoSource ? <PresentationVideo src={videoSource} onEnd={() => setVideoSource(null)} /> : null}
    </div>
  )
}

export default MasterPresentation
