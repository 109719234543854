import { Context, useContext } from "react";

export function createContextConsumerHook<T>(context: Context<T>, hookName: string): () => NonNullable<T> {
    return () => {   
        const foundContext = useContext(context)
        if (!foundContext) {
            throw new Error(`Missing Provider: The ${hookName} can't be used without it's provider in scope`)
        }
        
        return foundContext as NonNullable<T>
    }

}