import React, { useContext } from 'react'
import { AppContext, IAppContext } from '../../lib/contexts/appContext'

interface CircleButtonProps {
  label: string
  handleClick: () => void
}

const CircleButton = ({ label, handleClick }: CircleButtonProps) => {
  const { intendedUserCount: usersNumber } = useContext(AppContext) as IAppContext

  return (
    <button className="w-24 h-24 focus:outline-none" onClick={() => handleClick()}>
      <span
        className={`flex justify-center items-center rounded-full w-24 h-24 text-2xl font-medium border-2 hover:border-blue-700" ${
          usersNumber !== undefined && usersNumber + '' === label
            ? 'bg-green-550 border-green-550 text-white'
            : 'bg-white border-blue-600 text-blue-600'
        }`}
      >
        {label}
      </span>
    </button>
  )
}

export default CircleButton
