import { Employee, Ipad, IpadEmployee } from '@aposphaere/core-kit/build/models'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'

const { collection, ...ipad } = initFirebaseEntity<Ipad>('ipads')
export const ipadsCollection = collection

export const getAllIpads = ipad.getAll
export const getIpadWithDeviceId = async (deviceId: string) => await ipad.getFirst({ where: { device_id: deviceId } })

export const createIpad = ipad.create
export const deleteIpads = ipad.deleteMany

export const ipadWhere = ipad.where
/**
 * UPDATE
 */

export const updateIpad = ipad.update

export const updateIpadEmployee = async (employeeId: string, { userData, deviceId }: { userData: Partial<Employee>; deviceId: string }) => {
  const ipadDoc = await getIpadWithDeviceId(deviceId)
  if (!ipadDoc) {
    console.warn("Ipad not found. Can't update it")
    return
  }
  const foundIpad = ipadDoc.data()
  const users = foundIpad.users.map((employee: Employee) => {
    if (employee.id === employeeId) {
      return { ...employee, ...userData }
    }
    return employee
  })

  ipad.update(ipadDoc.id, {
    users: users,
  })
}

export const deleteIpadEmployee = async (userId: string, deviceId: string) => {
  const ipadDoc = await getIpadWithDeviceId(deviceId)
  if (!ipadDoc) {
    console.warn("Ipad not found. Can't update it")
    return
  }
  const foundIpad = ipadDoc.data()
  const users = foundIpad.users.filter((user: Employee) => {
    return user.id !== userId
  })

  const updatedFields = users.length === 0 ? { users: [], connected: false } : { users: users }

  ipad.update(ipadDoc.id, {
    ...updatedFields,
  })
}

/**
 *  QUESTIONNAIRE STATUS ON DEVICE
 */
interface CompleteEmployeeQuestionnaireArgs {
  deviceUuid: string
  employeeId: string
}
export const setEmployeeQuestionnaireStatus = async (
  status: 'required-done' | 'all-questions-done',
  { deviceUuid, employeeId }: CompleteEmployeeQuestionnaireArgs,
) => {
  const ipad = await getIpadWithDeviceId(deviceUuid)

  if (!ipad) {
    console.warn('Ipad not found')
    return
  }

  const updatedUsers = ipad.data().users.map((employee) => {
    if (employee.id === employeeId) {
      const initialQuestionnaireStatus: IpadEmployee['questionnaire_status'] = employee.questionnaire_status || {
        all_done: false,
        required_done: false,
      }

      const updatedEmployee: IpadEmployee = {
        ...employee,
        questionnaire_status:
          status === 'all-questions-done' ? { required_done: true, all_done: true } : { ...initialQuestionnaireStatus, required_done: true },
      }
      return updatedEmployee
    }
    return employee
  })

  updateIpad(ipad.id, { users: updatedUsers })
}
