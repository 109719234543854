import { DocumentReference, DocumentSnapshot, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { SubscriptionState, SubscriptionStatus } from '../lib/models/subscriptionState'

export function useDocumentSubscription<T>(documentReference: DocumentReference<T> | null) {
  const [state, setState] = useState<SubscriptionState<DocumentSnapshot<T>>>(() => ({
    status: documentReference ? SubscriptionStatus.Pending : SubscriptionStatus.Idle,
  }))
  const status = state.status
  const data = 'data' in state ? state.data : null
  const error = 'error' in state ? state.error : null

  const canFetch = Boolean(documentReference)

  useEffect(() => {
    if (canFetch && status === SubscriptionStatus.Idle) {
      setState({
        status: SubscriptionStatus.Pending,
      })
    }

    if (!canFetch) {
      setState({
        status: SubscriptionStatus.Idle,
      })
    }
  }, [canFetch, status])

  // Set the connection
  useEffect(() => {
    if (!documentReference) {
      return
    }

    const unsubscribe = onSnapshot(documentReference, {
      next: (snapshot: DocumentSnapshot<T>) => {
        if (snapshot) {
          setState({ data: snapshot || null, status: SubscriptionStatus.Resolved })
        }
      },
      error: (err) => setState({ status: SubscriptionStatus.Error, error: err }),
    })

    return unsubscribe
  }, [documentReference])

  return { status, data, isLoading: status === 'pending', error }
}
