import React, { useEffect, useState } from 'react'
import { IconButton, IconButtonKind, LogoutIconRounded } from '@aposphaere/ui-components'
import { useHistory } from 'react-router-dom'
import { ENTRY_SCREEN_ROUTE } from '../../routes'
import { useAuth } from '../../lib/contexts/authContext'
import { useActiveAppointment } from '../../lib/contexts/activeAppointmentContext'

const SignOutButton: React.FC = () => {
  const { removeActiveAppointment } = useActiveAppointment()
  const [loggedIn, setLoggedIn] = useState(true)
  const history = useHistory()
  const { user, logout } = useAuth()

  useEffect(() => {
    if (!loggedIn && user) {
      removeActiveAppointment()
      logout()
    }
    if (!user) {
      history.push(ENTRY_SCREEN_ROUTE)
    }
  })
  return (
    <IconButton
      kind={IconButtonKind.custom}
      icon={<LogoutIconRounded />}
      additionalCss="ml-2 px-2 py-2 active:text-white hover:text-white focus:outline-none hover:bg-blue-700 rounded active:bg-blue-800 transition ease-in-out duration-150"
      onClick={() => setLoggedIn(false)}
    />
  )
}

export default SignOutButton
