import { gql } from 'graphql-request'

export const wholeGermanySummary = gql`
  query getWholeGermanySummary($date: DateRange) {
    getSummary(date: $date) {
      appointments_completed
      appointments_booked
      appointments_canceled
      visits_completed
      visits_booked
      canceled_in_range
      canceled_till_today
      pharmacies_count
      pharmacies_with_appointment
      pharmacies_completed
      pharmacies_booked
      pharmacies_canceled
      trainings_count
      trainigns_multiple
      slots_booked
      slots_completed
      participant_count
      per_day {
        date
        appointments_canceled
        appointments_booked
        visits_booked
        appointments_completed
      }
      per_project {
        project {
          id
          name
          start_date
          end_date
          booked_time_in_seconds
        }
        pharmacies_potential
        slot_count
        pharmacies_completed
        participant_count
        average_duration
        appointments_future
        pharmacies_multiple
        appointments_canceled
      }
      pharmacies_answered_question
    }
  }
`

export const getSurveyAnswerCount = gql`
  query getSurveyAnswerCount($pharmacyClusterId: ID!, $date: DateRange!) {
    getSurveyAnswerCount(pharmacy_cluster_id: $pharmacyClusterId, date: $date) {
      total_count
      per_project {
        project {
          id
        }
        count
      }
    }
  }
`
