import { DayPart, Pharmacy } from '@aposphaere/core-kit'
import { getDocs, query, QueryDocumentSnapshot, Timestamp, where } from 'firebase/firestore'
import { initFirebaseEntity } from '../utils/initFirebaseEntity'
import { orderItemCollection } from './orderItems'
import { pharmacyCollection } from './pharmacy'
import { Project, projectCollection } from './project'

export enum AppointmentStatus {
  Active = 2,
}

export interface Appointment {
  date: Timestamp
  enddate?: Timestamp
  id?: number
  uuid?: string
  pharmacy_id: number
  project_id: number
  user_ids: number[]
  quarter_id: number
  status_id: number
  appointment_type_id: number
  creator_id: number
  send_confirmation: boolean
}

export type ExtendedAppointment = Appointment & {
  pharmacy?: Pharmacy
  projects: Project[]
  dayParts: DayPart[]
  docId: string
}

async function getAdditionalDataForAppointment(appointmentDocument: QueryDocumentSnapshot<Appointment>) {
  const additionalData: {
    pharmacy?: Pharmacy
    projects: Project[]
    dayParts: DayPart[]
  } = {
    pharmacy: undefined,
    projects: [],
    dayParts: [],
  }

  const appointment = appointmentDocument.data()
  const documentId = appointmentDocument.id
  const appointmentId = appointment?.id

  const pharamcyQuery = query(pharmacyCollection, where('id', '==', appointment?.pharmacy_id))
  const pharmacyDetails = await getDocs(pharamcyQuery)
  pharmacyDetails.docs.map(async (doc) => {
    additionalData.pharmacy = doc.data() as Pharmacy
  })

  const orderItemsQuery = appointmentId
    ? query(orderItemCollection, where('appointment_id', '==', appointment?.id))
    : query(orderItemCollection, where('appointment_doc_id', '==', documentId))

  const orderItemsDetails = await getDocs(orderItemsQuery)
  orderItemsDetails.docs.map(async (doc) => {
    const orderItem = doc.data()
    const projectQuery = query(projectCollection, where('id', '==', orderItem?.project_id))
    const projectDetails = await getDocs(projectQuery)
    projectDetails.docs.forEach((doc) => {
      const project = doc.data()
      additionalData.projects.push(project)
    })
  })
  return additionalData
}

export async function fetchExtendedAppointments(date: Date, userId: number): Promise<ExtendedAppointment[]> {
  const dayStart = new Date(date)
  dayStart.setUTCHours(0, 0, 0, 0)

  const dayEnd = new Date(date)
  dayEnd.setUTCHours(23, 59, 59, 999)

  const appointmentsTodaySnapshot = await getDocs(
    query(appointmentCollection, where('date', '<', Timestamp.fromDate(dayEnd)), where('date', '>', Timestamp.fromDate(dayStart))),
  )

  if (appointmentsTodaySnapshot.empty) {
    return []
  }

  const appointmentDocsWhereUserHasAccess = appointmentsTodaySnapshot.docs.filter((doc) => {
    const data = doc.data()
    const userIds = data.user_ids
    const hasUserAccessToAppointment = userIds.includes(userId)
    return hasUserAccessToAppointment
  })

  const promises = appointmentDocsWhereUserHasAccess.map(async (doc) => {
    const { pharmacy, projects, dayParts } = await getAdditionalDataForAppointment(doc)

    const appointment = doc.data()
    return { ...appointment, docId: doc.id, pharmacy: pharmacy, dayParts: dayParts, projects: projects }
  })
  const extendedAppointments = await Promise.all(promises)
  return extendedAppointments
}

const { collection, ...appointment } = initFirebaseEntity<Appointment>('appointments')

export const appointmentCollection = collection

export const createAppointment = appointment.create
