import { initFirebaseEntity } from '../utils/initFirebaseEntity'

export interface Quarter {
  id: number
  label?: string
  from?: string
  to?: string
  weeks?: number
  days?: number
  training_goal?: string
  default?: boolean
}

const { collection, ...quarter } = initFirebaseEntity<Quarter>('quarters')

export const quarterCollection = collection

export const getAllQuarters = quarter.getAll
