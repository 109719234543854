import React from 'react'

export interface MultiChoiceProps {
  options: (string | number)[]
  values: (string | number)[]
  onChange: (value: string | number) => void
}

export const QuestionnaireMultiChoice = (props: MultiChoiceProps) => {
  return (
    <>
      {props.options.map((option) => {
        const isChecked = props.values.includes(option)
        return (
          <div key={option}>
            <div className="flex relative flex-col items-center justify-center text-center w-32 px-1.5">
              <input
                id={`option-${option}`}
                name={`option-${option}`}
                value={option}
                checked={isChecked}
                onChange={(e) => {
                  props.onChange(e.target.value)
                }}
                type="checkbox"
                className="invisible focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-100 mb-0.5"
              />
              <label htmlFor={`option-${option}`}>
                <span
                  className={`h-6 w-6 checkbox box-border rounded-sm  bg-white  absolute top-0 left-14 -ml-1 border-solid ${
                    isChecked ? 'text-blue-600 checkmark bg-blue-600' : 'border-2 text-black-100 border-gray-400'
                  }`}
                ></span>
                <span className={`font-body text-lg font-medium ${isChecked ? 'text-blue-600' : 'text-black-100'}`}>{option}</span>
              </label>
            </div>
          </div>
        )
      })}
    </>
  )
}
