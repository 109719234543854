import React, { useState, useEffect, useMemo } from 'react'
import { ArrowUpIcon, Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, Modal, ModalKind, TextInput } from '@aposphaere/ui-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useChangeUserIpad } from '../../hooks/entities/useIpads'
import { Ipad } from '@aposphaere/core-kit/build/models/iPad'
import { usePharmacyEmployeePositions } from '../../hooks/entities/usePharmacies'

import { Employee } from '@aposphaere/core-kit/build/models'
import { usePharmacy } from '../../lib/contexts/pharmacyContext'
import { ANONYMOUS_USER } from '../../lib/entities/user'
import { createRegisterFunction } from '../../lib/utils/formHelpers'
import { updateIpadEmployee } from '../../lib/entities/iPad'
import { deleteIpadUser, updateEmployee } from '../../lib/entities/pta'
import ConfirmModal from '../ConfirmModal'

type IEditUsersModal = {
  closeModal: () => void
  selectedUser: Employee
  ipad: Ipad
  ipads: Ipad[]
}

type EditUserFormValues = {
  is_participant: 0 | 1
  device_name: string
  first_name: string
  last_name: string
  pharmacy_id: number
  email: string
  gender: string
  employee_position_id: string
}

export const EditIpadUsersModal = ({ closeModal, selectedUser: initiallySelectedUser, ipad, ipads }: IEditUsersModal) => {
  const { pharmacy } = usePharmacy()
  const [userChanged, setUserChanged] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<Employee | undefined>(initiallySelectedUser)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const pharmacyEmail = pharmacy?.email?.toLowerCase()

  const initialFormValues: EditUserFormValues = {
    is_participant: 1,
    device_name: ipad?.device_name || '',
    first_name: selectedUser?.first_name || '',
    last_name: selectedUser?.last_name || '',
    pharmacy_id: selectedUser?.pharmacy_id || 0,
    email: selectedUser?.email || '',
    gender: selectedUser?.gender || '',
    employee_position_id: selectedUser?.employee_position_id || '',
  }

  const { employeePositions } = usePharmacyEmployeePositions()
  const [changeUserIpad] = useChangeUserIpad()

  const deleteUser = async () => {
    if (!pharmacy?.id || !selectedUser?.id || !ipad?.device_id) {
      return
    }
    deleteIpadUser(selectedUser?.id, ipad?.device_id)
    setShowConfirmModal(false)
    closeModal()
  }

  const changeUserToAnonymous = async () => {
    if (!pharmacy?.id || !selectedUser?.id || !ipad?.device_id) {
      return
    }
    const userData: Employee = {
      id: selectedUser?.id,
      first_name: ANONYMOUS_USER.NAME,
      last_name: '',
      pharmacy_id: pharmacy?.id,
      email: ANONYMOUS_USER.EMAIL,
      gender: '',
      employee_position_id: '',
      privacy_policy_accepted: false,
      is_anonymous_user: true,
    }
    await updateEmployee(selectedUser.id, userData)
    closeModal()
  }

  const onFormSubmit = async (values: EditUserFormValues) => {
    const isParticipant = Number(values.is_participant) === 0 ? false : true
    if (!selectedUser?.id || !ipad?.device_id) {
      return
    }
    if (!isParticipant) {
      changeUserToAnonymous()
      return
    }
    const deviceName = values.device_name
    let userData: Employee = { ...values, is_anonymous_user: false, privacy_policy_accepted: true, id: selectedUser?.id }
    userData = { ...values, is_anonymous_user: false, privacy_policy_accepted: true, id: selectedUser?.id }
    await Promise.all([
      updateIpadEmployee(selectedUser.id, { userData, deviceId: ipad?.device_id }),
      updateEmployee(String(selectedUser?.id), userData),
    ])
    //change user ipad
    if (ipad?.device_name !== deviceName) {
      changeUserIpad(userData)
    }
    closeModal()
  }

  const EditUserValidationScheme = useMemo(
    () =>
      Yup.object().shape({
        is_participant: Yup.number().required('Feld ist notwendig!'),
        device_name: Yup.string().min(2, 'Zu kurz').required('Feld ist notwendig!'),
        first_name: Yup.string().trim().min(2, 'Zu kurz').required('Feld ist notwendig!'),
        last_name: Yup.string().trim().min(2, 'Zu kurz').required('Feld ist notwendig!'),
        pharmacy_id: Yup.string().trim().required('Feld ist notwendig!'),
        email: Yup.string()
          .email('Ungültige E-Mail')
          .required('Feld ist notwendig!')
          .test('is-pharmacy-generic-email', 'Bitte verwenden Sie eine personalisierte Emailadresse wie Vorname.Nachname@apotheke.de', (email) => {
            return Boolean(email && email?.toLowerCase() !== pharmacyEmail)
          }),
        gender: Yup.string().required('Feld ist notwendig!'),
        employee_position_id: Yup.string().trim().required('Feld ist notwendig!'),
      }),
    [pharmacyEmail],
  )

  const { errors, touched, handleSubmit, isValid, handleChange, handleBlur, values, setValues } = useFormik({
    initialValues: initialFormValues,
    onSubmit: onFormSubmit,
    validationSchema: EditUserValidationScheme,
  })
  const register = createRegisterFunction({ values, handleChange, handleBlur })

  useEffect(() => {
    setUserChanged(false)
  }, [values])

  useEffect(() => {
    const ipadUsers = ipad?.users || []
    const currentIpadUser = ipadUsers.find((ipadUser) => selectedUser?.id && selectedUser?.id === ipadUser.id)
    if (!currentIpadUser) {
      return
    }

    setValues({
      is_participant: 1,
      device_name: ipad?.device_name || '',
      first_name: currentIpadUser?.first_name,
      last_name: currentIpadUser?.last_name,
      pharmacy_id: currentIpadUser?.pharmacy_id,
      email: currentIpadUser?.email,
      gender: currentIpadUser?.gender,
      employee_position_id: currentIpadUser?.employee_position_id ? currentIpadUser?.employee_position_id : '',
    })
  }, [selectedUser, ipad?.device_name, ipad?.users, setValues])

  if (!pharmacy?.name) {
    console.error('Pharmacy not found')
    return null
  }

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          title={`Sind Sie sicher, dass Sie PTA "${selectedUser?.first_name + ' ' + selectedUser?.last_name}" löschen möchten?`}
          closeModal={() => {
            setShowConfirmModal(false)
          }}
          confirm={deleteUser}
        />
      )}
      <Modal kind={ModalKind.ms} noPadding title={'Personenmanagement der Apotheke'} onClose={closeModal} onBack={() => null}>
        <div className="flex flex-row w-full h-full">
          <div className="flex flex-col w-1/3 h-auto bg-gray-100 border-r border-gray-400">
            {ipad?.users?.length &&
              ipad.users.map(
                (user, index) =>
                  !user?.first_name.includes('Anonym') && (
                    <button
                      aria-label={'Benutzer auswählen: ' + user?.first_name + ' ' + user?.last_name}
                      key={index}
                      className={`flex flex-row justify-between items-center pl-10 pr-3 py-3 border-t -mt-px border-gray-400 cursor-pointer ${
                        user?.id === selectedUser?.id ? 'text-blue-700' : 'text-gray-900'
                      }`}
                      onClick={() => {
                        setSelectedUser(user)
                        setUserChanged(true)
                      }}
                    >
                      <span className="text-lg font-medium font-body">{user?.first_name + ' ' + user?.last_name}</span>
                      {user?.id === selectedUser?.id && <ArrowUpIcon rotate="90" />}
                    </button>
                  ),
              )}
          </div>
          <div className="w-2/3">
            <form onSubmit={handleSubmit} className={`flex flex-wrap w-full rounded-lg h-fit justify-center py-5 px-4`}>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="is_participant">
                  <span className="text-gray-900 mb-2.5">{'TEILNAHME AN DIESER SCHULUNG:'}</span>
                </InputLabel>
                <div className="w-full rounded-md">
                  <select
                    {...register('is_participant')}
                    className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                  >
                    <option value={1}>Ja</option>
                    <option value={0}>Nein</option>
                  </select>
                </div>
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="device_name">
                  <span className="text-gray-900 mb-2.5">{'ZUGEWEISENES IPAD:'}</span>
                </InputLabel>
                <div className="w-full rounded-md">
                  {ipads ? (
                    <select
                      {...register('device_name')}
                      className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                    >
                      {ipads?.length &&
                        ipads.map((item) =>
                          item?.device_name ? (
                            <option key={item.device_id} value={item.device_name}>
                              {item.device_name}
                            </option>
                          ) : null,
                        )}
                    </select>
                  ) : null}
                </div>
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="employee_position_id">
                  <span className="text-gray-900 mb-2.5">{'FUNKTION:'}</span>
                </InputLabel>
                <div className="w-full rounded-md">
                  <select
                    {...register('employee_position_id')}
                    className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                  >
                    {employeePositions?.length &&
                      employeePositions?.map(({ id, name }: { id: number; name: string }) => {
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="gender">
                  <span className="text-gray-900 mb-2.5">{'ANREDE:'}</span>
                </InputLabel>
                <div className="w-full rounded-md">
                  <select
                    {...register('gender')}
                    className="w-full form-select font-body h-10 text-gray-900 bg-white block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                  >
                    <option value="male">Herr</option>
                    <option value="female">Frau</option>
                    <option value="other">Divers</option>
                  </select>
                </div>
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="first_name">
                  <span className="text-gray-900 mb-2.5">{'VORNAME:'}</span>
                </InputLabel>
                <TextInput {...register('first_name')} bgColor="bg-white" type="text" />
                {errors.first_name && touched.first_name ? <InputMessage kind={InputMessageKind.error}>{errors.first_name}</InputMessage> : null}
              </div>
              <div className="w-1/2 py-3 px-4">
                <InputLabel htmlFor="last_name">
                  <span className="text-gray-900 mb-2.5">{'NACHNAME:'}</span>
                </InputLabel>
                <TextInput {...register('last_name')} bgColor="bg-white" type="text" />
                {errors.last_name && touched.last_name ? <InputMessage kind={InputMessageKind.error}>{errors.last_name}</InputMessage> : null}
              </div>
              <div className="w-full py-2 px-4">
                <InputLabel htmlFor="pharmacy_id">
                  <span className="text-gray-900 mb-2.5 opacity-50">{'APOTHEKE:'}</span>
                </InputLabel>
                <TextInput {...register('pharmacy_id')} bgColor="bg-white" disabled type="text" value={pharmacy?.name || 'Unbekannt'} />
                {errors.pharmacy_id && touched.pharmacy_id ? <InputMessage kind={InputMessageKind.error}>{errors.pharmacy_id}</InputMessage> : null}
              </div>
              <div className="w-full py-2 px-4">
                <InputLabel htmlFor="email">
                  <span className="text-gray-900 mb-2.5">{'E-MAIL:'}</span>
                </InputLabel>
                <TextInput {...register('email')} bgColor="bg-white" type="email" />
                {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
              </div>
              <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-end gap-6 p-4 pt-6 pb-4 place-items-stretch">
                <Button
                  kind={ButtonKind.outlinedDanger}
                  onClick={() => {
                    setShowConfirmModal(true)
                  }}
                >
                  {'Person löschen'}
                </Button>
                <Button
                  type="submit"
                  kind={ButtonKind.primary}
                  disabled={!isValid || userChanged || Object.keys(touched).length === 0}
                  onClick={handleSubmit}
                >
                  {'Änderungen speichern'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}
