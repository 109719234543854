import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { PresentationStatus } from '../../lib/constants'
import Spinner from '../../components/Spinner'
import { usePresentation } from '../../lib/contexts/presentationContext'
import { QUESTIONNAIRE_ROUTE } from '../../routes'
import { useProjectWithIframeData } from '../../hooks/useProjectWithIframeData'
import { useNetworkMode } from '../../lib/contexts/networkStatusContext'
import PresentationIframe, { PresentationMessageHandlers } from '../../components/Presentation'
import { PresentationVideo } from '../../components/Presentation/Video'

export type ClientPresentationLocationState = {
  projectId?: string | number
}

const ClientPresentation: React.FC = () => {
  const history = useHistory()
  const { isOnline, isOffline } = useNetworkMode()
  const location = useLocation<ClientPresentationLocationState>()
  const { presentation } = usePresentation()

  const projectId = location.state?.projectId ? location.state.projectId : presentation?.project_id

  const { isLoading: projectLoading, iframeSrc } = useProjectWithIframeData(projectId)

  const [currentSlide, setCurrentSlide] = useState(presentation?.current_slide)
  const [isFinished, setFinished] = useState(presentation?.status === PresentationStatus.Inactive)
  const videoSrc = presentation?.video_src

  useEffect(() => {
    if (isOnline && presentation?.status === PresentationStatus.Inactive) {
      setFinished(true)
    }
  }, [isOnline, presentation?.status])

  useEffect(() => {
    if (isOnline && presentation?.current_slide) {
      setCurrentSlide(presentation.current_slide)
    }
  }, [isOnline, presentation?.current_slide])
  const isLoading = projectLoading

  // Handle if finished
  useEffect(() => {
    if (isFinished) {
      const locationState: ClientPresentationLocationState = {
        projectId: projectId,
      }
      history.push({ pathname: QUESTIONNAIRE_ROUTE, state: locationState })
    }
  }, [isFinished, history, projectId])

  const messageHandlers: PresentationMessageHandlers | undefined = useMemo(() => {
    if (isOffline) {
      return {
        onFinished: () => setFinished(true),
        onSlideChange: (slide) => setCurrentSlide(slide),
      }
    }
    return undefined
  }, [isOffline])

  if (isLoading || !iframeSrc) {
    return <Spinner />
  }

  const heightRatio = 768
  const widthRatio = 1024
  const maxHeight = `${Math.round((heightRatio / widthRatio) * 100)}vw`

  return (
    <div className="flex justify-center items-center h-full">
      <PresentationIframe
        slide={currentSlide}
        src={iframeSrc}
        controllable={isOnline ? false : true}
        messageHandlers={messageHandlers}
        className="h-screen"
        style={{ aspectRatio: `${widthRatio}/${heightRatio}`, maxWidth: '100vw', maxHeight: maxHeight }}
      />

      {videoSrc && isOnline && <PresentationVideo src={videoSrc} />}
    </div>
  )
}

export default ClientPresentation
